import React from "react";
import { useReducer, useEffect, useState } from "react";
import "./rewardCard.css";
import { Card, Progress, Image } from "antd";
import { useHistory } from "react-router-dom";
import expiredImg from "./../../images/expired-stamp.png";
export default function RewardCardV2(prob) {
  const {
    cardColor,
    activityName,
    pointTarget,
    progressPoint,
    image,
    achieved,
    id,
    expired,
    rewardStatus,
    rewardStatusVisible,
  } = prob;

  const [activityNameValue, setActivityNameValue] = useState("ชื่อกิจกรรม");
  const [pointTargetValue, setPointTargetValue] = useState(0);
  const [progressPointValue, setProgessPointValue] = useState(0);
  const [statusOnBtn, setStatusOnBtn] = useState(null);
  const history = useHistory();

  const defaultImage =
    "https://firebasestorage.googleapis.com/v0/b/parkrun-th.appspot.com/o/Reward%2FDefault_Reward.png?alt=media&token=40e7eb53-10de-4cfe-8622-ad8dd674e0b3";

  useEffect(() => {
    dispatch({ type: `${cardColor}` });

    if (activityName) {
      setActivityNameValue(activityName);
    } else {
      setActivityNameValue("ชื่อกิจกรรม");
    }

    if (pointTarget) {
      setPointTargetValue(pointTarget);
    } else {
      setPointTargetValue(0);
    }

    if (progressPoint) {
      setProgessPointValue(progressPoint);
    }

    if (rewardStatus) {
      switch (rewardStatus) {
        case "ยืนยันข้อมูล":
          setStatusOnBtn({ colorBtn: "btn-blue", text: "คลิกเพื่อรับรางวัล" });
          break;
        case "เตรียมรางวัล":
          setStatusOnBtn({ colorBtn: "btn-orange", text: "รอการจัดส่งรางวัล" });
          break;
        case "สำเร็จ":
          setStatusOnBtn({ colorBtn: "btn-green", text: "จัดส่งสำเร็จ" });
          break;
        default:
          return;
      }
    }
  }, [cardColor, activityName, pointTarget, progressPoint, rewardStatus]);

  const initialColor = {
    cardBgColor: "#fff",
    cardBorderColor: `linear-gradient(220deg,#444,#444)30 `,
    progressBarStrokeStart: "#444",
    progressBarStrokeEnd: "#444",
    progressBarTrail: "#e4e4e4",
    pointBGLabel: "linear-gradient(180deg,#fff,#fff)",
    pointFontColor: "#444",
    dividerLine: "linear-gradient(60deg,#444,#444) 1",
    activityNameColor: "#444",
    avatarBorder: "#444",
    pointTargetFontColor: `linear-gradient(220deg,#444,#444)30 `,
  };

  const cardThemeColorSwitch = (state, action) => {
    switch (action.type) {
      case "#ffeb3b":
        return {
          ...state,
          avatarBorder: "#ffeb3b",
          dividerLine: "linear-gradient(60deg,#ccfde0,#ccd1fd) 1",
          cardBgImage: "#ffeb3b",
          // " linear-gradient( 0deg,  rgba(244,173,6,1) -10%, rgba(229,251,31,1) 90% )",
          textColor: "#444",
          progressBarStrokeStart: "#ffeb3b",
          progressBarStrokeEnd: "#80ff3b",
          progressBarTrail: "#a4a395",
        };
      case "#f44336":
        return {
          ...state,
          avatarBorder: "#f44336",
          textColor: "#444",
          cardBgImage: "#f44336",
          // "linear-gradient( 180deg,  rgba(255,94,157,1) 0%, rgba(255,78,6,1) 92.3% )",
          progressBarStrokeStart: "#f44336",
          progressBarStrokeEnd: "#f4a235",
          progressBarTrail: "#a4a395",
        };
      case "#e91e63":
        return {
          ...state,
          avatarBorder: "#e91e63",
          textColor: "#fff",
          cardBgImage: "#e91e63",
          // "linear-gradient( 0deg,  rgba(174,68,223,1) 27.3%, rgba(246,135,135,1) 112.7% )",
          progressBarStrokeStart: "#e91e63",
          progressBarStrokeEnd: "#f5a2be",
          progressBarTrail: "#8d7980",
        };

      case "#9c27b0":
        return {
          ...state,
          avatarBorder: "#9c27b0",
          textColor: "#fff",
          cardBgImage: "#9c27b0",
          // "linear-gradient(0deg, #4621FD 0%, #B721FF 100%)",
          progressBarStrokeStart: "#9c27b0",
          progressBarStrokeEnd: "#d581e3",
          progressBarTrail: "#716274",
        };

      case "#673ab7":
        return {
          ...state,
          avatarBorder: "#673ab7",
          cardBgImage: "#673ab7",
          // "linear-gradient( 180deg,  rgba(150,93,233,1) 10.8%, rgba(99,88,238,1) 94.3% )",
          textColor: "#fff",
          progressBarStrokeStart: "#673ab7",
          progressBarStrokeEnd: "#b63aa9",
          progressBarTrail: "#77737d",
        };
      case "#3f51b5":
        return {
          ...state,
          avatarBorder: "#3f51b5",
          textColor: "#fff",
          cardBgImage: "#3f51b5",
          // "linear-gradient( 0deg,  rgba(5,23,111,1) 0%, rgba(24,95,240,1) 70% )",
          progressBarStrokeStart: "#1303a8",
          progressBarStrokeEnd: "#6776ca",
          progressBarTrail: "#75767e",
        };

      case "#2196f3":
        return {
          ...state,
          avatarBorder: "#2196f3",
          textColor: "#fff",
          cardBgImage: "#2196f3",
          // "linear-gradient( 180deg,  rgba(31,179,237,1) 0%, rgba(17,106,197,1) 70% )",
          progressBarStrokeStart: "#2196f3",
          progressBarStrokeEnd: "#b221f2",
          progressBarTrail: "#808b93",
        };

      case "#03a9f4":
        return {
          ...state,
          avatarBorder: "#03a9f4",
          textColor: "#444",
          cardBgImage: "#03a9f4",
          // "linear-gradient( 0deg,  rgba(141,146,239,1) 0%, rgba(99,229,254,1) 100% )",
          progressBarStrokeStart: "#03a9f4",
          progressBarStrokeEnd: "#30f402",
          progressBarTrail: "#767d80",
        };

      case "#00bcd4":
        return {
          ...state,
          avatarBorder: "#00bcd4",
          textColor: "#444",
          cardBgImage: "#00bcd4",
          // "linear-gradient( 180deg,  rgba(163,217,207,1) 0%, rgba(4,178,217,1) 100% )",
          progressBarStrokeStart: "#1700d4",
          progressBarStrokeEnd: "#00e2ff",
          progressBarTrail: "#656d6e",
        };
      case "#009688":
        return {
          ...state,
          avatarBorder: "#009688",
          textColor: "#fff",
          cardBgImage: "#009688",
          // "linear-gradient( 0deg,  rgba(9,154,151,1) 11.2%, rgba(21,205,168,1) 91.1% )",
          progressBarStrokeStart: "#00665c",
          progressBarStrokeEnd: "#33ffeb",
          progressBarTrail: "#acb8b7",
        };

      case "#4caf50":
        return {
          ...state,
          avatarBorder: "#4caf50",
          textColor: "#fff",
          cardBgImage: "#4caf50",
          // " linear-gradient( 0deg,  rgba(34,126,34,1) 10%, rgba(168,251,60,1) 120% )",
          progressBarStrokeStart: "#2e6a30",
          progressBarStrokeEnd: "#0be84a",
          progressBarTrail: "#738774",
        };

      case "#8bc34a":
        return {
          ...state,
          avatarBorder: "#8bc34a",
          textColor: "#444",
          cardBgImage: "#8bc34a",
          // "linear-gradient( 0deg,  rgba(43,194,57,1) 0%, rgba(149,250,113,1) 90% )",
          progressBarStrokeStart: "#4a8ac3",
          progressBarStrokeEnd: "#4ac3be",
          progressBarTrail: "#87907c",
        };

      case "#cddc39":
        return {
          ...state,
          avatarBorder: "#cddc39",
          textColor: "#444",
          cardBgImage: "#cddc39",
          // "linear-gradient( 180deg,  rgba(242,241,36,1) -10%, rgba(11,236,218,1) 170% )",
          progressBarStrokeStart: "#39db48",
          progressBarStrokeEnd: "#cddc39",
          progressBarTrail: "#929381",
        };

      case "#ffc107":
        return {
          ...state,
          avatarBorder: "#ffc107",
          textColor: "#444",
          cardBgImage: "#ffc107",
          // "linear-gradient( 180deg,  rgba(248,250,107,1) -20%, rgba(238,148,148,1) 120%)",
          progressBarStrokeStart: "#ff4406",
          progressBarStrokeEnd: "#fc3",
          progressBarTrail: "#aa965b",
        };
      case "#ff9800":
        return {
          ...state,
          avatarBorder: "#ff9800",
          textColor: "#444",
          cardBgImage: "#ff9800",
          // "linear-gradient( 180deg,  rgba(255,190,32,1) 0%, rgba(251,112,71,1) 124.3% )",
          progressBarStrokeStart: "#ff9800",
          progressBarStrokeEnd: "#FFE900",
          progressBarTrail: "#898175",
        };

      case "#ff5722":
        return {
          ...state,
          avatarBorder: "#ff5722",
          textColor: "#444",
          cardBgImage: "#ff5722",
          // "linear-gradient( 0deg,  rgba(255,61,89,1) -20%, rgba(252,251,44,1) 150% )",
          progressBarStrokeStart: "#ff5722",
          progressBarStrokeEnd: "#ffc521",
          progressBarTrail: "#a2877e",
        };

      case "#795548":
        return {
          ...state,
          avatarBorder: "#795548",
          textColor: "#fff",
          cardBgImage: "#795548",
          // "linear-gradient( 0deg,  rgba(88,26,26,1) 30%, rgba(211,159,117,1)160% )",
          progressBarStrokeStart: "#795548",
          progressBarStrokeEnd: "#b28c7f",
          progressBarTrail: "#606060",
        };

      case "#607d8b":
        return {
          ...state,
          avatarBorder: "#607d8b",
          textColor: "#fff",
          cardBgImage: "#607d8b",
          // "linear-gradient( 178.4deg,  rgba(77,88,105,1) 4.8%, rgba(39,43,57,1) 86.7% )",
          progressBarStrokeStart: "#3e515a",
          progressBarStrokeEnd: "#83608b",
          progressBarTrail: "#a4b7c0",
        };

      default:
        return {
          ...initialColor,
        };
    }
  };

  const [state, dispatch] = useReducer(cardThemeColorSwitch, initialColor);
  const handelClick = () => {
    history.push(`/reward/${id}`);
  };
  return (
    <div className="reward-card">
      <Card
        onClick={handelClick}
        hoverable
        bordered={false}
        bodyStyle={{
          padding: 10,
          display: "flex",
          alignItems: "center",
          flexWrap: false,
          flexDirection: "column",
          position: "relative",
          height: 245,
          width: 182,
        }}
        style={{
          width: 182,
          height: 245,
          borderRadius: 5,
          background: state.cardBgImage,
          // backgroundImage: state.cardBgImage,
          position: "relative",
          // boxShadow: "20px 20px 30px rgba(0, 0, 0, 0.5)",
        }}
      >
        <h3
          style={{
            fontWeight: 700,
            fontSize: "20px",
            margin: 0,
            padding: 0,
            color: `${state.textColor}`,
          }}
        >
          {activityNameValue}
        </h3>
        <div
          style={{
            width: 160,
            height: 170,
            position: "relative",
            display: "flex",
            justifyContent: "center",
            borderRadius: 8,
          }}
        >
          <Image
            alt="reward"
            src={image ? image : defaultImage}
            style={{ maxHeight: 170, borderRadius: 8, overflow: "hidden" }}
          />
          {expired && !achieved && (
            <div className="reward-card-achieved">
              <img alt="expiredImg" src={expiredImg}></img>
            </div>
          )}
          <div
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(77, 77, 77, 0.2)",
              borderRadius: "100px",
              width: 80,
              height: 80,
              transform: "translate( 8%, 10%)",
            }}
          >
            <div
              style={{
                position: "absolute",
                background: "#ccc",
                backdropFilter: "blur(20px)",
                borderRadius: "100px",
                opacity: 0.9,
                width: 40,
                height: 40,
              }}
            ></div>
            <Progress
              type="circle"
              width={70}
              strokeWidth={15}
              strokeColor={{
                "0%": "#81c784",
                "100%": "#81c784",
                // "0%": state.progressBarStrokeStart,
                // "100%": state.progressBarStrokeEnd,
              }}
              trailColor={state.progressBarTrail}
              percent={Math.round(
                (progressPointValue / pointTargetValue) * 100
              )}
              showInfo={true}
              status={achieved ? "success" : false}
            />
          </div>
        </div>

        <div
          style={{
            position: "absolute",
            bottom: 0,
          }}
        >
          <h4
            style={{
              fontSize: "16px",
              fontWeight: 600,
              color: `${state.textColor}`,
            }}
          >{`${progressPointValue} / ${pointTargetValue} ครั้ง`}</h4>
        </div>

        <div className="reward-card-tagStatus--v2 ">
          {achieved && rewardStatusVisible && statusOnBtn && (
            <button
              className={`reward_coin--btn--v2 ${statusOnBtn.colorBtn}`}
            >{`${statusOnBtn.text}`}</button>
          )}
        </div>
      </Card>
    </div>
  );
}
