import React, { Fragment, useState, } from 'react'
import {
  makeStyles,
  withStyles,
  Container,
  Typography,
  Card,
  CardContent,
  Grid,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Button
} from '@material-ui/core'
import { Spin } from 'antd'
import moment from 'moment'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import client from '../index'

import DateRangeIcon from '@material-ui/icons/DateRange'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder'

import AppFooter from '../components/Footer'
import Stats from '../components/AllStats'
import ParkRunAnywhere from '../images/parkrun-anywhere.jpeg'

// const GET_EventVr = gql`
// query($slug : String){
//   eventOne(filter:{
//     slug: $slug
//   }){
//     _id
//     name
//     eventRoute
//     image
//     location
//     levels
//     organizEmail
//     road
//     route
//     startPoint
//     finishPoint
//     banner
//   }
// }
// `
const GET_RACEVR = gql`
query($slug : String){
  raceOne(filter:{
    slug: $slug
  }){
    _id
  }
}
`

const useStyles = makeStyles((theme) => ({
  root: {
    //
  },
  title: {
    textAlign: 'center',
    padding: '15px 0',
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
  },
  avatar: {
    display: 'flex',
    justifyContent: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  carouimg: {
    width: '100%',
    maxHeight: '450px',
  },
  imageHover: {
    width: '100%',
    maxHeight: '450px',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'relative',
  },
  icon: {
    position: 'relative',
    marginRight: theme.spacing(1),
  },
  statsItem: {
    alignItems: 'center',
    display: 'flex',
  },
  table: {
    minWidth: '100%',
  },
}))

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#CAE4E5',
    color: '#000000',
    fontSize: 14,
    '@media (max-width: 320px)': {
      fontSize: 12,
    },
  },
  body: {
    fontSize: 12,
    '@media (max-width: 320px)': {
      fontSize: 10,
    },
  },
}))(TableCell)

const StyledTableRow = withStyles(()  => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#ecf4f3',
    },
  },
}))(TableRow)

function createData(datetime, runner, volunteer) {
  return {
    datetime, runner, volunteer
  }
}

const EventVR = () => {
  const classes = useStyles()
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  const { data = { raceOne: [] }, loading } = useQuery(GET_RACEVR, {
    client,
    variables: {
      slug: 'Virtual',
    },
    fetchPolicy: 'cache-and-network',
  })

  if(loading){
    return (
      <div style={{display:'flex', justifyContent:'center', alignItems:'center',height:'100vh' }}>
        <Spin tip="Loading...event"/>
      </div>
    )
  }

  let dateList = []
  const firstDate = moment('09/01/2021')
  dateList.push(firstDate)
  while (moment(dateList[dateList.length - 1]).add(7, 'days').isBefore(moment())) {
    dateList.push(moment(dateList[dateList.length - 1]).add(7, 'days'))
  }

  dateList.reverse()

  const rows = dateList.map((item, index) => createData(
    moment(item).format('LL'),
    <Button color="textSecondary" fullWidth variant="boby1" onClick={()=> window.open(`${process.env.REACT_APP_CLIENT_URL}/anywhere/${data.raceOne._id}/result/${(dateList.length - 1) - index}`, '_blank')}>ดูผล</Button>,
    '-'
  ))

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  
  return (
    <Fragment>
      <Container maxWidth="lg">
        <Grid container spacing={1} style={{ marginTop: '80px' }}>
          <Grid item xs={12}>
            <Typography className={classes.title} align="justify" variant="h4">Park Run @nywhere</Typography>
          </Grid>
          <Grid item lg={12} xs={12}>
            <div className={classes.carouimg}>
              <img className={classes.imageHover} src={ParkRunAnywhere} alt="" />
            </div>
          </Grid>
          <Grid item lg={8} md={8} xl={9} xs={12}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Card elevation={0} square>
                  <CardContent>
                    <Typography align="left" variant="h5" className="titleEvent">
                      Park Run @nywhere
                    </Typography>
                    <Grid className={classes.statsItem} item>
                      <DateRangeIcon fontSize="small" className={classes.icon} />
                      <Typography align="left" variant="subtitle1">:&ensp;จัดทุกสัปดาห์
                      </Typography>
                    </Grid>
                    <Grid className={classes.statsItem} item>
                      <LocationOnIcon fontSize="small" className={classes.icon} />
                      <Typography align="left" variant="subtitle1">:&ensp;ทุกที่</Typography>
                    </Grid>
                    <Grid className={classes.statsItem} item>
                      <QueryBuilderIcon fontSize="small" className={classes.icon} />
                      <Typography align="left" variant="subtitle1">:&ensp;17.00 น. ถึง 21.00 น.</Typography>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card elevation={0} square>
                  <Divider variant="middle" />
                  <CardContent>
                    <Typography className="textEvent02" align="center" variant="body1">กติการกิจกรรม Park Run @nywhere</Typography>
                    <Typography className="textEvent03" variant="body1" style={{ marginTop: '10px' }}>
                      1) จัดขึ้นทุกวันพุธ เริ่มต้นเวลา 17.00 น. ถึง 21.00 น.
                    </Typography>
                    <Typography className="textEvent03" variant="body1" style={{ marginTop: '10px' }}>
                      2) กำหนดระยะวิ่ง 5K
                    </Typography>
                    <Typography className="textEvent03" variant="body1" style={{ marginTop: '10px' }}>
                      3) การส่งผลสามารถทำได้ผ่าน 3 App เท่านั้น ( ไม่รับแบบ Manual )
                    </Typography>
                    <Typography className="textEvent03" variant="body1" style={{ marginTop: '10px' }}>
                      4) ในกรณีที่นักวิ่งลง App มากกว่า 1 App ระบบจะรับผลการวิ่งจาก App แรกที่ส่งผล
                    </Typography>
                    <Typography className="textEvent03" variant="body1" style={{ marginTop: '10px' }}>
                      5) ระบบจะรับผลวิ่ง Park Run Anywhere เพียง 1 ครั้ง / สัปดาห์
                    </Typography>
                    <Typography className="textEvent03" variant="body1" style={{ marginTop: '10px' }}>
                      6) สมาชิกปัจจุบันของ Park Runner Thailand สามารถเข้าร่วมกิจกรรมได้โดยไม่ต้องสมัครสมาชิกใหม่ (แต่ต้องเลือกลง 1 ใน 3 App. ที่ร่วมโครงการ)
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={4} md={4} xl={3} xs={12}>
            <Card elevation={0} square>
              <CardContent style={{ overflowX: 'auto' }}>
                <Typography className="textEvent02" align="center" variant="body1">ผลการวิ่ง </Typography>
                <Table className={classes.table} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center" style={{ width: '40%' }}>วันที่และเวลา</StyledTableCell>
                      <StyledTableCell align="center" style={{ width: '30%' }}>นักวิ่ง</StyledTableCell>
                      <StyledTableCell align="center" style={{ width: '30%' }}>อาสาสมัคร</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {/* <TableBody>
                    <StyledTableCell align="center">ทุกวันพุธ เวลา 17.00 น. ถึง 21.00 น.</StyledTableCell>
                    <StyledTableCell align="center"><Button color="textSecondary" fullWidth variant="boby1" onClick={()=> window.open(`${process.env.REACT_APP_CLIENT_URL}/events/${data.raceOne._id}/result`, '_blank')}>ดูผล</Button></StyledTableCell>
                    <StyledTableCell align="center">-</StyledTableCell>
                  </TableBody> */}
                  <TableBody>
                    {(rowsPerPage > 0
                      ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : rows
                    ).map((row) => (
                      <StyledTableRow key={row.name}>
                        <StyledTableCell component="th" scope="row">
                          {row.datetime}
                        </StyledTableCell>
                        <StyledTableCell align="center">{row.runner}</StyledTableCell>
                        <StyledTableCell align="center">{row.volunteer}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                    {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                    )}
                  </TableBody>
                </Table>
                <p />
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Stats />
      {/* -------------------------------------------------------- */}
      <AppFooter description="795 ถนน บรรทัดทอง แขวง วังใหม่ เขตปทุมวัน กรุงเทพมหานคร 10330" />
    </Fragment>
  )
}

export default EventVR
