import { Box, Avatar } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

export default function RewardCoin({
  cardColor,
  activityName,
  pointTarget,
  activeStatus,
  image,
  _id,
  status,
}) {
  const [statusBtn, setStatusBtn] = useState(null);
  let history = useHistory();

  const handelClick = () => {
    history.push(`/reward/${_id}`);
  };

  useEffect(() => {
    if (status) {
      switch (status) {
        case "ยืนยันข้อมูล":
          setStatusBtn({ text: "คลิกเพื่อรับรางวัล", colorBtn: "btn-blue" });
          break;
        case "เตรียมรางวัล":
          setStatusBtn({ text: "รอการจัดส่งรางวัล", colorBtn: "btn-orange" });
          break;
        case "สำเร็จ":
          setStatusBtn({ text: "จัดส่งสำเร็จ", colorBtn: "btn-green" });
          break;
        default:
          return;
      }
    }
  }, [status]);

  return (
    <div className="reward_coin" onClick={handelClick}>
      <Box
        className={`reward_coin_${cardColor.slice(1)} ${activeStatus}`}
        style={{
          position: "relative",
          height: "120px",
          width: "120px",
          borderRadius: 100,
          boxShadow: "5px 5px 10px #495057 ",
        }}
      >
        <Avatar
          alt="Remy Sharp"
          src={image}
          style={{
            width: "115px",
            position: "absolute",
            height: "115px",
            margin: "auto",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }}
        />

        <Box
          style={{
            height: "90px",
            width: "90px",
            borderRadius: "50px",
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            margin: "auto",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            lineHeight: "32px",
            fontWeight: 700,
            background: "rgba(225,225,225,0.7)",
          }}
        >
          {activityName}
        </Box>
      </Box>
      {statusBtn && (
        <button
          className={`reward_coin--btn  ${statusBtn.colorBtn}`}
        >{`${statusBtn.text}`}</button>
      )}
    </div>
  );
}
