import React, { useState, Fragment } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Link } from 'react-router-dom'
import {
  Popover, Row, Col, Radio,
  Input, Spin
} from 'antd'
import GoogleMap from 'google-map-react'
import {
  makeStyles,
  Container,
  Grid,
  Paper,
  Typography,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
} from '@material-ui/core'
import LazyLoad from 'react-lazyload'

import DirectionsRunIcon from '@material-ui/icons/DirectionsRun'
import DateRangeIcon from '@material-ui/icons/DateRange'
import AddIcon from '@material-ui/icons/Add'
import _orderBy from 'lodash/orderBy'
import _find from 'lodash/find'

import Stats from '../components/AllStats'
import AppFooter from '../components/Footer'
import lokation from '../components/img/lokation.png'
// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemText from '@material-ui/core/ListItemText';
// import ListItemAvatar from '@material-ui/core/ListItemAvatar';
// import Avatar from '@material-ui/core/Avatar';
import provinceData from '../data/province.json'
import client from '../index'
import getEvent from '../graphql/queries/getAllEvent'
import getRunner from '../graphql/queries/getRunner'

const { Search } = Input
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    overflow: 'hidden',
    width: '100%',
    maxWidth: '1300px',
  },
  list: {
    width: '100%',
    maxWidth: 360,
  },
  paper: {
    width: '100%',
    height: '50px',
    position: 'relative',
    backgroundColor: '#16a596',
    padding: theme.spacing(1),
  },
  card: {
    position: 'relative',
    '&:hover': {
      backgroundColor: 'rgba(65, 174, 169, 0.1)',
    },
  },
  img: {
    height: '200px',
    width: '100%',
    display: 'inline-flex',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    color: theme.palette.text.secondary,
  },
  text: {
    display: 'inline-flex',
    color: theme.palette.text.secondary,
    fontSize: 16,
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(1),
    fontSize: 20,
    backgroundColor: '#ffd369',
    color: theme.palette.text.primary,
    '&:hover': {
      backgroundColor: '#ffc85c',
      color: theme.palette.text.primary,
    },
  },

}))

const markerUrl = lokation

const EventMap = ({ className, ...rest }) => {
  const classes = useStyles()
  const [center] = useState({ lat: 13.7563, lng: 100.5018 })
  const [searchKey, setSearchKey] = useState('')
  const [sector, setSector] = useState('')
  const { data, loading } = useQuery(getEvent, {
    client,
    fetchPolicy: 'network-only',
  })
  const { data: data2, loading: loading2 } = useQuery(getRunner, {
    client,
    fetchPolicy: 'cache-and-network',
    skip: true
  })

  if (loading) {
    return (
      <div style={{display:'flex', justifyContent:'center', alignItems:'center',height:'100vh' }}>
      <Spin tip="Loading...events"/>
      </div>
    )
  }
  if (loading2) {
    return (
      <div style={{display:'flex', justifyContent:'center', alignItems:'center',height:'100vh' }}>
      <Spin tip="Loading...events"/>
      </div>
    )
  }

  const findGEO = (province) => {
    const geo = _find(provinceData, (o) => o.PROVINCE_NAME === province)
    if (geo) {
      return `${geo.GEO_ID}`
    }
  }
  console.log(data2)

  let events = data.eventMany.map((e) => {
    // const temp = data2.getRunnerRegister.find((element) => element.eventId === e._id)
    // const tempE = { ...e }
    // if (temp) {
    //   e = { ...tempE, ...temp }
    // }
    return e
  })

  if (!loading && events) {
    events = _orderBy(events.filter((event) => !event.legacy), ['startTime'], ['desc'])
    events = events.map((event) => ({
      ...event,
      GEO: findGEO(event.province),
    }))
    events = events.filter((event) => {
      if (sector === '1' || sector === '2' || sector === '3' || sector === '4' || sector === '5' || sector === '6') {
        const s = !![event.GEO].find(
          (k) => {
            if (k) {
              return k.toString().toLowerCase().replace(/\s/g, '').search(sector) !== -1
            }
            return false
          },
        )
        return s
      }
      const s = !![event.slug, event.name, event.province].find(
        (k) => k.toLowerCase().replace(/\s/g, '').search(searchKey.toLowerCase()) !== -1,
      )
      return s
    })
  }



  const handleSectorChange = (sector) => {
    setSector(sector)
    setSearchKey(sector)
  }

  const AnyReactComponent = ({
    text, line, img, addressto,
  }) => (
    <div>
      <Link to={`/event/${line}`}>
        <Popover
          content={(
            <div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <img src={img} alt="img" align="center" style={{ maxHeight: '150px' }} />
              </div>
              <Typography color="textSecondary" variant="body1" align="center">
                จังหวัด: {addressto}
              </Typography>
            </div>
          )}
          title={(
            <Typography color="textSecondary" variant="subtitle1" align="center">
              {text}
            </Typography>
          )}
          trigger="click"
        >
          <img src={markerUrl} alt="img" style={{ height: '30px', width: '30px' }} />
        </Popover>
      </Link>

    </div>
  )

  return (
    <Fragment>
      <Container maxWidth="lg">
        <Row gutter={[8, 8]} type="flex" style={{ marginTop: '80px' }}>
          <Col lg={{ span: 24 }} xs={{ span: 24 }}>
            <Paper elevation={0} className={classes.paper} square>
              <Typography variant="h5" align="center" style={{ color: '#fff' }}>
                สนามวิ่งทั้งหมด
              </Typography>
            </Paper>
          </Col>
          <Col lg={{ span: 24 }} xs={{ span: 24 }}>
            <div style={{ height: '50vh', width: '100%' }}>
              <GoogleMap
                bootstrapURLKeys={{ key: 'AIzaSyANTo6Y9YNxZTnRrXdmZtWBLgqAvLY9GFg' }}
                defaultCenter={center}
                defaultZoom={5.3}
              >
                {events.map((item) => {
                  const { address } = item
                  if (address) {
                    const latlng = address.split(',')
                    return (
                      <AnyReactComponent
                        lat={Number(latlng[0])}
                        lng={Number(latlng[1])}
                        text={item.name}
                        line={item._id}
                        img={item.image}
                        addressto={item.province}
                      />
                    )
                  }
                  return ''
                })}
              </GoogleMap>
            </div>
          </Col>
          <div style={{ width: '100%', marginTop: '5px' }}>
            <Row gutter={[8, 8]} type="flex">
              <Col xs={{ span: 24 }} md={{ span: 18 }}>
                <Radio.Group value={sector} onChange={(e) => handleSectorChange(e.target.value)}>
                  <Radio.Button value="">ทั้งหมด</Radio.Button>
                  <Radio.Button value="กรุงเทพมหานคร">กรุงเทพมหานคร</Radio.Button>
                  <Radio.Button value="1">ภาคเหนือ</Radio.Button>
                  <Radio.Button value="3">ภาคตะวันออกเฉียงเหนือ</Radio.Button>
                  <Radio.Button value="4">ภาคตะวันตก</Radio.Button>
                  <Radio.Button value="2">ภาคกลาง</Radio.Button>
                  <Radio.Button value="5">ภาคตะวันออก</Radio.Button>
                  <Radio.Button value="6">ภาคใต้</Radio.Button>
                </Radio.Group>

              </Col>
              <Col xs={{ span: 24 }} md={{ span: 6 }}>
                <Search
                  placeholder="ค้นหาด้วยชื่อสนามหรือจังหวัด"
                  onChange={(e) => setSearchKey(e.target.value)}
                />

              </Col>

            </Row>

          </div>
          {events.map((item, index) => (
            <Col lg={{ span: 6 }} sm={{ span: 12 }} xs={{ span: 24 }}>
              <Link to={`/event/${item._id}`} key={index}>
                <Card className={classes.card} variant="outlined" elevation={0} square>
                  <CardActionArea>
                    <CardMedia>
                      <LazyLoad height={200}>
                        <img alt={item.title} src={item.image} className={classes.img} />
                      </LazyLoad>
                    </CardMedia>
                    <CardContent style={{ height: '80px' }}>
                      <Typography gutterBottom variant="h6" component="h2">
                        {item.name}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <Divider />
                  <CardActions>
                    <Grid item xs={8}>
                      <Typography className={classes.text}>
                        <DateRangeIcon className={classes.icon} size="small" />{!item.date ? 'เร็วๆนี้' : item.date}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography className={classes.text}>
                        <DirectionsRunIcon className={classes.icon} size="small" />{!item.runner ? '-' : item.runner}
                      </Typography>
                    </Grid>
                  </CardActions>
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
        <Button variant="contained" href="/createEvent" className={classes.fab}>
          <AddIcon /> สร้างสนามวิ่ง
        </Button>
      </Container>
      {/* -------------------------------------------------------- */}
      <Stats />
      {/* -------------------------------------------------------- */}
      <AppFooter description="795 ถนน บรรทัดทอง แขวง วังใหม่ เขตปทุมวัน กรุงเทพมหานคร 10330" />
    </Fragment>

  )
}
export default EventMap
