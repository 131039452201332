import React from "react";

import { Typography, makeStyles } from "@material-ui/core";
import AppFooter from "../components/Footer";

export default function PrivatePolicy() {
  const useStyles = makeStyles(() => ({
    container: {
      margin: "auto",
      padding: "64px 28px",
      background: "#fff",
      maxWidth: "1200px",
    },
    head: {
      margin: "32px",
    },
    topicHead: { margin: "24px 0 4px 0" },
    list: {
      margin: "12px 24px",
    },
  }));

  const classes = useStyles();
  return (
    <>
      <div className={classes.container}>
        <Typography variant="h5" align="center" className={classes.head}>
          ข้อตกลงการใช้บริการ และ นโยบายความเป็นส่วนตัว (Privacy Policy)
        </Typography>
        <Typography variant="body2" align="left">
          บริษัท ไทยดอทรัน จำกัด (“ผู้ให้บริการ”)
          ตระหนักและใส่ใจถึงนโยบายความเป็นส่วนตัวของผู้ใช้บริการ
          (“ผู้ใช้บริการ”) ที่เข้าใช้บริการผ่านช่องทางต่างๆ
          ของผู้ให้บริการในปัจจุบัน และรวมถึงช่องทางเพิ่มเติมในอนาคต (“บริการ”)
          ทางผู้ให้บริการ จึงได้กำหนด การเก็บรวบรวม ใช้ และเปิดเผย
          ข้อมูลส่วนบุคคล โดยผู้ใช้บริการ
          รับทราบและยินยอมในข้อตกลงและข้อกำหนดในการใช้บริการ
        </Typography>
        <Typography variant="h6" align="left" className={classes.topicHead}>
          วัตถุประสงค์ในการเก็บรวบรวมข้อมูลส่วนบุคคล
        </Typography>
        <Typography variant="body2" align="left">
          เพื่อให้การบริการเป็นไปอย่างมีประสิทธิภาพ ถูกต้อง รวดเร็ว
          และตอบโจทย์ความต้องการของผู้ใช้บริการ
          ผู้ให้บริการจึงมีการเก็บข้อมูลส่วนบุคคลตามที่จำเป็น เพื่อ
          <ul className={classes.list}>
            <li>
              ให้สามารถทำตามวัตถุประสงค์ของผู้ใช้บริการ ในการใช้บริการต่างๆ
            </li>
            <li>
              ใช้ในการประมวลผลเพื่อให้ผู้ให้บริการทราบถึงข้อมูลและสถิติต่างๆ
              ในการใช้บริการ เพื่อให้สามารถนำข้อมูลมาปรับปรุง
              และพัฒนาช่องทางบริการต่างๆ
              เพื่อให้ผู้ใช้บริการได้รับประสบการณ์ที่ดีในการใช้งาน
              สามารถใช้งานได้สะดวกสบาย และเกิดประโยชน์สูงสุด
            </li>
            <li>
              แจ้งข้อมูล ข่าวสาร กิจกรรม ผลิตภัณฑ์ และรายการส่งเสริมการขาย
              ผ่านช่องทางของผู้ให้บริการ และ พันธมิตรที่เกี่ยวข้อง
            </li>
          </ul>
        </Typography>

        <Typography variant="h6" align="left" className={classes.topicHead}>
          การเก็บรวบรวมข้อมูลส่วนบุคคล
        </Typography>

        <Typography variant="body2" align="left">
          ผู้ให้บริการได้รับข้อมูลส่วนบุคคลโดยได้รับผ่านทางการใช้บริการ
          เมื่อมีความจำเป็นในการดำเนินการตามวัตถุประสงค์
          โดยข้อมูลเหล่านั้นรวมถึง
          <ul className={classes.list}>
            <li>
              ชื่อ นามสกุล อายุ วันเดือนปีเกิด เพศ เลขประจำตัวประชาชน /
              หนังสือเดินทาง
            </li>
            <li>
              ช่องทางการติดต่อ เช่น อีเมล์ ที่อยู่จัดส่ง
              ที่อยู่ในการออกใบกำกับภาษี หมายเลขโทรศัพท์
            </li>
            <li>ข้อมูลการสั่งซื้อสินค้า และ/หรือ บริการ</li>
            <li>
              ข้อมูลบัญชีผู้ใช้งาน user name , password, recovery email รูปภาพ
            </li>
            <li>
              ข้อมูลการทำธุรกรรมและการเงิน รายละเอียดบัตรเครคิด บัญชีธนาคาร
              ประวัติการสั่งซื้อ
            </li>
            <li>
              ข้อมูลอื่นๆ ตามที่จำเป็น เช่นข้อมูลการรีวิวสินค้าและบริการ
              ข้อเสนอแนะ
            </li>
            <li>
              ข้อมูลโดยใช้ Cookies
              เพื่อเพิ่มประสบการณ์การใช้งานของผู้ใช้บริการให้มีประสิทธิภาพมากขึ้น
            </li>
          </ul>
          ข้อมูลส่วนบุคคลของผู้ใช้บริการอาจถูกเปิดเผยต่อบุคคลหรือนิติบุคคลที่เกี่ยวข้องเช่น
          <ul className={classes.list}>
            <li>
              บุคคลและนิติบุคคลที่เกี่ยวข้องกับการให้บริการ และกิจกรรม ตามจำเป็น
              เช่น ผู้จัดงานวิ่ง ช่างภาพ
              ผู้ให้บริการจัดส่งและขนส่งสินค้าและผลิตภัณฑ์
            </li>
            <li>
              ผู้ให้บริการประมวลข้อมูลเพื่อให้ผู้ใช้บริการได้รับข่าวสาร กิจกรรม
              ผลิตภัณฑ์ การส่งเสริมการขายและการบริการ
              เพื่อประโยชน์สูงสุดของผู้ใช้บริการ เช่น search engine, social
              media, E-commerce ต่างๆ
            </li>
          </ul>
          ระยะเวลาในการจัดเก็บข้อมูล
          ผู้ให้บริการจะทำการเก็บรักษาข้อมูลที่ได้รับจากผู้ใช้บริการเป็นอย่างดี
          ด้วยระบบรักษาความปลอดภัยที่ได้มาตรฐาน จนกว่าจะมีการแก้ไข เปลี่ยนแปลง
          หรือยกเลิกโดยผู้ใช้บริการ
        </Typography>
        <Typography variant="h6" align="left" className={classes.topicHead}>
          ติดต่อเรา
        </Typography>
        <Typography variant="body2" align="left">
          การติดต่อเกี่ยวกับข้อมูลส่วนบุคคลของผู้ใช้บริการสามารถติดต่อได้ที่
          บริษัท ไทยดอทรัน จำกัด ที่อยู่เลขที่ 809 โครงการ Stadium one ถนนพระราม
          6 แขวงวังใหม่ เขตปทุมวัน กรุงเทพมหานคร 10330 หรือทางอีเมล์
          admin@thai.run
        </Typography>
      </div>
      <AppFooter description="795 ถนน บรรทัดทอง แขวง วังใหม่ เขตปทุมวัน กรุงเทพมหานคร 10330" />
    </>
  );
}
