import React, { useContext } from "react";
import clsx from "clsx";
import { useQuery } from "@apollo/react-hooks";
import { Link as RouterLink } from "react-router-dom";
import jwt from "jsonwebtoken";
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  Button,
  ListItemText,
  ListItemAvatar,
  Avatar,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import MeetingRoomTwoToneIcon from "@material-ui/icons/MeetingRoomTwoTone";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AccessibilityNewTwoToneIcon from "@material-ui/icons/AccessibilityNewTwoTone";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
// import Menu from '@material-ui/core/Menu';

// import PostAddIcon from '@material-ui/icons/PostAdd'

import _get from "lodash/get";
import _includes from "lodash/includes";

import getUser from "../../graphql/queries/getUserOne";
import Logo from "../../components/img/logo02.png";
import AuthContext from "../../context/AuthContext";
// import { node } from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    background: "rgba(255, 255, 255,0.9)",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  nav: {
    fontSize: 16,
    color: theme.palette.text.primary,
    "&:hover": {
      color: theme.palette.primary.dark,
    },
  },
  navlogin: {
    fontSize: 16,
    marginLeft: theme.spacing(1),
    "&:hover": {
      color: "#fff",
    },
  },
  button: {
    color: theme.palette.text.secondary,
    "&:hover": {
      color: theme.palette.primary.dark,
    },
    justifyContent: "flex-start",
    letterSpacing: 0,
    padding: "10px 8px",
    textTransform: "none",
    width: "100%",
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  title: {
    marginRight: "auto",
  },

  list: {
    // width: '100%',
    maxWidth: 300,
  },

  nested: {
    paddingLeft: theme.spacing(2),
  },
}));

const TopBar = ({ className, onMobileNavOpen, userId, ...rest }) => {
  const classes = useStyles();
  const { signOut } = useContext(AuthContext);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const { userInfo } = useContext(AuthContext);
  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const roles = _get(this.props, 'role', [])

  const { data = { userOne: {} } } = useQuery(getUser, {
    variables: {
      _id: `${userInfo._id}`,
    },
    fetchPolicy: "cache-and-network",
  });
  const handleLink = () => {
    if (
      data.userOne.role.includes("ORGANIZER") ||
      data.userOne.role.includes("ADMIN")
    ) {
      const token = jwt.sign(
        {
          id: data.userOne._id,
          name: data.userOne.name,
          role: data.userOne.role,
        },
        "secret",
        {
          expiresIn: "1h",
        }
      );
      //localStorage.setItem('token', token) `http://localhost:3300/token?token=${token}`
      window.location.replace(
        `https://admin.parkrunthailand.com/token?token=${token}`
      );
    }
  };
  const handleClick = () => {
    setOpen(!open);
  };
  // const handleClick2 = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleLink2 = (link) => {
  //   window.open(link, '_blank')
  // };
  return (
    <AppBar
      className={clsx(classes.root, className)}
      disableGutters
      elevation={0}
      {...rest}
    >
      <Toolbar>
        <RouterLink to="/home">
          <img
            alt="Logo"
            src={Logo}
            style={{ maxHeight: "60px", width: "auto", display: "block" }}
          />
        </RouterLink>
        <Box flexGrow={1} />
        <Hidden smDown>
          <Button className={classes.nav} href="/home">
            หน้าหลัก
          </Button>
          <Button className={classes.nav} href="/eventMap">
            สนามวิ่ง
          </Button>
          <Button
            className={classes.nav}
            href="/event-vr"
            style={{ textTransform: "none" }}
          >
            Park Run @nywhere
          </Button>
          <Button className={classes.nav} href="/result">
            สถิติโดยรวม
          </Button>
          {/* <Button className={classes.nav} onClick={handleClick2}>สถิติ</Button> */}
          {/* <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            // onClose={handleClose}
          >
            <MenuItem className={classes.nav} onClick={()=>handleLink2('result')}>สถิติโดยรวม</MenuItem>
            <MenuItem className={classes.nav} onClick={()=>handleLink2('resultrunnerage')}>ตารางอายุโดยรวม</MenuItem>
          </Menu> */}
          {/* {(_includes(data.userOne.role, 'ORGANIZER') || _includes(data.userOne.role, 'ADMIN')) && <Button onClick={()=>handleLink()} className={classes.nav}>สำหรับผู้จัด</Button>} */}
          {_includes(_get(data.userOne, "role"), "ORGANIZER") && (
            <Button onClick={() => handleLink()} className={classes.nav}>
              สำหรับผู้จัด
            </Button>
          )}
          {_includes(_get(data.userOne, "role"), "ADMIN") && (
            <Button onClick={() => handleLink()} className={classes.nav}>
              สำหรับแอดมิน
            </Button>
          )}

          {userId ? (
            <React.Fragment>
              <Button
                className={classes.list}
                ref={anchorRef}
                aria-controls={open ? "menu-list-grow" : undefined}
                onClick={handleClick}
              >
                <ListItemAvatar>
                  <Avatar alt="Remy Sharp" src={_get(data.userOne, "image")} />
                </ListItemAvatar>
                <ListItemText className={classes.nav}>
                  {_get(data.userOne, "name")}
                </ListItemText>
                {open ? (
                  <ExpandLess className={classes.nav} />
                ) : (
                  <ExpandMore className={classes.nav} />
                )}
              </Button>
              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom" ? "center top" : "center bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClick}>
                        <MenuList autoFocusItem={open} id="menu-list-grow">
                          <MenuItem
                            component={RouterLink}
                            to="/profile"
                            className={classes.button}
                          >
                            <AccountCircleIcon
                              className={classes.icon}
                              size="20"
                            />
                            <span className={classes.title}>โปรไฟล์</span>
                          </MenuItem>
                          <MenuItem
                            component={RouterLink}
                            to="/eventlist"
                            className={classes.button}
                          >
                            <AccessibilityNewTwoToneIcon
                              className={classes.icon}
                              size="20"
                            />
                            <span className={classes.title}>
                              สำหรับอาสาสมัคร
                            </span>
                          </MenuItem>
                          <MenuItem
                            component={RouterLink}
                            to="/rewardpoint"
                            className={classes.button}
                          >
                            <CardGiftcardIcon
                              className={classes.icon}
                              size="20"
                            />
                            <span className={classes.title}>รางวัลทั้งหมด</span>
                          </MenuItem>
                          {/* <MenuItem component={RouterLink} to="/creteEvent" className={classes.button}>
                            <PostAddIcon
                              className={classes.icon}
                              size="20"
                            />
                            <span className={classes.title}>
                              สร้างงานวิ่ง
                            </span>
                          </MenuItem> */}
                          <MenuItem
                            className={classes.button}
                            onClick={() => signOut()}
                          >
                            <MeetingRoomTwoToneIcon
                              className={classes.icon}
                              size="20"
                            />
                            <span className={classes.title}>ออกจากระบบ</span>
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Button
                className={classes.navlogin}
                variant="contained"
                color="primary"
                href="/actions/login"
              >
                เข้าสู่ระบบ
              </Button>
            </React.Fragment>
          )}
        </Hidden>
        <Hidden mdUp>
          <IconButton className={classes.nav} onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

// TopBar.propTypes = {
//   className: PropTypes.string,
//   onMobileNavOpen: PropTypes.func
// };

export default TopBar;
