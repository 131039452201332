import React, { useState, useEffect, useContext } from 'react';
import { gql, useQuery } from '@apollo/client'
import { Modal, Select, List, Layout, Typography, Card, Spin, notification } from 'antd'
import { useIndexedDB } from 'react-indexed-db'
import { useHistory } from 'react-router-dom'
import AuthContext from '../../../context/AuthContext'
import moment from 'moment'
// import getRoleUser from '../../../graphql/queries/getRoleUser'

const GETEVENTS = gql`
query($userId: MongoID!){
  getRaceVolunteer(userId: $userId){
    name
    slug
    startTime
    endTime
    checkpoints {
      name
      position
      cutOffTime
    }
    _id
    eventId
    event{
      organizId
    }
  }
}
`

//ต้องเพิ่ม filter by valunteer
// const GET_EVENTS = gql`
//   query getRaceByEventId($eventId: MongoID) {
//     raceMany(filter: {
//       eventId: $eventId,
//       deleted: false
//     }) {
//       name
//       slug
//       startTime
//       endTime
//       checkpoints {
//         name
//         position
//         cutOffTime
//       }
//       _id
//       eventId
//       event{
//         organizId
//       }
//     }
//   }
// `

// const GET_VALUNTEER = gql`
//   query raceByIdsInput($_id:MongoID){
//   regisMany(filter:{
//     userId:$_id
//     regisType:"VALUNTEER"
//     status:apporve
//   }){
//     raceId
//     _id
//     userId
//     name
//     user{
//       name
//     }
//     race{
//       name
//       slug
//       startTime
//       endTime
//       checkpoints {
//         name
//         position
//         cutOffTime
//       }
//       _id
//     }
//   }
// }
// `
function ListEvent() {
  const [selectedEvent, setSelectedEvent] = useState(null)
  const [selectedPos, setSelectedPos] = useState(null)
  const [visibleModal, setVisibleModal] = useState(false)

  const { userInfo } = useContext(AuthContext)
  const { loading: loadEvents, data: events } = useQuery(GETEVENTS, { variables: { userId: userInfo._id }, fetchPolicy: 'cache-and-network' })
  // const { loading: loadEvents, data: events } = useQuery(GET_EVENTS, { variables: { organizId: userInfo.organizId }, fetchPolicy: 'cache-and-network'})
  // const { loading: loadValunteer, data: regis } = useQuery(GET_VALUNTEER, { variables: { _id: userInfo._id}, fetchPolicy: 'cache-and-network'})
  // const { loading: loadUser, data: user } = useQuery(getRoleUser, { variables: { _id: userInfo._id}, fetchPolicy: 'cache-and-network'})
  const { add, getAll, clear } = useIndexedDB('checkIn')
  const history = useHistory()

  useEffect(() => {
    async function checkData() {
      const results = await getAll()
      if (results.length > 0) {
        // history.replace('/info')
        await clear()
      }
    }
    checkData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  
  if (loadEvents) return (
    <div style={{display:'flex', justifyContent:'center', alignItems:'center',height:'100vh' }}>
        <Spin tip="Loading...event"/>
    </div>
  )
//   if (loadOrganiz) return <div>loading Organiz</div>
  //const same = regis.regisMany.filter((regis)=>moment(regis.race.startTime).isSameOrAfter(moment(),'day'))
  //console.log({same});
  let { getRaceVolunteer } = events
  
//   const { organizById } = organizer
// const CheckRacesId = (same, raceId) => {
//   const res =  same.map(r => {
//     if(`${r.raceId}` === `${raceId}`){
//       //console.log('eq')
//       return `${r.raceId}`
//     }
//     // eslint-disable-next-line
//     return
//   })
//   let race
//    res.filter((r, index)=> {
//     if(r!== undefined){
//       race = res[index]
//     }
//     return ''
//   })
//   return race
 
// }
  // if(user.userOne.role === 'ORGANIZER'){
  //   raceMany = raceMany.filter((races) => (`${races.event.organizId}` === `${user.userOne._id}` && moment(races.startTime).isSameOrAfter(moment(), 'day')) || `${races._id}` === CheckRacesId(same, races._id))
  // }else{
  //   raceMany = raceMany.filter((races) => `${races._id}` === CheckRacesId(same, races._id))
  // }
  
  //console.log({raceMany});
  const closeModal = () => setVisibleModal(false)
  const handleOk = async () => {
    if (!selectedPos) {
      Modal.error({
        title: 'กรุณาเลือกตำแหน่งที่จะเช็คอิน'
      })
      return
    }
    const checkInData = {
      position: selectedPos,
      slug: selectedEvent.slug,
      name: selectedEvent.name,
      userId: userInfo.userId,
      raceId: selectedEvent._id,
      startTime: selectedEvent.startTime,
    }
    
    await add(checkInData)
    setVisibleModal(false)
    history.push('/info')
  }
  const handleSelectEvent = (index) => {
    const event = getRaceVolunteer[index]
    const startTime = event.startTime
    const endTime = moment(startTime).add(3, "days").toDate()
    if(moment().isAfter(endTime)) {
      notification.error({
        message: 'ไม่สามารถเลือกสนามนี้ได้',
        duration: 5,
        placement: 'bottomRight'
      })
    }
    else {
      setVisibleModal(true)
      // const event = getRaceVolunteer[index]
      setSelectedEvent(event)
    }
    // setVisibleModal(true)
    // const event = getRaceVolunteer[index]
    // setSelectedEvent(event)
  }
  const handleSelectPosition = (value) => {
    setSelectedPos(value.toString())
  }
  // if(loadValunteer){
  //   return <div>loading</div>
  // }
  return (
    <Layout>
      <Layout.Content style={{ padding: 10, backgroundColor: '#fff' }}>
        <Card 
          style={{ width:'100%', height:'50px', backgroundColor:'#CAE4E5', marginTop:'80px',textAlign:'center'}} 
          title="รายการสนามที่คุณเป็นอาสาสมัคร" 
        />
          <List
            bordered
            dataSource={getRaceVolunteer}
            renderItem={(event, index) => (
              <List.Item onClick={() => handleSelectEvent(index)}>
                <Typography.Title level={4}>{event.name}</Typography.Title>
              </List.Item>
            )}
          />
      </Layout.Content>
      <Modal
        title="ตำแหน่งที่จะเช็คอิน"
        visible={visibleModal}
        onCancel={closeModal}
        onOk={handleOk}
      >
        <Select
          style={{ width: 200 }}
          onChange={handleSelectPosition}
        >
          {selectedEvent && selectedEvent.checkpoints.map(({position, name}) => {
            return (
              <Select.Option
                key={position}
                value={position}
              >
                {name} | ตำแหน่งที่ {position}
              </Select.Option>
            )
          })}
        </Select>
      </Modal>
  </Layout>
  );
}

export default ListEvent;
