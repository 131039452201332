import { Box, Divider } from "@material-ui/core";
import React, { useEffect, useState, useContext } from "react";

// import RewardCard from "./RewardCard";
import RewardCardV2 from "./RewardCard_V2";
// import RewardTimeline from "./RewardTimeline";
// import image from "./../../images/Unicorn.jpg";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import { Radio, RadioGroup, FormControl } from "@material-ui/core";

import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import AuthContext from "../../context/AuthContext";
import moment from "moment";
import userPointGQL from "../../graphql/queries/userPointsOne";
import eventMany from "../../graphql/queries/getAllEvent";
import updateUserById from "./../../graphql/mutations/updateUser";
import userPointUpdateById from "./../../graphql/mutations/userPointUpdateById";
import userById from "../../graphql/queries/getUserById";
import rewardOne from "./../../graphql/queries/rewardOne";
// import imgRun from "./../../images/sapiens.svg";
import { Typography } from "antd";
import kerry from "./../../images/kerry.png";
import AppFooter from "../../components/Footer";
import Autocomplete from "@material-ui/lab/Autocomplete";
import RewardTimelineV2 from "./RewardTimelineV2";
import Alert from '@material-ui/lab/Alert';

import "./rewardInfoandStatusV2.css";
export default function RewardInfoandStatus() {
  const { _id } = useParams();
  const { userInfo } = useContext(AuthContext);

  const [achievementData, setAchievementData] = useState("");
  const [reward, setReward] = useState(null);
  const [size, setSize] = useState("");
  const [addressLabel, setAddressLabel] = useState("ที่อยู่ที่บันทึกไว้");
  const [address, setAddress] = useState(null);
  const [status, setStatus] = useState(null);
  const [rememberAddress, setRememberAddress] = useState(null);
  const [userAddress, setUserAddress] = useState(null);
  const [events, setEvents] = useState(null);
  const { Title } = Typography;

  //Query achievement
  const userPoint = useQuery(userPointGQL, {
    variables: { filter: { _id: _id } },
  });

  const rewardInfo = useQuery(rewardOne, {
    variables: { filter: { _id: _id } },
  });

  const user = useQuery(userById, {
    variables: { _id: userInfo._id },
  });

  const eventAll = useQuery(eventMany);

  const [updateUserFn] = useMutation(updateUserById);
  const [userPointUpdateFn, userPointUpdateObj] =
    useMutation(userPointUpdateById);

  useEffect(() => {
    user.refetch();
    userPoint.refetch();
    rewardInfo.refetch();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (userPoint.data && userPoint.data.userPointsOne) {
      // console.log(userPoint.data);
      setAchievementData(userPoint.data.userPointsOne);
      setReward(userPoint.data.userPointsOne.reward);
      // setSize(userPoint.data.userPointsOne.reward.sizeList[0]);
      setStatus(userPoint.data.userPointsOne.rewardStatus);
    }

    if (rewardInfo.data && rewardInfo.data.rewardOne) {
      setReward(rewardInfo.data.rewardOne);
    }

    if (user.data) {
      // console.log(user.data);
      setUserAddress(user.data.userById.address);
      setAddress(user.data.userById.address);
    }
    if (userPointUpdateObj.data) {
      userPoint.refetch();
      user.refetch();
    }

    if (eventAll.data) {
      setEvents(eventAll.data.eventMany);
    }
    //sku
  }, [
    userPoint.data,
    user.data,
    userPointUpdateObj.data,
    rewardInfo.data,
    user,
    userPoint,
    eventAll.data,
  ]);

  const handleSizeChange = (e) => {
    setSize(e.target.value);
  };

  const handleEventChange = (e, value) => {
    if (!value) {
      setAddress(null);
      return;
    }
    setAddress(value.name);
  };

  const handleUserAddress = (e) => {
    // console.log(userAddress);
    setAddressLabel(
      e.target.value === userAddress ? "ที่อยู่ที่บันทึกไว้" : "ที่อยู่ใหม่"
    );
    setAddress(e.target.value);
  };

  const handleSubmit = (e) => {
    if (rememberAddress) {
      //MUTATION ADDRESS TO USER COLLECTION
      updateUserFn({
        variables: {
          record: {
            _id: userInfo._id,
            address: address,
          },
        },
      });
    }

    //MUTATION ADDRESS TO USERPOINT

    userPointUpdateFn({
      variables: {
        record: {
          _id: _id,
          address: address,
          rewardStatus: "เตรียมรางวัล",
          rewardDetail: size,
        },
      },
    });
  };

  const handleCheckBox = (e) => {
    // console.log(e.target.checked);
    setRememberAddress(e.target.checked);
  };

  return (
    <div className="reward-infoandstatusV2">
      <div className="reward-infoandstatusV2-container">
        <div
          className={`reward-infoandstatus_layout ${
            achievementData.achieved
              ? "achievementData.achieved"
              : "grid-option-1row"
          }`}
        >
          {achievementData.achieved && (
            <div className="reward-infoandstatus-interface">
              <Alert severity="warning">ระบบการจัดส่งรางวัลอยู่ระหว่างการทดสอบ</Alert>
              <RewardTimelineV2 status={status} />
              <div className="reward-infoandstatus-interface--box">
                {status === "ยืนยันข้อมูล" && (
                  <Box
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-around",
                      margin: "10px 0 ",
                    }}
                  >
                    <RadioGroup
                      row
                      aria-label="position"
                      name="position"
                      value={
                        reward.anyWhere ? "userAddress" : "raceTrackAddress"
                      }
                      // onChange={handleRadioValue}
                      m={2}
                    >
                      <FormControlLabel
                        value="raceTrackAddress"
                        control={<Radio color="primary" />}
                        disabled={reward.anyWhere ? true : false}
                        label="ส่งรางวัลไปที่สนาม"
                      />
                      <FormControlLabel
                        value="userAddress"
                        control={<Radio color="primary" />}
                        disabled={reward.anyWhere ? false : true}
                        label="ส่งรางวัลไปที่ที่อยู่ของคุณ"
                      />
                    </RadioGroup>

                    {events && reward.parkRun && !reward.anyWhere && (
                      <Autocomplete
                        // style={{ marginY: 10 }}
                        id="combo-box-demo"
                        options={events}
                        getOptionLabel={(option) => option.name}
                        style={{ margin: "10px 0 ", width: "100%" }}
                        onChange={(e, value) => handleEventChange(e, value)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="เลือกสนามที่ต้องการให้จัดส่งรางวัล"
                            variant="filled"
                          />
                        )}
                      />
                    )}

                    {reward.anyWhere && (
                      <TextField
                        id="outlined-multiline-static"
                        label={addressLabel}
                        multiline
                        rows={4}
                        variant="outlined"
                        value={address ? address : ""}
                        onChange={handleUserAddress}
                      />
                    )}
                    {reward.anyWhere && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={handleCheckBox}
                            name="rememberAddress"
                            color="primary"
                          />
                        }
                        label="บันทึกที่อยู่เพื่อใช้ในครั้งถัดไป"
                      />
                    )}
                    <FormControl
                      sx={{ m: 1, minWidth: 120 }}
                      style={{ margin: "10px 0 " }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        เลือกขนาดเสื้อ
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={size}
                        onChange={handleSizeChange}
                      >
                        {reward &&
                          reward.sizeList.map((el, i) => (
                            <MenuItem key={i} value={el}>
                              {el}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    <Button
                      style={{ width: "150px", margin: "10px 0 " }}
                      variant="contained"
                      color="primary"
                      disabled={size && address ? false : true}
                      onClick={handleSubmit}
                    >
                      ยืนยัน
                    </Button>
                  </Box>
                )}
                {status === "เตรียมรางวัล" && (
                  <Box
                    className="reward-infoandstatus-interface-subBox"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      flexWrap: true,
                      // textAlign: "center",
                    }}
                  >
                    <h2>เรากำลังจัดเตรียมรางวัลของคุณ</h2>
                    <Box>
                      <h3 style={{ overflowWrap: "break-word" }}>
                        รางวัลของคุณคือ : {achievementData.reward.rewardItem} ,
                        ขนาด : {achievementData.rewardDetail}
                      </h3>
                      <h3>ที่อยู่จัดส่ง : {achievementData.address}</h3>
                    </Box>
                  </Box>
                )}

                {status === "สำเร็จ" && (
                  <Box
                    className="reward-infoandstatus-interface-subBox"
                    style={{
                      // width: "50%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <h2>เราจัดส่งรางวัลเรียบร้อยแล้ว</h2>
                    <Box>
                      <h3>
                        รางวัลของคุณถูกส่งไปที่ : {achievementData.address}
                      </h3>
                      <h3>
                        รางวัลของคุณคือ : {achievementData.reward.rewardItem} ,
                        ขนาด : {achievementData.rewardDetail}
                      </h3>
                      <Title
                        level={4}
                        copyable={{
                          text: `${achievementData.trackingNo}`,
                        }}
                        style={{ margin: "25px 0" }}
                      >
                        เลขที่พัสดุ : {achievementData.trackingNo}
                      </Title>
                    </Box>
                    <h3>
                      ตรวจสอบสถานะการจัดส่งได้ที่
                      <span>
                        <img
                          alt="kerry"
                          src={kerry}
                          onClick={() =>
                            window.open("https://th.kerryexpress.com/th/track/")
                          }
                          style={{
                            width: "40px",
                            "&:hover": { cursor: "pointer" },
                          }}
                        />
                      </span>
                    </h3>
                  </Box>
                )}
              </div>
            </div>
          )}
          <div className="reward-infandstatus-rewardinfo">
            <div className="reward-infandstatus-rewardinfo-leftCol">
              <Box
                className="reward-infoandstatus-card-detailV2"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                {reward && (
                  <RewardCardV2
                    cardColor={reward.cardColor}
                    activityName={reward.activityName}
                    pointTarget={reward.pointTarget}
                    progressPoint={achievementData.points}
                    achieved={achievementData.achieved}
                    expired={achievementData.expired}
                    image={reward.image}
                    rewardStatusVisible={false}
                  />
                )}

                <Box
                  style={{ justifyContent: "flex-start", margin: "40px 5px" }}
                >
                  <h3 style={{ fontWeight: 700 }}>นับคะแนนจากกิจกรรม :</h3>

                  {reward && (
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={reward.parkRun}
                            inputProps={{ "aria-label": "primary checkbox" }}
                          />
                        }
                        label="Park Run"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={reward.anyWhere}
                            inputProps={{ "aria-label": "primary checkbox" }}
                          />
                        }
                        label="Any Where"
                      />
                    </FormGroup>
                  )}
                </Box>
              </Box>
            </div>
            <div className="reward-infandstatus-rewardinfo-roleActivity">
              <Box style={{ justifyContent: "flex-start", margin: "20px 0" }}>
                {reward && <h2>{`${reward.activityName}`}</h2>}
                <h3 style={{ fontWeight: 700 }}>{"กติกา :"}</h3>

                {reward && <p>{`${reward.roleActivity}`}</p>}

                <h3 style={{ fontWeight: 700 }}>{"วันที่เริ่มสะสมคะแนน :"}</h3>
                {reward && (
                  <p>{`${moment(reward.startDate).format("DD-MM-YYYY")}`}</p>
                )}
                <h3 style={{ fontWeight: 700 }}>
                  {"วันที่สิ้นสุดการสะสมคะแนน :"}
                </h3>
                {reward && (
                  <p>{`${
                    reward.endDate
                      ? moment(reward.endDate).format("DD-MM-YYYY")
                      : "ไม่มีวันสิ้นสุดการสะสมคะแนน"
                  }`}</p>
                )}
              </Box>
              <Divider />
            </div>
            <div className="reward-infandstatus-rewardinfo-rewardImg">
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  marginX: "15px",
                  padding: "16px",
                  // alignItems: "start",
                }}
              >
                <h2>{"รางวัลที่จะได้รับ :"}</h2>
                {reward && (
                  <img
                    alt="rewardImage"
                    src={reward.image}
                    style={{
                      maHeight: "180px",
                      maxWidth: "180px",
                      alignSelf: "center",
                    }}
                  ></img>
                )}
              </Box>
            </div>
            <div>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  // alignItems: "center",
                  // maxHeight: "120px",
                  marginX: "15px",
                  padding: "16px",
                }}
              >
                <h2>{"ขนาดเสื้อ :"}</h2>
                {reward && (
                  <p
                    style={{ alignSelf: "center" }}
                  >{`${reward.rewardCharacter}`}</p>
                )}
              </Box>
            </div>
          </div>
        </div>
      </div>
      <AppFooter description="795 ถนน บรรทัดทอง แขวง วังใหม่ เขตปทุมวัน กรุงเทพมหานคร 10330" />
    </div>
  );
}
