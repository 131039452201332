import React, { Fragment, useState, useMemo, useContext } from "react";
// import PropTypes from 'prop-types';
import clsx from "clsx";
import _orderBy from "lodash/orderBy";
import { useParams, useHistory } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import {
  Container,
  makeStyles,
  withStyles,
  Grid,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
  Avatar,
  CardContent,
  TablePagination,
  Divider,
  Link,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { notification } from "antd";
import "./css/event.css";
import moment from "moment";
import _find from "lodash/find";

import DateRangeIcon from "@material-ui/icons/DateRange";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import FacebookIcon from "@material-ui/icons/Facebook";

import client from "../index";
import AppFooter from "../components/Footer";
import AuthContext from "../context/AuthContext";
import RegisterEvent from "../graphql/mutations/registerEvent";
import getRace from "../graphql/queries/getRaceFromEventId";
import getEventOne from "../graphql/queries/getEventOne";
import getRegister from "../graphql/queries/getRegister";
import thankValunTeer from "../graphql/queries/thankVol";

import "moment/locale/th";

import Stats from "../components/AllStats";

moment.locale("th");

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    display: "flex",
    justifyContent: "center",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  carouimg: {
    width: "100%",
    maxHeight: "450px",
  },
  imageHover: {
    width: "100%",
    maxHeight: "450px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
  },
  icon: {
    position: "relative",
    marginRight: theme.spacing(1),
  },
  icon2: {
    position: "relative",
    marginRight: theme.spacing(1),
    color: "red"
  },
  statsItem: {
    alignItems: "center",
    display: "flex",
  },
  table: {
    minWidth: "100%",
  },
}));

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#CAE4E5",
    color: "#000000",
    fontSize: 14,
    "@media (max-width: 320px)": {
      fontSize: 12,
    },
  },
  body: {
    fontSize: 12,
    "@media (max-width: 320px)": {
      fontSize: 10,
    },
  },
}))(TableCell);

const StyledTableRow = withStyles(() => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#ecf4f3",
    },
  },
}))(TableRow);

function createData(datetime, runner, volunteer) {
  return {
    datetime,
    runner,
    volunteer,
  };
}
const EventDetail = ({ className, customers, ...rest }) => {
  const classes = useStyles();
  const history = useHistory();
  const { eventId } = useParams();
  const { userInfo } = useContext(AuthContext);
  const [event, setEvent] = useState({});
  const [regisManyData, setregisManyData] = useState([]);
  const [open, setOpen] = useState(false);
  const [opendialog, setOpendialog] = useState(false);
  const [registerEvent] = useMutation(RegisterEvent);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const { data = { raceMany: [] }, loading } = useQuery(getRace, {
    client,
    variables: {
      eventId,
    },
    fetchPolicy: "cache-and-network",
  });
  const regisMany = useQuery(getRegister, {
    client,
    // variables: {
    //   userId: userInfo._id
    // },
    fetchPolicy: "cache-and-network",
    skip: true,
  });
  const { data: data3 = { thankValunTeer: {} }, loading: loading3 } = useQuery(
    thankValunTeer,
    {
      client,
      variables: {
        eventId,
      },
      fetchPolicy: "cache-and-network",
    }
  );
  const eventOne = useQuery(getEventOne, {
    client,
    fetchPolicy: "cache-and-network",
    skip: true,
  });
  // const [setShow] = useState(false)
  useMemo(() => {
    if (eventId) {
      eventOne
        .refetch({
          _id: eventId,
        })
        .then((result) => {
          setEvent(result.data.eventOne);
        });
    }
    if (userInfo._id) {
      regisMany
        .refetch({
          userId: userInfo._id,
        })
        .then((result) => {
          setregisManyData(result.data.regisMany);
        });
    }
  }, [eventId, eventOne, userInfo, regisMany]);

  // const handleClose = () => setShow(false)
  const handleLink = async (link, _id) => {
    if (link) {
      window.open(link, "_blank");
    } else {
      window.open(
        `${process.env.REACT_APP_CLIENT_URL}/events/${_id}/result`,
        "_blank"
      );
    }
  };
  // const handleClose = async (_id) => {
  //   if(_id){
  //     window.open(`https://parkrunthailand.com/profile`, '_blank')
  //   }
  // }
  const handleClickOpen = async (type) => {
    if (type === "RUNNER") {
      setOpen(true);
    } else {
      setOpendialog(true);
    }
  };

  const handleCloseDialog = async () => {
    setOpen(false);
    setOpendialog(false);
  };
  const handleOK = async (id, type) => {
    // window.open(link, '_blank')
    try {
      await registerEvent({
        client,
        variables: {
          name: event.name,
          eventId: `${eventId}`,
          userId: `${userInfo._id}`,
          raceId: `${id}`,
          regisType: `${type}`,
        },
        // fetchPolicy: 'cache-and-network',
      });
      notification.success({
        message: `สมัครสำเร็จ`,
        duration: 5,
        placement: "bottomRight",
      });
      // handleClose()
      history.push(`/eventMap`);
    } catch (err) {
      console.log(err);
    }
  };
  if (loading) {
    return <p>loading</p>;
  }
  if (regisMany.loading) {
    return <p>loading</p>;
  }
  if (loading3) {
    return <p>loading</p>;
  }
  // console.log(regisManyData);
  // console.table(data.raceMany);
  const races = _orderBy(
    data.raceMany.filter((race) =>
      moment(race.startTime).isSameOrAfter(moment("2020-12-05T00:00:00.000Z"))
    ),
    ["_id"],
    ["desc"]
  );
  const racesStatus = data.raceMany.filter(({ status }) => status === "open");

  const rows = races.map((item, index) =>
    createData(
      moment(item.startTime).format("LLLL") +
        ` ${item.status === "open" ? "เปิด" : "ปิด"}`,

      moment(item.startTime).isAfter(moment()) ? (
        _find(racesStatus, function (o) {
          return o.status === item.status;
        }) ? (
          _find(regisManyData, function (o) {
            return o.race.startTime === item.startTime;
          }) ? (
            <Button color="textSecondary" fullWidth variant="boby1" disabled>
              สมัคร
            </Button>
          ) : (
            <div>
              <Button
                color="textSecondary"
                fullWidth
                variant="boby1"
                onClick={() => {
                  handleClickOpen("RUNNER");
                }}
              >
                สมัคร
              </Button>
              <Dialog
                open={open}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title1">
                  {"ยืนยันที่จะสมัครเป็นนักวิ่ง ?"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    ยืนยันการสมัครเป็นนักวิ่งในสนามนี้
                    เมื่อคุณสมัครเป็นนักวิ่งแล้วคุณจะไม่สามารถเป็นอาสาสมัครได้
                    คุณจะไม่สามารถสมัครของสนามอื่นในวันนี้ คุณยืนยันที่จะสมัคร.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseDialog} color="primary">
                    ยกเลิก
                  </Button>
                  <Button
                    onClick={() => handleOK(item._id, "RUNNER")}
                    color="primary"
                    autoFocus
                  >
                    ยืนยัน
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          )
        ) : (
          <Button color="textSecondary" fullWidth variant="boby1" disabled>
            สมัคร
          </Button>
        )
      ) : (
        <Button
          color="textSecondary"
          fullWidth
          variant="boby1"
          onClick={() => handleLink(item.link, item._id)}
        >
          ดูผล
        </Button>
      ),

      moment(item.startTime).isAfter(moment()) ? (
        _find(racesStatus, function (o) {
          return o.status === item.status;
        }) ? (
          _find(regisManyData, function (o) {
            return o.race.startTime === item.startTime;
          }) ? (
            <Button color="textSecondary" fullWidth variant="boby1" disabled>
              สมัคร
            </Button>
          ) : (
            <div>
              <Button
                color="textSecondary"
                fullWidth
                variant="boby1"
                onClick={() => {
                  handleClickOpen("VALUNTEER");
                }}
              >
                สมัคร
              </Button>
              <Dialog
                open={opendialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title2">
                  {"ยืนยันที่จะสมัครเป็นอาสาสมัคร ?"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    ยืนยันสมัครเป็นอาสาสมัครในสนามนี้
                    เมื่อคุณสมัครเป็นอาสาสมัครคุณจะไม่สามารถเป็นนักวิ่งได้
                    คุณจะไม่สามารถสมัครของสนามอื่นในวันนี้ คุณยืนยันที่จะสมัคร.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseDialog} color="primary">
                    ยกเลิก
                  </Button>
                  <Button
                    onClick={() => handleOK(item._id, "VALUNTEER")}
                    color="primary"
                    autoFocus
                  >
                    ยืนยัน
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          )
        ) : (
          <Button color="textSecondary" fullWidth variant="boby1" disabled>
            สมัคร
          </Button>
        )
      ) : (
        "-"
      )
    )
  );
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <Fragment>
      <Container maxWidth="lg">
        <Grid container spacing={1} style={{ marginTop: "80px" }}>
          <Grid item lg={12} xs={12}>
            <div className={classes.carouimg}>
              <img
                className={classes.imageHover}
                alt={event.title}
                src={event.banner}
              />
            </div>
          </Grid>
          <Grid item lg={8} md={8} xl={9} xs={12}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Card
                  elevation={0}
                  className={clsx(classes.root, className)}
                  square
                >
                  <CardContent>
                    <Typography
                      align="left"
                      variant="h5"
                      className="titleEvent"
                    >
                      {event.name}
                    </Typography>
                    <Grid className={classes.statsItem} item>
                      <DateRangeIcon
                        fontSize="small"
                        className={classes.icon}
                      />
                      <Typography align="left" variant="subtitle1">
                        :&ensp;
                        {event.levels === "every"
                          ? "จัดทุกสัปดาห์"
                          : event.levels === "twice"
                          ? "จัดเดือนละ 2 ครั้ง (จัดวันเสาร์แรกและเสาร์สามของเดือน)"
                          : "จัดเดือนละ 1 ครั้ง (จัดวันเสาร์แรก)"}
                      </Typography>
                    </Grid>
                    <Grid className={classes.statsItem} item>
                      <LocationOnIcon
                        fontSize="small"
                        className={classes.icon}
                      />
                      <Typography align="left" variant="subtitle1">
                        :&ensp;{event.location}
                      </Typography>
                      <br/>
                      <a href={`https://www.google.co.th/maps/place/${event.address}`} color="red" target="_blank" rel="noopener noreferrer">
                        <LocationOnIcon
                          fontSize="small"
                          className={classes.icon2}
                        />
                      </a>
                    </Grid>
                    <Grid className={classes.statsItem} item>
                      <PersonPinIcon
                        fontSize="small"
                        className={classes.icon}
                      />
                      <Typography align="left" variant="subtitle1">
                        :&ensp;{event.organizEmail}
                      </Typography>
                    </Grid>
                    <Grid className={classes.statsItem} item>
                      <FacebookIcon fontSize="small" className={classes.icon} />
                      <Typography align="left" variant="subtitle1">
                        :&ensp;{event.facebookURL}
                      </Typography>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card
                  elevation={0}
                  className={clsx(classes.root, className)}
                  square
                >
                  <Divider variant="middle" />
                  <CardContent>
                    <Typography
                      className="textEvent02"
                      align="center"
                      variant="body1"
                    >
                      แผนที่วิ่ง {event.name}{" "}
                    </Typography>
                    <img
                      alt={event.title}
                      src={event.eventRoute}
                      className="paperEvent"
                    />
                    <Typography
                      className="textEvent03"
                      variant="body1"
                      style={{ marginTop: "10px" }}
                    >
                      รายละเอียดแผนที่วิ่ง
                    </Typography>
                    <Typography className="textEvent05" variant="body2">
                      &ensp;{event.road}
                    </Typography>
                    <Typography className="textEvent03" variant="body1">
                      จุดนัดพบ
                    </Typography>
                    <Typography className="textEvent05" variant="body2">
                      &ensp;{event.startPoint}
                    </Typography>
                    <Typography className="textEvent03" variant="body1">
                      การเดินทางไปจุดนัดพบ
                    </Typography>
                    <Typography className="textEvent05" variant="body2">
                      &ensp;{event.route}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card
                  elevation={0}
                  className={clsx(classes.root, className)}
                  square
                >
                  <Divider variant="middle" />
                  <CardContent>
                    <Typography
                      className="textEvent02"
                      align="center"
                      variant="body1"
                    >
                      ขอบคุณอาสาสมัคร {event.name} สำหรับสัปดาห์ที่ผ่านมา
                    </Typography>
                    <div className={classes.avatar}>
                      {data3.thankValunTeer.map((dataUser) => (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Avatar alt="" src={dataUser.image} />
                          <Link
                            onClick={() =>
                              history.push(`/parkrunner/${dataUser.bib}`)
                            }
                          >
                            <Typography
                              className="textEvent03"
                              align="center"
                              variant="body2"
                            >
                              {dataUser.name} ,{" "}
                            </Typography>
                          </Link>
                        </div>
                      ))}
                    </div>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card
                  elevation={0}
                  className={clsx(classes.root, className)}
                  square
                >
                  <Divider variant="middle" />
                  <CardContent>
                    <Typography
                      className="textEvent02"
                      align="center"
                      variant="body1"
                    >
                      ผู้สนับสนุน {event.name}{" "}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={4} md={4} xl={3} xs={12}>
            <Card
              elevation={0}
              className={clsx(classes.root, className)}
              square
            >
              <CardContent style={{ overflowX: "auto" }}>
                <Typography
                  className="textEvent02"
                  align="center"
                  variant="body1"
                >
                  ตารางเวลาจัด {event.name}
                </Typography>
                <Table className={classes.table} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center" style={{ width: "40%" }}>
                        วันที่และเวลา
                      </StyledTableCell>
                      <StyledTableCell align="center" style={{ width: "30%" }}>
                        นักวิ่ง
                      </StyledTableCell>
                      <StyledTableCell align="center" style={{ width: "30%" }}>
                        อาสาสมัคร
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(rowsPerPage > 0
                      ? rows.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                      : rows
                    ).map((row) => (
                      <StyledTableRow key={row.name}>
                        <StyledTableCell component="th" scope="row">
                          {row.datetime}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.runner}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.volunteer}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                <p />
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
                <Divider variant="middle" />
                <Typography
                  className="textEvent02"
                  align="center"
                  variant="body1"
                >
                  เคล็ดลับ {event.name}
                </Typography>
                <Typography className="textEvent03" variant="body2">
                  • อย่าลืมคิวอาร์โค้ด!
                  คุณสามารถพิมพ์สำเนาคิวอาร์โค้ดของคุณได้ที่หน้าโปรไฟล์ของคุณและนำมาด้วยเสมอ
                </Typography>
                <Typography className="textEvent03" variant="body2">
                  • เราดำเนินนโยบาย“ ไม่มีคิวอาร์โค้ด = ไม่มีผลวิ่ง”
                  หากคุณลืมคิวอาร์โค้ด คุณจะไม่ได้รับการบันทึกผลวิ่งของคุณ
                </Typography>
                <Typography className="textEvent03" variant="body2">
                  • ถ้าหากคุณต้องการวิ่งมี 2 ช่องทาง คือ กดสมัครนักวิ่งในตาราง
                  หรือ นำคิวอาร์โค้ดไปที่สนามวิ่ง
                </Typography>
                <Typography className="textEvent03" variant="body2">
                  • กิจกรรมนี้ดำเนินการโดยอาสาสมัครที่รักการวิ่ง
                  หากคุณสมัครเป็นอาสาสมัครแล้ว
                  คุณไม่สามารถสมัครเป็นนักวิ่งในรายการเดียวกัน
                  ต้องเลือกอย่างใดอย่างหนึ่ง!
                </Typography>
                <Typography className="textEvent03" variant="body2">
                  • คุณได้เป็นอาสาสมัครในสัปดาห์นี้หรือไม่ ?
                  ตรวจสอบผลอนุมัติที่หน้าโปรไฟล์ของคุณ
                  โดยปกติผู้จัดจะเป็นคนอนุมัติก่อนวันจัดกิจกรรมเสมอ
                </Typography>
                <Typography className="textEvent03" variant="body2">
                  • ไม่ได้รับข้อความแจ้งผลวิ่งของคุณ ?
                  อาจอยู่ในโฟลเดอร์สแปมของคุณ หรือใช้เราช่วยเหลือได้
                </Typography>
                <Typography className="textEvent03" variant="body2">
                  • สถิติโดยของ {event.name} สรุปอยู่ที่ด้านล่างของทุกหน้า
                </Typography>
                <Typography className="textEvent03" variant="body2">
                  • คำถามอื่นๆ อ่านที่เว็บไซต์หน้าหลัก
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      {/* -------------------------------------------------------- */}
      <Stats />
      {/* -------------------------------------------------------- */}
      <AppFooter description="795 ถนน บรรทัดทอง แขวง วังใหม่ เขตปทุมวัน กรุงเทพมหานคร 10330" />
    </Fragment>
  );
};

export default EventDetail;
