import React, { useContext, useEffect, useState } from "react";

import "./RewardCoinsContainer.css";
import { Box, Card, Grid, CardHeader, Divider, Badge } from "@material-ui/core";
import RewardCoin from "./RewardCoin";
import AuthContext from "../../context/AuthContext";
import { useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import userPointGQL from "../../graphql/queries/userPointsMany";
import Alert from "@material-ui/lab/Alert";
// import CircularProgress from "@material-ui/core/CircularProgress";

export default function RewardCoinsContainer({ classname }) {
  const { userInfo } = useContext(AuthContext);
  const history = useHistory();
  const [achievementData, setAchievementData] = useState(null);

  const userPointOjb = useQuery(userPointGQL, {
    variables: {
      filter: { userId: userInfo._id, deleted: false, achieved: true },
    },
  });

  useEffect(() => {
    userPointOjb.refetch();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (userPointOjb.data) {
      if (userPointOjb.data.userPointsMany.length > 0) {
        let sortData = userPointOjb.data.userPointsMany.filter(
          (el) => !el.reward.deleted
        );

        const achieved = sortData.filter((el) => {
          return el.achieved;
        });

        const activeData = achieved.filter((el) => {
          return el.address === null || el.address === "";
        });

        const noneActiveData = achieved.filter((el) => {
          return el.address;
        });
        setAchievementData(activeData.concat(noneActiveData));
      }
    }
  }, [userPointOjb.data]);

  const handleClick = () => {
    history.push("/rewardpoint");
  };

  return (
    <div>
      <Grid container spacing={2} className={classname}>
        <Grid item lg={12} xs={12}>
          <Card
            className="coins_container-card"
            style={{
              boxShadow: "10px 10px 20px #ced4da",
              border: "#868e96",
            }}
          >
            <CardHeader
              title="รางวัลที่ได้รับ"
              style={{ textAlign: "start", backgroundColor: "#CAE4E5" }}
            />
            <Divider />
            {userPointOjb.error && (
              <Alert severity="error">
                เกิดข้อผิดพลาดในการแสดงรางวัล กรุณาลองอีกครั้ง
              </Alert>
            )}
            {userPointOjb.loading && <div>loading</div>}

            <Box>
              <Box
                style={{
                  padding: "30px",
                }}
              >
                <Grid container alignItems="center" spacing={4}>
                  {achievementData ? (
                    achievementData.map((el, i) => (
                      <Grid item key={i} xs={6} sm={4} md={2} lg={2}>
                        <Box>
                          <Badge
                            badgeContent={"New"}
                            color="error"
                            // overlap="circular"
                            overlap="circle"
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            invisible={el.address ? true : false}
                          >
                            <RewardCoin
                              _id={el._id}
                              // handleClick={handleClick}
                              cardColor={el.reward.cardColor}
                              activityName={el.reward.activityName}
                              pointTarget={el.reward.pointTarget}
                              activeStatus={el.address ? "" : "active"}
                              image={el.reward.image}
                              status={el.rewardStatus}
                            />
                          </Badge>
                        </Box>
                      </Grid>
                    ))
                  ) : (
                    <h3>คุณยังไม่ได้รับรางวัล</h3>
                  )}
                </Grid>

                <Box
                  style={{
                    textAlign: "end",
                    color: "#444",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                  className={"reward-container_link"}
                  onClick={handleClick}
                >
                  ดูรางวัลทั้งหมด
                </Box>
              </Box>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
