export const DBConfig = {
    name: 'checkpointDB',
    version: 1,
    objectStoresMeta: [
      {
        store: 'checkIn',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
          { name: 'slug', keypath: 'slug', options: { unique: false } },
          { name: 'position', keypath: 'position', options: { unique: false } },
          { name: 'userId', keypath: 'userId' }
        ]
      }, {
        store: 'checkpoint',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
          { name: 'position', keypath: 'position' },
          { name: 'time', keypath: 'time' },
          { name: 'slug', keypath: 'slug' },
          { name: 'status', keypath: 'status' },
          { name: 'userId', keypath: 'userId' },
          { name: 'runnerId' ,keyPath: 'runnerId'}
        ]
      }
    ]
  }