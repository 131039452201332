import React, { useContext} from "react";
import { useQuery } from '@apollo/react-hooks'
import { 
    Layout,
    Row,
    Col,
} from 'antd';
import { Typography } from '@material-ui/core'

import logo from '../../images/logobib.png'
import QRCode from 'react-qr-code'
import getUser from '../../graphql/queries/getUserOne'
import AuthContext from '../../context/AuthContext'

const {Content} = Layout;


export default function ConverQR() {
    //const classes = useStyles()
    const { userInfo } = useContext(AuthContext)
    const { data = { userOne: {} } } = useQuery(getUser, {
      variables: {
        _id: `${userInfo._id}`
      },
      fetchPolicy: 'cache-and-network',
    })
    console.log(data)

  return (
    <Layout className="layout">
        <Content style={{marginTop:'120px'}}>
          <Row justify="flex" align="center">
            <Col lg={24} xs={24} style={{width:'100%', display:'flex', justifyContent:'center'}}>
                <img src={logo} alt="logo" style={{height:'80px',padding:'10px'}} />
            </Col>
            <Col lg={24} xs={24} style={{width:'100%', display:'flex', justifyContent:'center'}}>
              <QRCode value={`${data.userOne._id}`} style={{ width:'100%',height:'auto',marginTop:'10px'}}/>
            </Col>
            <Col lg={24} xs={24} style={{display:'flex', justifyContent:'center'}}>
              <Typography gutterBottom variant="h3" align="center" style={{color:'#000000', marginTop:'5px', fontSize:'20px' }}>
                 0000001
              </Typography>
            </Col>
            <Col lg={24} xs={24} style={{display:'flex', justifyContent:'center'}}>
              <Typography gutterBottom variant="h3" align="center" style={{color:'#000000', marginTop:'-5px', fontSize:'24px', fontWeight:400 }}>
                {data.userOne.name}  
              </Typography>
            </Col>
          </Row>
        </Content>
    </Layout>
  );
}
