import React, {
  useEffect,
  useReducer,
  useMemo,
  useContext,
  useState,
} from "react";
import firebase from "firebase/app";
import "firebase/auth";
import { gql } from "@apollo/client";

import { useMutation } from "@apollo/react-hooks";
// import { Modal } from 'antd'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import "antd/dist/antd.css";
import "./index.css";

import AuthContext from "./context/AuthContext";
import Home from "./pages/Home";
// import Register from './components/Register'
import MasterLayout from "./layouts/MasterLayout/TopBar";
import NavBar from "./layouts/MasterLayout/NavBar";
// import Header from './components/AppNavMenu'
import CreateEvent from "./pages/CreateEventPage";
// import Event from './pages/Event'
import Profile from "./views/account/Profile";
import Editprofile from "./views/account/EditProfilePage";
import Parkrunner from "./views/account/Parkrunner";
import EventDetail from "./pages/EventDetail";
import PasswordResetRequest from "./pages/ResetPassRequest";
import PasswordReset from "./pages/resetPassword";

import EventList from "./pages/checkpoint/Event/EventList";
import Info from "./pages/checkpoint/Info/info";
import Reader from "./pages/checkpoint/Reader/reader";
import QRall from "./pages/QRCode/generator";
import Convert from "./pages/QRCode/convertQR";
// import EventBoard from './pages/results/result'
import EventMap from "./pages/EventMap";
import Result from "./pages/results/result";
import Stats from "./components/Stats/stats";
import UserAge from "./components/Stats/userage";
import Actions from "./components/Actions";
import Passport from "./components/Passport";
import EventVR from "./pages/EventVR";
import AnywhereResult from "./pages/results/anywhereResult";
// import AllCheckpoints from './Checkpoints/AllCheckpoints'
// mport EventByOrganiz from './pages/EventByOrganiz'
import CREATEUSER from "./graphql/mutations/createUser";
// import EventMap from './pages/EventMap'
import UpdateUser from "./graphql/mutations/updateUser";
import RewardPoint from "./views/Reward/RewardPoint";
// import RewardInfoandStatus from "./views/Reward/RewardInfoandStatus";
import RewardInfoandStatusV2 from "./views/Reward/RewardInfoandStatusV2";

import PrivatePolicy from "./PrivatePolicy/PrivatePolicy";

import client from "./index";

const GET_USER = gql`
  query getUser($userId: String!) {
    userOne(filter: { userId: $userId }) {
      userId
      organizId
      email
      _id
    }
  }
`;

function PrivateRoute({ children, ...rest }) {
  const { isLoading, userId } = useContext(AuthContext);
  // console.log(userId)

  if (isLoading) return <div>checking auth.</div>;

  return (
    <Route
      {...rest}
      render={({ location }) =>
        userId ? (
          children
        ) : (
          <Redirect
            to={{ pathname: "/actions/login", state: { from: location } }}
          />
        )
      }
    />
  );
}

export default function RouterConfig() {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  // const { client } = useQuery(GET_USER, { variables: { userId: "" }})
  const [state, dispatch] = useReducer(
    (prevState, action) => {
      switch (action.type) {
        case "RESTORE_TOKEN":
          return {
            ...prevState,
            userId: action.userId,
            userInfo: action.userInfo,
            isLoading: false,
          };
        case "SIGN_IN":
          return {
            ...prevState,
            isSignout: false,
            userId: action.userId,
            userInfo: action.userInfo,
          };
        case "REGISTER":
          return {
            ...prevState,
            isSignout: false,
            email: action.email,
            password: action.password,
          };
        case "SIGN_OUT":
          return {
            ...prevState,
            isLoading: false,
            isSignout: true,
            userId: null,
            userInfo: {},
          };
        default:
      }
    },
    {
      isLoading: true,
      isSignout: false,
      userId: null,
      userInfo: {},
    }
  );

  useEffect(() => {
    document.title = "Parkrun Thailand";
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const { data } = await client.query({
          query: GET_USER,
          variables: { userId: user.uid },
        });
        if (!data.userOne) {
          dispatch({ type: "SIGN_OUT" });
          return;
        }
        const userInfo = data.userOne;
        dispatch({ type: "RESTORE_TOKEN", userId: user.uid, userInfo });
      } else {
        dispatch({ type: "SIGN_OUT" });
      }
    });
  }, []);

  const [createUser] = useMutation(CREATEUSER, {
    client,
  });
  const [updateUser] = useMutation(UpdateUser, {
    client,
  });

  const authContext = useMemo(
    () => ({
      isLoading: state.isLoading,
      userId: state.userId,
      userInfo: state.userInfo,
      signIn: async (data) =>
        firebase
          .auth()
          .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
          .then(() =>
            firebase
              .auth()
              .signInWithEmailAndPassword(data.email, data.password)
              .then(({ user }) =>
                client
                  .query({
                    query: GET_USER,
                    variables: {
                      userId: user.uid,
                    },
                    fetchPolicy: "network-only",
                  })
                  .then(({ data }) => {
                    if (!data.userOne) {
                      throw Error("ไม่พบข้อมูลในฐานข้อมูล");
                    }
                    const userInfo = data.userOne;
                    dispatch({ type: "SIGN_IN", userId: user.uid, userInfo });
                    return userInfo;
                  })
              )
          )
          .catch((error) => {
            throw error;
          }),
      signOut: async () => {
        await firebase.auth().signOut();
        dispatch({ type: "SIGN_OUT" });
        window.location.replace("/");
      },
      //=> firebase.auth().createUserWithEmailAndPassword(regis.email, regis.password).then(async ({ user })
      register: async (regis) => {
        const { data } = await createUser({
          variables: {
            // userId: user.uid,
            name: regis.name,
            idcard: regis.idcard,
            drug: regis.drug,
            phone: regis.phone,
            emergenPhone: regis.emergenPhone,
            birthdate: regis.birthDate,
            email: regis.email,
            password: regis.password,
            gender: regis.gender,
          },
        });
        // console.log(data)
        return data;
      },
      registerCode: async (regis) =>
        firebase
          .auth()
          .createUserWithEmailAndPassword(regis.email, regis.password)
          .then(async ({ user }) => {
            const { data } = await updateUser({
              variables: {
                record: {
                  _id: regis._id,
                  userId: user.uid,
                  name: regis.name,
                  idcard: regis.idcard,
                  drug: regis.drug,
                  phone: regis.phone,
                  emergenPhone: regis.emergenPhone,
                  birthDate: regis.birthDate,
                  email: regis.email,
                  password: regis.password,
                  gender: regis.gender,
                },
              },
            });
            // console.log(data)
            return data;
          }),
    }),
    [createUser, updateUser, state.isLoading, state.userId, state.userInfo]
  );

  return (
    <AuthContext.Provider value={authContext}>
      <Router>
        <NavBar
          userId={state.userId}
          onMobileClose={() => setMobileNavOpen(false)}
          openMobile={isMobileNavOpen}
        />
        <MasterLayout
          userId={state.userId}
          onMobileNavOpen={() => setMobileNavOpen(true)}
        />
        {/* <MasterLayout  onMobileNavOpen={() => setMobileNavOpen(true)} /> */}
        <Switch>
          <Route
            exact
            path="/"
            render={() => {
              if (state.isLoading) {
                return <div>Checking Auth</div>;
              }
              return state.userId ? (
                <Redirect to={{ pathname: "/home" }} />
              ) : (
                <Home />
              );
            }}
          />
          <Route
            path="/login"
            render={({ history }) => {
              if (state.isLoading) {
                return <div>Checking Auth</div>;
              }
              return state.userId ? (
                <Redirect to={{ pathname: "/home" }} />
              ) : (
                history.replace("/actions/login")
              );
            }}
          />
          <Route path="/home" component={Home} />
          <Route path="/actions" component={Actions} />
          <Route path="/result" component={Stats} />
          <Route path="/resultrunnerage" component={UserAge} />
          <Route path="/privacy-policy" component={PrivatePolicy} />
          <Route exact path="/passport">
            <Passport />
          </Route>
          {/* <Route exact path="/event">
            <Event />
          </Route> */}
          <Route path="/event/:eventId">
            <EventDetail />
          </Route>
          <Route path="/eventMap" component={EventMap} />
          <Route path="/event-vr" component={EventVR} />
          <Route path="/anywhere/:eventId/result/:date">
            <AnywhereResult />
          </Route>
          <Route path="/events/:eventId/result">
            <Result />
          </Route>
          <Route path="/parkrunner/:bib">
            <Parkrunner />
          </Route>
          <Route path="/password/reset">
            <PasswordResetRequest />
          </Route>
          <Route path="/passwordreset/:token">
            <PasswordReset />
          </Route>
          <PrivateRoute>
            <Route exact path="/createEvent">
              <CreateEvent />
            </Route>
            <Route path="/profile">
              <Profile />
            </Route>
            <Route path="/rewardpoint">
              <RewardPoint />
            </Route>
            <Route path="/reward/:_id">
              {/* <RewardInfoandStatus /> */}
              <RewardInfoandStatusV2 />
            </Route>
            <Route path="/editprofile" component={Editprofile} />
            <Route path="/convert">
              <Convert />
            </Route>

            <Route path="/eventlist">
              <EventList />
            </Route>
            {/* <Route path="/event/:eventId">
              <EventDetail/>
            </Route> */}
            <Route path="/info">
              <Info />
            </Route>
            <Route path="/reader">
              <Reader />
            </Route>
            <Route path="/all">{/* <AllCheckpoints /> */}</Route>
            <Route path="/qrall">
              <QRall />
            </Route>
          </PrivateRoute>
        </Switch>
      </Router>
    </AuthContext.Provider>
  );
}
