import React, { useContext, useEffect, useState } from "react";
import "./RewardCoinsContainer.css";
import { useQuery } from "@apollo/client";
import AuthContext from "../../context/AuthContext";
import RewardCardV2 from "./RewardCard_V2";
// import RewardCard from "./RewardCard";
import { Grid, Box, Divider, Typography } from "@material-ui/core";
import AppFooter from "../../components/Footer";
import userPointGQL from "./../../graphql/queries/userPointsMany";
import rewardMany from "./../../graphql/queries/rewardMany";

export default function RewardPoint() {
  const { userInfo } = useContext(AuthContext);
  const [activeActivities, setActiveActivities] = useState(null);
  const [expiredActivities, setExpiredActivities] = useState(null);
  const [completeAcitivities, setCompleteAcitivities] = useState(null);
  const [newActivities, setNewActivities] = useState(null);
  //QUERY USER POINT
  const userPoint = useQuery(userPointGQL, {
    variables: {
      filter: { userId: userInfo._id },
    },
  });

  const allReward = useQuery(rewardMany, {
    variables: {
      filter: { deleted: false },
    },
  });

  useEffect(() => {
    userPoint.refetch();
    allReward.refetch();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (userPoint.data) {
      // console.log(userPoint.data);
      const activeUserPoint = userPoint.data.userPointsMany.filter(
        (el) => !el.reward.deleted
      );

      // const achieved = activeUserPoint.filter((el) => el.achieved);
      let achieved = [];
      activeUserPoint.forEach((el) => {
        if (!el.achieved) return;

        let sortStatus;
        switch (el.rewardStatus) {
          case "ยืนยันข้อมูล":
            sortStatus = -1;
            break;
          case "เตรียมรางวัล":
            sortStatus = 0;
            break;
          case "สำเร็จ":
            sortStatus = 1;
            break;
          default:
            sortStatus = 0;
        }
        achieved.push({ ...el, sortStatus });
      });

      achieved.sort((a, b) => a.sortStatus - b.sortStatus);
      setCompleteAcitivities(achieved.length <= 0 ? null : achieved);

      const active = activeUserPoint.filter(
        (el) =>
          !el.achieved &&
          (new Date(el.reward.endDate) > new Date(Date.now()) ||
            el.reward.endDate === null)
      );

      function compare(a, b) {
        if (
          (a.points * 100) / a.reward.pointTarget <
          (b.points * 100) / b.reward.pointTarget
        ) {
          return 1;
        }

        if (
          (a.points * 100) / a.reward.pointTarget >
          (b.points * 100) / b.reward.pointTarget
        ) {
          return -1;
        }
        return 0;
      }

      setActiveActivities(active.length <= 0 ? null : active.sort(compare));

      const expiredUserPoint = activeUserPoint.filter(
        (el) =>
          el.reward.endDate !== null &&
          new Date(el.reward.endDate) < new Date(Date.now()) &&
          !el.achieved
      );
      // console.log(expiredUserPoint);
      setExpiredActivities(
        // expiredUserPoint
        expiredUserPoint.length <= 0 ? null : expiredUserPoint
      );

      if (allReward.data) {
        // console.log(allReward.data);
        let newReward = allReward.data.rewardMany.filter((reward) => {
          return (
            !activeUserPoint.find((point) => reward._id === point.rewardId) &&
            (new Date(reward.endDate) > new Date(Date.now()) ||
              reward.endDate === null)
          );
        });
        setNewActivities(newReward.length <= 0 ? null : newReward);
      }
    }
  }, [userPoint.data, allReward.data]);

  return (
    <>
      <div
        className="reward-point-background"
        // style={{ display: "grid", gridTemplateRows: "repeat(2,1fr)" }}
      >
        {/* <div className="reward-point-layout"> */}
        <div className="reward-point-container">
          {userPoint.loading && <p>{"loading"}</p>}
          {userPoint.error && <p>{"error"}</p>}
          {activeActivities && (
            <Box>
              {activeActivities && (
                <Box textAlign="center" style={{ margin: 20 }}>
                  <Typography variant="h5">กิจกรรมที่กำลังเข้าร่วม</Typography>
                </Box>
              )}
              <Grid container spacing={1} style={{ justifyContent: "center" }}>
                {activeActivities &&
                  activeActivities.map((el, i) => (
                    <Grid item xs={12} sm={4} md={3} lg={2} key={i}>
                      <Box
                        className="reward-point-card flip-in-ver-right "
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <RewardCardV2
                          cardColor={el.reward.cardColor}
                          activityName={el.reward.activityName}
                          pointTarget={el.reward.pointTarget}
                          progressPoint={el.points}
                          achieved={el.achieved}
                          expired={el.expired}
                          image={el.reward.image}
                          id={el._id}
                        />
                        {/* <RewardCard
                          cardColor={el.reward.cardColor}
                          activityName={el.reward.activityName}
                          pointTarget={el.reward.pointTarget}
                          progressPoint={el.points}
                          achieved={el.achieved}
                          expired={el.expired}
                          image={el.reward.image}
                          id={el._id}
                        /> */}
                      </Box>
                    </Grid>
                  ))}
              </Grid>
              <Divider style={{ margin: 32 }} />
            </Box>
          )}
          {completeAcitivities && (
            <Box>
              {completeAcitivities && (
                <Box textAlign="center" style={{ margin: 20 }}>
                  <Typography variant="h5">กิจกรรมที่ได้รับรางวัล</Typography>
                </Box>
              )}

              <Grid container spacing={1} style={{ justifyContent: "center" }}>
                {completeAcitivities &&
                  completeAcitivities.map((el, i) => (
                    <Grid item xs={12} sm={4} md={3} lg={2} key={i}>
                      <Box
                        className="reward-point-card flip-in-ver-right"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <RewardCardV2
                          cardColor={el.reward.cardColor}
                          activityName={el.reward.activityName}
                          pointTarget={el.reward.pointTarget}
                          progressPoint={el.points}
                          achieved={el.achieved}
                          expired={el.expired}
                          image={el.reward.image}
                          id={el._id}
                          rewardStatus={el.rewardStatus}
                          rewardStatusVisible={true}
                        />

                        {/* <RewardCard
                          cardColor={el.reward.cardColor}
                          activityName={el.reward.activityName}
                          pointTarget={el.reward.pointTarget}
                          progressPoint={el.points}
                          achieved={el.achieved}
                          expired={el.expired}
                          image={el.reward.image}
                          id={el._id}
                          rewardStatus={el.rewardStatus}
                          rewardStatusVisible={true}
                        /> */}
                      </Box>
                    </Grid>
                  ))}
              </Grid>

              <Divider style={{ margin: 32 }} />
            </Box>
          )}

          {expiredActivities && (
            <Box>
              {expiredActivities && (
                <Box textAlign="center" style={{ margin: 20 }}>
                  <Typography variant="h5">กิจกรรมที่หมดเวลาสะสม</Typography>
                </Box>
              )}

              <Grid container spacing={1} style={{ justifyContent: "center" }}>
                {expiredActivities &&
                  expiredActivities.map((el, i) => (
                    <Grid item xs={12} sm={4} md={3} lg={2} key={i}>
                      <Box
                        className="reward-point-card flip-in-ver-right"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <RewardCardV2
                          cardColor={el.reward.cardColor}
                          activityName={el.reward.activityName}
                          pointTarget={el.reward.pointTarget}
                          progressPoint={el.points}
                          achieved={el.achieved}
                          image={el.reward.image}
                          expired={true}
                          id={el._id}
                        />
                        {/* <RewardCard
                          cardColor={el.reward.cardColor}
                          activityName={el.reward.activityName}
                          pointTarget={el.reward.pointTarget}
                          progressPoint={el.points}
                          achieved={el.achieved}
                          image={el.reward.image}
                          expired={true}
                          id={el._id}
                        /> */}
                      </Box>
                    </Grid>
                  ))}
              </Grid>
              <Divider style={{ margin: 32 }} />
            </Box>
          )}

          {newActivities && (
            <Box>
              <Box textAlign="center" style={{ margin: 20 }}>
                <Typography variant="h5">กิจกรรมมาใหม่</Typography>
              </Box>
              <Grid container spacing={1} style={{ justifyContent: "center" }}>
                {newActivities &&
                  newActivities.map((el, i) => (
                    <Grid item xs={12} sm={4} md={3} lg={2} key={i}>
                      <Box
                        className="reward-point-card flip-in-ver-right"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <RewardCardV2
                          cardColor={el.cardColor}
                          activityName={el.activityName}
                          pointTarget={el.pointTarget}
                          image={el.image}
                          id={el._id}
                        />
                        {/* <RewardCard
                          cardColor={el.cardColor}
                          activityName={el.activityName}
                          pointTarget={el.pointTarget}
                          image={el.image}
                          id={el._id} */}
                        {/* /> */}
                      </Box>
                    </Grid>
                  ))}
              </Grid>
              <Divider style={{ margin: 32 }} />
            </Box>
          )}
        </div>
      </div>
      <AppFooter description="795 ถนน บรรทัดทอง แขวง วังใหม่ เขตปทุมวัน กรุงเทพมหานคร 10330" />
      {/* </div> */}
    </>
  );
}
