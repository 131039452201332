import React, { useState, Fragment, useContext } from 'react'
import clsx from 'clsx'
import 'antd/dist/antd.css'
import moment from 'moment'
import {
  Container,
  Grid,
  makeStyles,
  TextField,
  Typography,
  Button,
  // IconButton,
  MenuItem,
} from '@material-ui/core'
import {
  Upload,
  notification,
} from 'antd'
import { gql, useMutation } from '@apollo/client'
import firebase from 'firebase/app'
import {
  useHistory, withRouter,
} from 'react-router-dom'
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';

import AppFooter from '../components/Footer'
import AuthContext from '../context/AuthContext'
import client from '../index'

const useStyles = makeStyles((theme) => ({
  root: { 
  },
  input: {
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: '#357B77',
      borderWidth: '2px',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#357B77',
    },
  },
  borderinput: {
    borderRadius: '5px',
    backgroundColor: '#fafafa',
  },
  margin: {
    margin: theme.spacing(0.5),
    width:'100%'
  },
}))
const level = [
  {
    value: 'every',
    label: 'จัดทุกสัปดาห์',
  },
  {
    value: 'twice',
    label: 'จัดเดือนละ 2 ครั้ง (จัดวันเสาร์แรกและเสาร์สามของเดือน)',
  },
  {
    value: 'onec',
    label: 'จัดเดือนละ 1 ครั้ง (จัดวันเสาร์แรก)',
  }
]

const CREATE_EVENT = gql`
  mutation createEvent($record: CreateOneeventsInput!) {
    eventCreateOne(record: $record) {
      record {
        name
      }
    }
  }
`
const GET_EVENT = gql`
query($name : String){
    eventOne(filter:{
      name: $name
    }){
      _id
      name
      eventRoute
      image
    }
  }
`
const CreateEventPage = ({ className, ...rest }) => {
  const [createEvent] = useMutation(CREATE_EVENT)
  const [province, setProvince] = useState('')
  const [name, setName] = useState('')
  const [location, setLocation] = useState('')
  const [address, setAddress] = useState('')
  const [route, setRoute] = useState('')
  const [road, setRoad] = useState('')
  const [organizName, setOrganizName] = useState('')
  const [startPoint, setStartPoint] = useState('')
  const [finishPoint, setFinishPoint] = useState('')
  const [organizEmail, setOrganizEmail] = useState('')
  const [description, setDescription] = useState('')
  const [levels, setLevels] = useState('every')
  const [organizPhone, setOrganizPhone] = useState('')
  const [imageDownloadUrl, setImageDownloadUrl] = useState('')
  const [bannerDownloadUrl, setBannerDownloadUrl] = useState('')
  const [eventRouteDownloadUrl, setEventRouteDownloadUrl] = useState('')
  const [loading, setLoading] = useState(false)
  const { userInfo } = useContext(AuthContext)

  const history = useHistory()
  const classes = useStyles()
  const handleSubmit = async (e) => {
    e.preventDefault()
    const record = {
      name,
      organizId: userInfo._id,
      province,
      organizEmail,
      organizName,
      organizPhone,
      address,
      road,
      levels,
      slug : name+1,
      route,
      location,
      startPoint,
      finishPoint,
      description,
      image: imageDownloadUrl,
      banner: bannerDownloadUrl,
      eventRoute: eventRouteDownloadUrl,
    }
    try {
      setLoading(true)
      if( name ){
        const { data } = await client.query({ query: GET_EVENT, variables: { name }, fetchPolicy: 'network-only' })
        if (data.eventOne) {
          notification.error({
          message: `มีชื่อสนามนี้อยู่แล้วในระบบ`,
          duration: 5,
          placement: 'bottomRight'
        })
          return
        }
      }
      if(checkId(address)) {
        const create = await createEvent({
          variables: { record },
        })
        if (create) {
          setLoading(false)
          await notification.success({
            message: `ส่งคำร้องขอสำเร็จ`,
            duration: 5,
            placement: 'bottomRight'
          })
          await history.push('/')
        } else {
          console.log('err')
        }
      } else{
        return
      }
    }catch (err) {
      console.error(err)
    }
  }
  const getBase64 = (file, cb) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      cb(reader.result)
    }
    reader.onerror = function (error) {
      console.log('Error: ', error)
    }
  }

  const checkId = () => {
    // const address = e.target.value
    const pattern = /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/
    if(pattern.test(address)){
      return true
    }else{
      notification.error({
        message: 'รูปแบบพิกัด GPS ไม่ถูกต้อง',
        duration: 5,
        placement: 'bottomRight',
      })
      return false
    }
  }

  const beforeUpload = (file) => {
    const isImage = file.type.indexOf('image/') === 0
    if (!isImage) {
      // console.log('You can only upload image file!');
      notification.error({
        message: 'กรุณาอัปโหลดรูปสนามวิ่ง',
        duration: 5,
        placement: 'bottomRight',
      })
    }
  }

    const handleChange = (info) => {
      if (info.file.status === 'uploading') {
        setLoading(true)
        return
      }
      if (info.file.status === 'done') {
        getBase64(info.file.originFileObj, (imageUrl) => {
          // setImageUrl(imageUrl)
          console.log(loading)
          setLoading(false)
        },
  
        )
      }
    }

    const customUpload = async ({ onError, onSuccess, file }) => {
      const storages = firebase.storage()
      const metadata = {
        contentType: 'image/jpeg',
      }
      const storageRef = await storages.ref()
      const imageName = `parkrun${moment()}` // a unique name for the image
      const imgFile = storageRef.child(`Event/${imageName}.png`)
      try {
        const image = await imgFile.put(file, metadata)
        // console.log(image);
        const url = await imgFile.getDownloadURL()
        setImageDownloadUrl(url)
        onSuccess(null, image)
      } catch (e) {
        onError(e)
      }
    }

    const customUpload2 = async ({ onError, onSuccess, file }) => {
        const storages = firebase.storage()
        const metadata = {
          contentType: 'image/jpeg',
        }
        const storageRef = await storages.ref()
        const imageName = `banner${moment()}` // a unique name for the image
        const imgFile = storageRef.child(`Event/${imageName}.png`)
        try {
          const image = await imgFile.put(file, metadata)
          // console.log(image);
          const url = await imgFile.getDownloadURL()
          setBannerDownloadUrl(url)
          onSuccess(null, image)
        } catch (e) {
          onError(e)
        }
      }
  
    const customUpload3 = async ({ onError, onSuccess, file }) => {
      const storages = firebase.storage()
      const metadata = {
        contentType: 'image/jpeg',
      }
      const storageRef = await storages.ref()
      const imageName = `eventRoute${moment()}` // a unique name for the image
      const imgFile = storageRef.child(`Event/${imageName}.png`)
      try {
        const image = await imgFile.put(file, metadata)
        // console.log(image);
        const url = await imgFile.getDownloadURL()
        setEventRouteDownloadUrl(url)
        onSuccess(null, image)
      } catch (e) {
        onError(e)
      }
    }


    return (
      <Fragment>
        <Container maxWidth="lg">
          <Grid container justify="center" className={clsx(classes.root, className)} {...rest}>
            <Grid item container justify="center" style={{marginTop:'100px'}}>
              <Typography variant="h6">กรอกข้อมูลสนามวิ่ง</Typography>
            </Grid>
            <form  onSubmit={(e)=>handleSubmit(e)}>
            <Grid item container spacing={2} md={8} >
              <Typography>ชื่อสนาม</Typography>
              <Grid item xs={12}>
                <TextField
                  required
                  variant="outlined"
                  label="ชื่อสนาม"
                  id="name"
                  onChange={(e) => setName(e.target.value)}
                  fullWidth
                  autoFocus
                  className={classes.input}
                  InputProps={{
                    className: classes.borderinput,
                  }}
                  InputLabelProps={{
                    style: { color: '#2E7672' },
                  }}
                />
              </Grid>
              <Typography>ที่อยู่สนาม</Typography>
              <Grid item xs={12}>
                <TextField
                  required
                  variant="outlined"
                  label="ที่อยู่"
                  id="location"
                  onChange={(e) => setLocation(e.target.value)}
                  fullWidth
                  autoFocus
                  className={classes.input}
                  InputProps={{
                    className: classes.borderinput,
                  }}
                  InputLabelProps={{
                    style: { color: '#2E7672' },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="จังหวัด"
                  id="province"
                  onChange={(e) => setProvince(e.target.value)}
                  fullWidth
                  autoFocus
                  className={classes.input}
                  InputProps={{
                    className: classes.borderinput,
                  }}
                  InputLabelProps={{
                    style: { color: '#2E7672' },
                  }}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="พิกัด GPS สนาม"
                  id="address"
                  onChange={(e) => setAddress(e.target.value)}
                  fullWidth
                  className={classes.input}
                  InputProps={{
                    className: classes.borderinput,
                  }}
                  InputLabelProps={{
                    style: { color: '#2E7672' },
                  }}
                  required
                />
                <Typography variant="subtitle2" align="center" style={{ padding: '15px 0px' }}>พิกัด GPS ผ่านเว็บไซต์ Google Maps ของสถานที่เสนอของสวนสาธารณะ(หรือสถานที่จัดงาน)<p>ตัวอย่างพิกัด 13.7469783,100.5220205</p><b style={{color : 'red'}}>*กรุณาใส่พิกัดเป็นตัวเลขละติจูด, ลองติจูด เท่านั้น ห้ามใส่ Link Google Maps เด็ดขาด</b></Typography>
              </Grid>
              <Typography>ผู้ประสานงาน</Typography>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="ชื่อ นามสกุล"
                  id="organizName"
                  onChange={(e) => setOrganizName(e.target.value)}
                  fullWidth
                  className={classes.input}
                  InputProps={{
                    className: classes.borderinput,
                  }}
                  InputLabelProps={{
                    style: { color: '#2E7672' },
                  }}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="อีเมล"
                  id="organizEmail"
                  onChange={(e) => setOrganizEmail(e.target.value)}
                  fullWidth
                  className={classes.input}
                  InputProps={{
                    className: classes.borderinput,
                  }}
                  InputLabelProps={{
                    style: { color: '#2E7672' },
                  }}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="เบอร์โทรศัพท์"
                  id="organizPhone"
                  onChange={(e) => setOrganizPhone(e.target.value)}
                  fullWidth
                  className={classes.input}
                  InputProps={{
                    className: classes.borderinput,
                  }}
                  InputLabelProps={{
                    style: { color: '#2E7672' },
                  }}
                  required
                />
                <Typography variant="subtitle2" align="center" style={{ padding: '15px 0px' }}>เราอาจต้องการพูดคุยกับคุณ หากคุณสนใจรายละเอียดเกี่ยวกับ Paek Run โปรดระบุข้อมูลของคุณ</Typography>
              </Grid>
              <Typography>เกี่ยวกับสนาม Park Run ของคุณ</Typography>
              <Grid item xs={12}>
                <TextField
                  rows={2}
                  multiline
                  variant="outlined"
                  label="แนะนำสนามวิ่ง"
                  id="description"
                  onChange={(e) => setDescription(e.target.value)}
                  fullWidth
                  className={classes.input}
                  InputProps={{
                    className: classes.borderinput,
                  }}
                  InputLabelProps={{
                    style: { color: '#2E7672' },
                  }}
                  required
                />
                <Typography variant="subtitle2" align="center" style={{ paddingTop: '5px' }}>อธิบายถึงความประทับใจหรือความน่าสนใจของสนามวิ่งที่คุณเสนอ (อธิบายพอสังเขป)</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  multiline
                  variant="outlined"
                  label="การเดินทางไปจุดนัดพบ"
                  id="route"
                  onChange={(e) => setRoute(e.target.value)}
                  fullWidth
                  className={classes.input}
                  InputProps={{
                    className: classes.borderinput,
                  }}
                  InputLabelProps={{
                    style: { color: '#2E7672' },
                  }}
                  required
                />
                <Typography variant="subtitle2" align="center" style={{ paddingTop: '5px' }}>อธิบายรายละเอียดการเดินทางไปยังสนาม (อธิบายพอสังเขป)</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  multiline
                  variant="outlined"
                  label="รายละเอียดแผนที่วิ่ง"
                  id="path"
                  onChange={(e) => setRoad(e.target.value)}
                  fullWidth
                  className={classes.input}
                  InputProps={{
                    className: classes.borderinput,
                  }}
                  InputLabelProps={{
                    style: { color: '#2E7672' },
                  }}
                  required
                />
                <Typography variant="subtitle2" align="center" style={{ padding: '5px 0px' }}>อธิบายรายละเอียดเส้นทางวิ่งของสนาม (อธิบายพอสังเขป)</Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  label="จุดนัดพบ"
                  id="startPoint"
                  onChange={(e) => setStartPoint(e.target.value)}
                  fullWidth
                  className={classes.input}
                  InputProps={{
                    className: classes.borderinput,
                  }}
                  InputLabelProps={{
                    style: { color: '#2E7672' },
                  }}
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  label="เส้นชัย"
                  id="finishPoint"
                  onChange={(e) => setFinishPoint(e.target.value)}
                  fullWidth
                  className={classes.input}
                  InputProps={{
                    className: classes.borderinput,
                  }}
                  InputLabelProps={{
                    style: { color: '#2E7672' },
                  }}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="level"
                  select
                  fullWidth
                  value={levels}
                  label="จัดวันวิ่ง"
                  onChange={(e) => setLevels(e.target.value)}
                  variant="outlined"
                  className={classes.input}
                  InputProps={{
                    className: classes.borderinput,
                  }}
                  InputLabelProps={{
                    style: { color: '#2E7672' },
                  }}
                  required
                >
                  {level.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <Typography variant="subtitle2" align="center" style={{ padding: '5px 0px' }}>เมื่อสร้างสนามสำเร็จจะไม่มีการเปลี่ยนแปลงวันจัดวิ่งระบบจะสร้างงานวิ่งอัตโนมัติ</Typography>
              </Grid>
              <Typography>อัพโหลดรูปสนามวิ่ง</Typography>
              <Grid item xs={12}>
                <Typography variant="body2" display="flex" >อัพโหลดรูปหน้าปกสนามวิ่ง ขนาด 300x200 px </Typography>
                <Upload beforeUpload={beforeUpload} onChange={handleChange} customRequest={customUpload}  listType="picture">
                  <Button startIcon={<AddAPhotoIcon  />}  color="primary">
                    อัพโหลดรูป
                  </Button>
                </Upload>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">อัพโหลดรูปเพจสนามวิ่ง ขนาด 1300x400 px</Typography>
                <Upload beforeUpload={beforeUpload} onChange={handleChange} customRequest={customUpload2}  listType="picture">
                  <Button startIcon={<AddAPhotoIcon  />}  color="primary">
                    อัพโหลดรูป
                  </Button>
                </Upload>
              </Grid>
              <Grid item xs={12}>
              <Typography variant="body2">อัพโหลดรูปแผนที่วิ่งของสนาม ขนาด 750x300 px</Typography>
                <Upload beforeUpload={beforeUpload} onChange={handleChange} customRequest={customUpload3}  listType="picture">
                  <Button startIcon={<AddAPhotoIcon  />}  color="primary">
                    อัพโหลดรูป
                  </Button>
                </Upload>
              </Grid>
            
              <Grid item xs={6} md={6} >
                <div style={{display: 'flex',justifyContent: 'center'}}>
                  <Button
                    color="primary"
                    type="submit"
                    align="center" 
                    variant="contained"
                    className={classes.margin}
                  >
                    ขออนุมัติสร้างงานวิ่ง
                  </Button>
                </div>
              </Grid>  
                <Grid item xs={6} md={6}>
                  <div style={{display: 'flex',justifyContent: 'center'}}>
                    <Button
                      align="center" 
                      variant="contained"
                      className={classes.margin}
                    >
                    ยกเลิก
                    </Button>
                  </div>
                </Grid>
            </Grid>
              </form>
          </Grid>
          <div style={{marginTop:'20px' ,width:'100%'}}>
          <AppFooter description="795 ถนน บรรทัดทอง แขวง วังใหม่ เขตปทุมวัน กรุงเทพมหานคร 10330" />
        </div>
        </Container>
      </Fragment>
    )
  }
  
  export default withRouter(CreateEventPage)
  