import React, { useEffect, useState } from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";
import HomeIcon from "@material-ui/icons/Home";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { withStyles } from "@material-ui/core/styles";

export default function RewardTimelineV2({ status }) {
  const [timelineStatus, setTimelineStatus] = useState(0);

  useEffect(() => {
    if (!status) return;

    switch (status) {
      case "ยืนยันข้อมูล":
        setTimelineStatus(0);
        break;
      case "เตรียมรางวัล":
        setTimelineStatus(1);
        break;
      case "สำเร็จ":
        setTimelineStatus(2);
        break;
      default:
        return;
    }
  }, [status]);

  function ColorlibStepIcon(props) {
    const { active, completed, icon } = props;

    const classes = {
      root: {
        backgroundColor: "#ccc",
        zIndex: 1,
        color: "#fff",
        width: 50,
        height: 50,
        display: "flex",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
      },
      active: {
        backgroundImage:
          "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
        boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
      },
      completed: {
        backgroundImage:
          "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
      },
    };

    const icons = {
      1: <HomeIcon />,
      2: <CardGiftcardIcon />,
      3: <CheckCircleIcon />,
    };

    return (
      <div
        style={{
          ...classes.root,
          ...classes[active ? "active" : completed ? "active" : ""],
        }}
      >
        {icons[`${icon}`]}
      </div>
    );
  }

  const ColorlibConnector = withStyles({
    alternativeLabel: {
      top: 22,
    },
    active: {
      "& $line": {
        backgroundImage:
          "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
      },
    },
    completed: {
      "& $line": {
        backgroundImage:
          "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
      },
    },
    line: {
      height: 3,
      border: 0,
      backgroundColor: "#eaeaf0",
      borderRadius: 1,
    },
  })(StepConnector);

  return (
    <div>
      <Stepper
        alternativeLabel
        style={{
          top: 10,
          left: "calc(-50% + 16px)",
          right: "calc(50% + 16px)",
        }}
        activeStep={timelineStatus}
        connector={<ColorlibConnector />}
      >
        <Step key={1}>
          <StepLabel StepIconComponent={ColorlibStepIcon}>
            {"ยืนยันข้อมูล"}
          </StepLabel>
        </Step>
        <Step key={2}>
          <StepLabel StepIconComponent={ColorlibStepIcon}>
            {"เตรียมรางวัล"}
          </StepLabel>
        </Step>
        <Step key={3}>
          <StepLabel StepIconComponent={ColorlibStepIcon}>{"สำเร็จ"}</StepLabel>
        </Step>
        {/* ))} */}
      </Stepper>
    </div>
  );
}
