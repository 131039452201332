import React, {
  Fragment, useContext, useState, useMemo,
} from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useHistory } from 'react-router-dom'
// import PropTypes from 'prop-types';
import firebase from 'firebase/app'
import 'firebase/storage'
import moment from 'moment'
import clsx from 'clsx'

import _get from 'lodash/get'

// import getEvent from '../../graphql/queries/getAllEvent'
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Grid,
  Container,
  TextField,
  CardHeader,
} from '@material-ui/core'

import {
  Upload,
  notification,
} from 'antd'
import EmailIcon from '@material-ui/icons/Email'
import PhoneIcon from '@material-ui/icons/Phone'
import AddIcCallIcon from '@material-ui/icons/AddIcCall'
import FavoriteIcon from '@material-ui/icons/Favorite'
import CakeIcon from '@material-ui/icons/Cake'
import WcIcon from '@material-ui/icons/Wc'

import UpdateUser from '../../graphql/mutations/updateUser'
import AuthContext from '../../context/AuthContext'

import getUser from '../../graphql/queries/getUserOne'

import AppFooter from '../../components/Footer'

import provinces from './provinces.json'

const Gender = [
  {
    value: 'male',
    label: 'ชาย',
  },
  {
    value: 'female',
    label: 'หญิง',
  },
]

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 1,
    height: '550px',
    '@media (max-width: 768px)': {
      height: 'auto'
    }
  },
  formedit: {
    flexGrow: 1,
    padding: 1,
    sidenav: {
      alignItems: 'center',
      // marginTop: '100px',
      display: 'flex',
      flexDirection: 'column',
      width:'100%'
    },
  },
  avatar: {
    height: 150,
    width: 150,
  },
  icon: {
    marginRight: theme.spacing(0.5),
  },
  testB: {
    display: 'flex',
    paddingBottom:'5px'
  },
  divider:{
    marginTop:'100px',
    '@media (max-width: 768px)': {
      marginTop: 'auto'
    }
  }
}))

const EditProfilePage = ({ className, ...rest }) => {
  // const [image,setImage] = useState('')
  const history = useHistory()
  const [updateUser] = useMutation(UpdateUser)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [gender, setGender] = useState('')
  const [province, setProvince] = useState('')
  const [idcard, setIdcard] = useState('')
  const [drug, setDrug] = useState('')
  const [birthDate, setBirthDate] = useState('')
  const [emergenPhone, setEmergenPhone] = useState('')
  const { userInfo } = useContext(AuthContext)
  // const [imageUrl, setImageUrl] = useState('')
  const [imageDownloadUrl, setImageDownloadUrl] = useState('')
  const [loading, setLoading] = useState(false)
  const { data = { userOne: {} } } = useQuery(getUser, {
    variables: {
      _id: `${userInfo._id}`,
    },
    fetchPolicy: 'cache-and-network',
  })
  // console.log(data)
  const classes = useStyles()
  const handleSubmit = async (e) => {
    try {
      // setImage(imageDownloadUrl)
      const data = await updateUser({
        variables: {
          record: {
            _id: userInfo._id,
            name,
            email,
            phone,
            gender,
            province,
            idcard,
            drug,
            birthDate: new Date(birthDate),
            emergenPhone,
            image: imageDownloadUrl,
          },
        },
      })
      if (data) {
        notification.success({
          message: 'แก้ไขสำเร็จ',
          duration: 5,
          placement: 'bottomRight',
        })
        history.push('/profile')
      }
    } catch (err) {
      notification.error({
        message: `${err}`,
        duration: 5,
        placement: 'bottomRight',
      })
    }
  }

  useMemo(() => {
    if (data.userOne) {
      setEmail(data.userOne.email)
      setIdcard(data.userOne.idcard)
      setName(data.userOne.name)
      setProvince(data.userOne.province)
      setBirthDate(data.userOne.birthDate)
      if (!data.userOne.gender) {
        setGender('male')
      } else {
        setGender(data.userOne.gender)
      }
      setDrug(data.userOne.drug)
      setPhone(data.userOne.Phone)
      setEmergenPhone(data.userOne.emergenPhone)
      setImageDownloadUrl(data.userOne.image)
    }
  }, [data.userOne])

  const getBase64 = (file, cb) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      cb(reader.result)
    }
    reader.onerror = function (error) {
      console.log('Error: ', error)
    }
  }

  const beforeUpload = (file) => {
    const isImage = file.type.indexOf('image/') === 0
    if (!isImage) {
      // console.log('You can only upload image file!');
      notification.error({
        message: 'You can only upload image file!',
        duration: 5,
        placement: 'bottomRight',
      })
    }

    // You can remove this validation if you want
    const isLt5M = file.size / 1024 / 1024 < 5
    if (!isLt5M) {
      // console.log('Image must smaller than 5MB!');
      notification.error({
        message: 'Image must smaller than 5MB!',
        duration: 5,
        placement: 'bottomRight',
      })
    }
    return isImage && isLt5M
  }
  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true)
      return
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, (imageUrl) => {
        // setImageUrl(imageUrl)
        console.log(loading)
        setLoading(false)
      },

      )
    }
  }

  const customUpload = async ({ onError, onSuccess, file }) => {
    const storages = firebase.storage()
    const metadata = {
      contentType: 'image/jpeg',
    }
    const storageRef = await storages.ref()
    const imageName = `parkrun${moment()}` // a unique name for the image
    const imgFile = storageRef.child(`Vince Wear/${imageName}.png`)
    try {
      const image = await imgFile.put(file, metadata)
      // console.log(image);
      const url = await imgFile.getDownloadURL()
      setImageDownloadUrl(url)
      onSuccess(null, image)
    } catch (e) {
      onError(e)
    }
  }

  // const handleChange = (event) => {
  //   setValues({
  //     ...values,
  //     [event.target.name]: event.target.value
  //   });
  // };

  // if(loading){
  //   return <p>loading</p>
  // }

  return (
    <Fragment>
      <Container maxWidth="lg">
        <Grid container spacing={2} style={{marginTop: '100px'}}>
          <Grid item lg={4} md={4} xs={12} className={classes.sidenav}>
            <Card className={clsx(classes.root, className)} {...rest}>
              <CardContent>
                <Box alignItems="center" display="flex" flexDirection="column">
                  <Avatar className={classes.avatar} src={_get(data.userOne, 'image')} />
                  <Typography color="textPrimary" gutterBottom variant="h5">
                    {data.userOne.name}
                  </Typography>
                  <div style={{alignItems:'end'}}>
                    <Typography variant="subtitle2" color="textSecondary" className={classes.testB}>
                      <EmailIcon fontSize="small" className={classes.icon} /> {data.userOne.email}
                    </Typography>
                    <Typography variant="subtitle2" color="textSecondary" className={classes.testB}>
                      <PhoneIcon fontSize="small" className={classes.icon} /> {data.userOne.phone}
                    </Typography>
                    <Typography variant="subtitle2" color="textSecondary" className={classes.testB}>
                      <AddIcCallIcon fontSize="small" className={classes.icon} />ฉุกเฉิน: {data.userOne.emergenPhone}
                    </Typography>
                    <Typography variant="subtitle2" color="textSecondary" className={classes.testB}>
                      <FavoriteIcon fontSize="small" className={classes.icon} />แพ้ยา: {data.userOne.drug}
                    </Typography>
                    <Typography variant="subtitle2" color="textSecondary" className={classes.testB}>
                      <WcIcon fontSize="small" className={classes.icon} />เพศ: {_get(data.userOne, 'gender') && _get(data.userOne, 'gender') === 'male' ? 'ชาย' : 'หญิง'}
                    </Typography>
                    <Typography variant="subtitle2" color="textSecondary" className={classes.testB}>
                      <CakeIcon fontSize="small" className={classes.icon} />อายุ: {_get(data.userOne, 'birthDate') && moment(new Date()).diff(moment(data.userOne.birthDate), 'years')} ปี
                    </Typography>
                  </div>
                </Box>
              </CardContent>
              <Divider className={classes.divider}/>
              <Box
                display="flex"
                justifyContent="center"
                p={1}
              >
                <CardActions>
                  <Upload
                    beforeUpload={beforeUpload}
                    onChange={handleChange}
                    customRequest={customUpload}
                  >
                    <Button color="primary" variant="text">
                      อัพเดตรูปโปรไฟล์
                    </Button>
                  </Upload>
                </CardActions>
              </Box>
            </Card>
          </Grid>
          <Grid item lg={8} md={8} xs={12}>
          <Card className={clsx(classes.root, className)} {...rest}>
            <form autoComplete="off" noValidate className={classes.formedit}>
              <CardHeader title="แก้ไขโปรไฟล์" />
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="ชื่อ"
                        name="name"
                        onChange={(e) => setName(e.target.value)}
                        required
                        variant="outlined"
                        inputProps={{
                          defaultValue: data.userOne.name ? `${data.userOne.name}` : '',
                        }}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="รหัสประจำตัวประชาชน"
                        name="idcard"
                        onChange={(e) => setIdcard(e.target.value)}
                        variant="outlined"
                        inputProps={{
                          defaultValue: data.userOne.idcard ? `${data.userOne.idcard}` : '',
                        }}
                        disabled={data.userOne.idcard}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="อีเมล"
                        name="email"
                        required
                        onChange={(e) => setEmail(e.target.value)}
                        inputProps={{
                          defaultValue: data.userOne.email ? `${data.userOne.email}` : '',
                        }}
                        variant="outlined"
                        disabled
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="เบอร์โทร"
                        name="phone"
                        type="number"
                        onChange={(e) => setPhone(e.target.value)}
                        variant="outlined"
                        inputProps={{
                          defaultValue: data.userOne.phone ? `${data.userOne.phone}` : '',
                        }}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        id="date"
                        label="วันเกิด"
                        type="date"
                        variant="outlined"
                        defaultValue={data.userOne.birthDate ? `${moment(data.userOne.birthDate).format('yyyy-MM-DD')}` : `${moment().format('yyyy-MM-DD')}`}
                        onChange={(e) => setBirthDate(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      {/* </form> */}
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="เพศ"
                        name="Gender"
                        required
                        select
                        SelectProps={{ native: true }}
                        onChange={(e) => setGender(e.target.value)}
                        variant="outlined"
                        inputProps={{
                          defaultValue: data.userOne.gender ? `${data.userOne.gender}` : 'male',
                        }}
                      >
                        {Gender.map((option) => (
                          <option
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    {/* <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="ที่อยู่"
                          name="country"
                          // onChange={handleChange}
                          required
                          // value={values.country}
                          variant="outlined"
                        />
                      </Grid> */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="จังหวัด"
                        name="province"
                        required
                        select
                        SelectProps={{ native: true }}
                        onChange={(e) => setProvince(e.target.value)}
                        variant="outlined"
                        inputProps={{
                          defaultValue: data.userOne.province ? `${data.userOne.province}` : '',
                        }}
                      >
                        {provinces.map((option) => (
                          <option
                            key={option.name.th}
                            value={option.name.th}
                          >
                            {option.name.th}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="เบอร์โทรฉุกเฉิน"
                        name="emergency"
                        type="emergency"
                        onChange={(e) => setEmergenPhone(e.target.value)}
                        variant="outlined"
                        inputProps={{
                          defaultValue: data.userOne.emergenPhone ? `${data.userOne.emergenPhone}` : '',
                        }}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="ประวัติการแพ้ยา"
                        name="allergy"
                        type="allergy"
                        onChange={(e) => setDrug(e.target.value)}
                        variant="outlined"
                        inputProps={{
                          defaultValue: data.userOne.drug ? `${data.userOne.drug}` : '',
                        }}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Button color="primary" variant="contained" style={{ backgroundColor: '#41AEA9', color: '#fff', width:'100px' }} onClick={handleSubmit}>
                    บันทึก
                  </Button>
                </Box>
              </form>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <div style={{ marginTop: '10px' }}>
          <AppFooter description="795 ถนน บรรทัดทอง แขวง วังใหม่ เขตปทุมวัน กรุงเทพมหานคร 10330" />
        </div>
    </Fragment>
  )
}

export default EditProfilePage
