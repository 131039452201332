import React from 'react';
import { useParams } from 'react-router-dom'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

import{ Row, Col, Table, Space, Spin
  // Input, Select, Progress, Icon, Card, 
} from 'antd';
import {
  Link
} from 'react-router-dom'

import FusionCharts from 'fusioncharts'
import Charts from 'fusioncharts/fusioncharts.charts'
import ReactFC from 'react-fusioncharts'
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion'
// import RenderChart from './renderChart'

import _groupBy from 'lodash/groupBy'
import moment from 'moment'
import _get from 'lodash/get'
import _unionBy from 'lodash/unionBy'
import _reverse from 'lodash/reverse'
import _sortBy from 'lodash/sortBy'
import _last from 'lodash/last'
import _orderBy from 'lodash/orderBy'

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme)

const GET_EVENT_AND_CHECKPOINT = gql`
  query getEventAndCheckpoint($eventId: MongoID!){
    raceById(_id: $eventId) {
      _id
      name
      slug
      organizId
      startTime
      endTime
      haveChipTime
      checkpoints {
        distance
        position
        cutOffTime
      }
    }
    checkpointByEventId(eventId: $eventId) {
      position
      time
      slug
      userId
      _id
      runnerId
      user{
          name
          bib
      }
    	
    }
   
  }
`
// const GET_VIRTUAL = gql`
//   query{
//     checkpointMany(filter:{
//       deleted: false,
//       isVirtual: true
//     }) {
//       _id
//       runnerId
//       user{
//           name
//           bib
//       }
//       startTime
//       endTime
//       location
    	
//     }
//   }
// `

function calcTime(before, current){
  const curTime = moment(current)
  const beforeTime = moment(before)
  const diff = curTime.diff(beforeTime)
  const duration = moment.duration(diff)
  return moment.utc(duration.asMilliseconds()).format('HH:mm:ss')
}

function calcPace(time, distance) {
  const minutes = (time.hour()*60) + time.minute() + (time.second() / 60)
  const paceResult = minutes / distance
  const paceTime = moment.utc().startOf('day').add({ minutes: paceResult }).format(paceResult>=60?'HH:mm:ss':'mm:ss')
  return paceTime
}

function buildData({ startTime, haveChipTime, checkpoints }, checkpointsData) {
  const results = []
  // const times = []
  for (const [key, value] of Object.entries(checkpointsData)) {
    if (key !== 'null') {
      const result = {
        bib: key
      }
      const checkpointsInfo = checkpoints.map(cp => cp)
      _reverse(value)
      const data = _unionBy(value, ({ position }) => position)
      const sortedData = _sortBy(data, e => e.position)
      let defaultTime = startTime
      
      if (haveChipTime) {
        const startPosition = sortedData.shift()
        checkpointsInfo.shift()
        if (startPosition.position === 1) {
          defaultTime = startPosition.time
        }
      }

      sortedData.forEach(data => {
        const splitTime = calcTime(defaultTime, data.time)
        result[data.position] = splitTime
        result.name = _get(data, 'user.name', '-')
      })

      checkpointsInfo.forEach(({ position, distance }, index) => {  // รวมเวลาทั้งหมดไว้ใน array
        if (!result[position]) {
          result.gunTime = 'DNF'
          result.chipTime = 'DNF'
          result.avgPace = '-'
          return
        }
        if (distance === 0) {
          result[`pace${position}`] = '-'
          return
        }
        const time = moment(result[position], 'HH:mm:ss')
        const paceTime = calcPace(time, distance) //moment.utc().startOf('day').add({ minutes: paceResult }).format('H:mm:ss')
        result[`pace${position}`] = paceTime
      })
      if (result.gunTime !== 'DNF') {  // คำนวนเวลาทั้งหมด
        const { position, distance } = _last(checkpointsInfo)
        const lastData = sortedData.find(d => d.position === position)
        // const totalTime = times.slice(1).reduce((prev, cur) => moment.duration(cur).add(prev), moment.duration(times[0]))
        // const gunTime = moment.utc(totalTime.asMilliseconds()).format("HH:mm:ss")
        const chipTime = calcTime(defaultTime, lastData.time)
        result.avgPace = calcPace(moment(chipTime, 'HH:mm:ss'), distance)
        result.gunTime = calcTime(startTime, lastData.time)
        result.chipTime = chipTime //calcTime(defaultTime, lastData.time)
      } 

      results.push(result)
    }
  }

  return results
}

function sortRank(data) {
  const dnfData = data.filter(({ gunTime }) => gunTime === 'DNF')
  const finishData = data.filter(({ gunTime }) => gunTime !== 'DNF')
  const orderedData = _orderBy(finishData, o => moment(o.gunTime, 'HH:mm:ss'), ['asc'])
  const orderedDataWithPos = orderedData.map((data, index) => ({...data, pos: index+1}))
  // console.log('orderedDataWithPos', orderedDataWithPos)
  return orderedDataWithPos.concat(dnfData)
}

const Result = () => {
  const { eventId, date } = useParams()
  const { data, loading } = useQuery(GET_EVENT_AND_CHECKPOINT, 
    { variables: { eventId },
    fetchPolicy: 'network-only'})
  
  // const { data: data2, loading: loading2 } = useQuery(GET_VIRTUAL, 
  //   { variables: { eventId },
  //   fetchPolicy: 'network-only'})
  //   console.log(data2)
  if (loading) return (
    <div style={{display:'flex', justifyContent:'center', alignItems:'center',height:'100vh' }}>
      <Spin tip="Loading...results"/>
    </div>
  )
  const { raceById: event , checkpointByEventId: checkpoints } = data
  // const { checkpointMany: vrCheckpoints } = data2
  const checkpointsInfo = event.checkpoints.map(cp => cp)
  checkpointsInfo.pop()
  if (event.haveChipTime) {
    checkpointsInfo.shift()
  }

  let dateList = []
  const firstDate = moment('09/01/2021')
  dateList.push(firstDate)
  while (moment(dateList[dateList.length - 1]).add(7, 'days').isBefore(moment())) {
    dateList.push(moment(dateList[dateList.length - 1]).add(7, 'days'))
  }

  // const filterCr = date ? vrCheckpoints.filter((item)=> moment(item.startTime).isSame(dateList[date], 'days')) : vrCheckpoints


  
  const columnsCheckpoint = checkpointsInfo.map((cp, index) => ({
    title: `เช็คพอยท์ ${index+1} (${cp.distance})`,
    dataIndex: cp.position,
    key: cp.position,
    render: (time, record) => {
      if (!time) {
        return '-'
      }
      const place = record[`pace${cp.position}`]
      return `${time} \n (${place})`
    }
  }))

  const columns = [
    {
      title: 'ลำดับ',
      dataIndex: 'pos',
      key: '_id',
      render: pos => pos || '-'
    },
    {
      title: 'เลขบิบ',
      dataIndex: 'bib',
      key: 'bib',
      render: bib =>
        <Link to={`/parkrunner/${bib}`}>
          {bib}
        </Link>
          
    },
    {
      title: 'ชื่อ-สกุล',
      dataIndex: 'name',
      key: 'name'
    },
    ...columnsCheckpoint,
    {
      title: 'Chip Time',
      dataIndex: 'chipTime',
      key: 'chipTime'
    },
    {
      title: 'Gun Time',
      dataIndex: 'gunTime',
      key: 'gunTime'
    },
    {
      title: 'AVG Pace',
      dataIndex: 'avgPace',
      key: 'avgPace'
    },
  ]

  // const columns2 = [
  //   {
  //     title: 'เลขบิบ',
  //     dataIndex: 'user',
  //     key: 'bib',
  //     render: user => <div>{`${user.bib}`}</div>
  //   },
  //   {
  //     title: 'ชื่อ-สกุล',
  //     dataIndex: 'user',
  //     key: 'name',
  //     render: user => <div>{`${user.name}`}</div>
  //   },
  //   {
  //     title: 'เวลา',
  //     dataIndex: 'startTime',
  //     key: 'time',
  //     render: (startTime, record) => <div>{`${calcTime(record.startTime, record.endTime)} (${moment(startTime).format('LL , HH:mm:ss')})`}</div>
  //   },
  // ]
  
  const groupedCheckpoints = _groupBy(checkpoints, cp => cp.user.bib)
  let dataSource = buildData(event, groupedCheckpoints)
  dataSource = sortRank(dataSource)

  // let graphData
  // graphData = dataSource && dataSource.map(({pos, name, bib, chipTime, gunTime, avgPace }) => (
  //   { 
  //     label: name, 
  //     value: pos,
  //     pos,
  //     bib,
  //     chipTime,
  //     gunTime
  //   }
  // )).slice(0, 3)

  // if (graphData && graphData.length > 1) {
  //   if (graphData.length > 2) {
  //     const _3rd = graphData && graphData.shift()
  //     graphData && graphData.push(_3rd)
  //   }
  // }

  return (
    <Row type="flex" justify="center" style={{ backgroundColor: '#fff', marginTop: '70px' }}>
      {/* <Col xs={24}>
        <Card bordered={true} style={{ width: '100%' }}>
          {graphData && graphData.length !== 0 ? <RenderChart dataSource={graphData}/> : <h2>ยังไม่มีผลวิ่ง</h2>}
        </Card>
      </Col> */}
      <Col xs={24} lg={24} style={{ padding: '5px' }}>
        <Space style={{ width: '100%' }} direction="vertical">
          {event.slug ==='Virtual'? <h1>ผลการวิ่งงาน {event.name}  {date && date < dateList.length && moment(dateList[date]).format('LL')}</h1>
            :<h1>ผลการวิ่งงาน {event.name} {moment(event.startTime).format('LL')}</h1>
          }
          
          <Table dataSource={ dataSource } columns={columns} pagination={{ pageSize: 20 }} loading={loading} />
        </Space>
        
      </Col>
    </Row>
  )
}
export default Result