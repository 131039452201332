import React, { useEffect, useState } from 'react'
import { ApolloProvider, InMemoryCache, ApolloClient } from '@apollo/client'
import ReactDOM from 'react-dom'
import { persistCache } from 'apollo-cache-persist'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { colors } from '@material-ui/core'

import firebase from 'firebase/app'
import { initDB } from 'react-indexed-db'

import shadows from './theme/shadows'
// import firebaseConfig from './firebaseConfig'
import { DBConfig } from './dbConfig'
import RouterConfig from './Router'
import * as serviceWorker from './serviceWorker'

firebase.initializeApp({
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: "parkrun-th.firebaseapp.com",
  databaseURL: "https://parkrun-th.firebaseio.com",
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET
})
initDB(DBConfig)
let client

const THEME = createMuiTheme({
  typography: {
    fontFamily: '"Kanit", sans-serif',
  },
  palette: {
    primary: {
      light: '#82e9de',
      main: '#4db6ac',
      dark: '#00867d',
      contrastText: '#fff',
    },
    secondary: {
      light: '#82e9de',
      main: '#4db6ac',
      dark: '#00867d',
      contrastText: '#000',
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600],
    },
  },
})

function ApolloProviderPersisted(props) {
  const [isPersisted, setIsPersisted] = useState()
  const cache = new InMemoryCache()
  useEffect(() => {
    async function hydrate() {
      await persistCache({
        cache,
        storage: window.localStorage,
      })
      setIsPersisted(true)
    }
    hydrate()
  })

  client = new ApolloClient({
    uri: process.env.REACT_APP_API_URL,
    cache,
  })

  if (!isPersisted) {
    return props.persistGate || null
  }

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>
}

ReactDOM.render(
  <ApolloProviderPersisted>
    <shadows>
      <MuiThemeProvider theme={THEME}>

        <RouterConfig />
        {/* <App/> */}

      </MuiThemeProvider>
    </shadows>
  </ApolloProviderPersisted>,
  document.getElementById('root'),
)
export default client

serviceWorker.unregister()
