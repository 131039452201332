import React, { useState, Fragment } from 'react'
import jwtDecode from 'jwt-decode'
import clsx from 'clsx'
import 'antd/dist/antd.css'
import {
  Container,
  Grid,
  makeStyles,
  TextField,
  Typography,
  Button,
} from '@material-ui/core'
import {
  notification,
} from 'antd'
import {
  useHistory, useParams
} from 'react-router-dom'
import _get from 'lodash/get'
import AppFooter from '../components/Footer'
import { useMutation } from '@apollo/react-hooks'
import passwordResetMutation from '../graphql/mutations/changePassword'

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '60vh'
  },
  input: {
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: '#357B77',
      borderWidth: '2px',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#357B77',
    },
  },
  borderinput: {
    borderRadius: '5px',
    backgroundColor: '#fafafa',
  },
  margin: {
    margin: theme.spacing(0.5),
    width:'100%'
  },
}))

const ResetPassword = ({ className, ...rest }) => {
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const classes = useStyles()
  const history = useHistory()
  const { token } = useParams()
  const [passwordResetRequest] = useMutation(passwordResetMutation)

  let user
  if(token){
		try {
			user = jwtDecode(token)
		} catch (error) {
			notification.error({
				message: `url ไม่ถูกต้อง`,
				duration: 5,
				placement: 'bottomRight'
			})
			history.push('/')
		}
  }

  //console.log(user)

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    const delay = ms => new Promise(res => setTimeout(res, ms));
    passwordResetRequest({
      variables: {
        _id: user.id,
        password: password
      }
    }).then( async (res) => {
      await notification.success({
        message: `เปลี่ยนรหัสผ่านสำเร็จ`,
        duration: 5,
        placement: 'bottomRight'
      })
      await delay(3000)
      setLoading(false)
      history.push('/')
    }
		).catch(err => {
			setLoading(false)
			console.log(err)
		})


  }

  return (
    <Fragment>
        <Container maxWidth="lg">
          <Grid container justify="center" className={clsx(classes.root, className)} {...rest}>
            <Grid item container justify="center" style={{marginTop:'100px'}}>
              <Typography variant="h6">password reset</Typography>
            </Grid>
            <form style={{marginTop: '-100px'}} onSubmit={(e)=>handleSubmit(e)}>
            <Grid item container spacing={2} md={8} >
                <Typography>email</Typography>
              <Grid item xs={12}>
                <TextField
                  required
                  variant="outlined"
                  label="email"
                  id="email"
                  disabled
                  value={_get(user, 'email')}
                  fullWidth
                  autoFocus
                  className={classes.input}
                  InputProps={{
                    className: classes.borderinput,
                  }}
                  InputLabelProps={{
                    style: { color: '#2E7672' },
                  }}
                />
              </Grid>
              <Typography>password</Typography>
              <Grid item xs={12}>
                <TextField
                  required
                  variant="outlined"
                  label="password"
                  id="password"
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  fullWidth
                  autoFocus
                  className={classes.input}
                  InputProps={{
                    className: classes.borderinput,
                  }}
                  InputLabelProps={{
                    style: { color: '#2E7672' },
                  }}
                />
              </Grid>   
            
              <Grid item xs={12} md={12} >
                <div style={{display: 'flex',justifyContent: 'center'}}>
                  <Button
                    color="primary"
                    type="submit"
                    align="center" 
                    variant="contained"
                    className={classes.margin}
                    disabled={loading}
                  >
                    Submit
                  </Button>
                </div>
              </Grid>  
            </Grid>
              </form>
          </Grid>
          <div style={{marginTop:'120px' ,width:'100%'}}>
            <AppFooter description="795 ถนน บรรทัดทอง แขวง วังใหม่ เขตปทุมวัน กรุงเทพมหานคร 10330" />
          </div>
        </Container>
      </Fragment>
  )

}

export default ResetPassword