import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useIndexedDB } from "react-indexed-db";
import {
  Layout,
  List,
  Button,
  Typography,
  Space,
  Empty,
  Modal,
  message,
  Tabs,
  notification,
} from "antd";
import { gql, useMutation } from "@apollo/client";
import { Detector } from "react-detect-offline";
import _reverse from "lodash/reverse";
import _get from "lodash/get";
import moment from "moment";

const PUSH_CHECKPOINT = gql`
  mutation pushCheckpoints($record: [CreateManycheckpointsInput]) {
    checkpointCreateMany(records: $record) {
      records {
        _id
        slug
        time
        runnerId
        position
        raceId
      }
    }
  }
`;

function Info() {
  const [checkIn, setCheckIn] = useState(null);
  const [checkpoints, setCheckpoints] = useState([]);
  const history = useHistory();
  const [pushCheckpoint] = useMutation(PUSH_CHECKPOINT);
  const getAllCheckIn = useIndexedDB("checkIn").getAll;
  // const clearCheckIn = useIndexedDB('checkIn').clear
  const getAllCheckpoint = useIndexedDB("checkpoint").getAll;
  const clearCheckpoint = useIndexedDB("checkpoint").clear;
  const updateCheckpoint = useIndexedDB("checkpoint").update;
  const clearCheckIn = useIndexedDB("checkIn").clear;

  useEffect(() => {
    async function init() {
      let checkInData = await getAllCheckIn();
      let checkpointData = await getAllCheckpoint();
      _reverse(checkpointData);
      if (checkInData.length === 0) {
        history.replace("/event");
        return;
      }
      const startTime = _get(checkInData, "0.startTime");
      const endTime = moment(startTime).add(3, "days").toDate();
      if (moment().isAfter(endTime)) {
        notification.error({
          message: "ไม่สามารถเลือกสนามนี้ได้",
          duration: 5,
          placement: "bottomRight",
        });
        await clearCheckIn();
        history.replace("/eventlist");
      }

      const currentPos = _get(checkInData, "0.position");
      const currentUserId = _get(checkInData, "0.userId");
      checkpointData = checkpointData.filter(
        ({ position, userId }) =>
          position === currentPos && userId === currentUserId
      );
      setCheckIn(checkInData[0]);
      setCheckpoints(checkpointData);
    }
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCheckout = async () => {
    await clearCheckIn();
    history.replace("/eventlist");
  };

  const handleOpenCam = () => history.push("/reader");
  const handlePushData = async () => {
    const prepareData = checkpoints
      .filter(({ status }) => status === "pending")
      .map(
        ({ _id, runnerId, position, slug, time, userId, raceId, source }) => ({
          _id,
          runnerId: runnerId,
          position: parseInt(position, 10),
          slug,
          time,
          userId,
          raceId,
          source,
        })
      );
    if (prepareData.length === 0) return;

    try {
      await pushCheckpoint({
        variables: {
          record: prepareData,
        },
      });
      const updatedCheckpoints = checkpoints.map(
        ({ id, _id, position, slug, time, userId, raceId, source }) => {
          const data = {
            id,
            _id,
            position,
            slug,
            time,
            userId,
            raceId,
            status: "sent",
            source,
          };
          updateCheckpoint(data);
          return data;
        }
      );
      setCheckpoints(updatedCheckpoints);
      message.success({
        content: "ส่งข้อมูลเรียบร้อย",
        style: {
          marginTop: "70px",
        },
      });
      // await clearCheckpoint()
      // setCheckpoints([])
    } catch (e) {
      console.error("e", e);
      // await clearCheckpoint()
      // setCheckpoints([])
    }
  };
  const handleClearData = async () => {
    Modal.confirm({
      title: "ต้องการล้างข้อมูลการเช็คอินหรือไม่ ?",
      content: "ถ้าลบแล้วจะไม่สามารถกู้ข้อมูลคืนได้",
      onOk: async () => {
        await clearCheckpoint();
        setCheckpoints([]);
      },
      onCancel: () => {
        return;
      },
    });
  };

  if (!checkIn) {
    return <div>checking...</div>;
  }
  const pendingData = checkpoints.filter(({ status }) => status === "pending");
  const sentData = checkpoints.filter(({ status }) => status === "sent");
  const isPendingEmpty = pendingData.length === 0;
  const isSentEmpty = sentData.length === 0;

  const $compPending = (
    <div style={{ maxHeight: 300, overflow: "scroll", padding: 10 }}>
      <List
        bordered
        dataSource={pendingData}
        renderItem={(item) => {
          const time = new Date(item.time);
          return (
            <List.Item>
              {item.position} (pos.) |{item.runnerId} (runnerId) |
              {time.toLocaleTimeString("th-TH")} |{item.status}
            </List.Item>
          );
        }}
      />
    </div>
  );
  const $compSent = (
    <div style={{ maxHeight: 300, overflow: "scroll", padding: 10 }}>
      <List
        bordered
        dataSource={sentData}
        renderItem={(item) => {
          const time = new Date(item.time);
          return (
            <List.Item>
              {item.position} (pos.) |{item.runnerId} (runnerId) |
              {time.toLocaleTimeString("th-TH")} |{item.status}
            </List.Item>
          );
        }}
      />
    </div>
  );
  return (
    <Layout style={{ marginTop: "75px" }}>
      <Layout.Content
        style={{
          padding: 10,
          backgroundColor: "#fff",
        }}
      >
        <Space style={{ width: "100%" }} direction="vertical" size={15}>
          <div style={{ textAlign: "center" }}>
            <Typography.Title level={4}>
              {checkIn.name} ตำแหน่งที่ {checkIn.position}
            </Typography.Title>
          </div>
          <Tabs defaultActiveKey="1" centered>
            <Tabs.TabPane tab={`Pending (${pendingData.length})`} key="1">
              {!isPendingEmpty ? $compPending : <Empty />}
            </Tabs.TabPane>
            <Tabs.TabPane tab={`Sent (${sentData.length})`} key="2">
              {!isSentEmpty ? $compSent : <Empty />}
            </Tabs.TabPane>
          </Tabs>
          <div>
            <Button size="large" type="primary" onClick={handleOpenCam} block>
              เปิดกล้อง
            </Button>
          </div>
          <div>
            <Button size="large" type="primary" onClick={handleCheckout} block>
              เปลี่ยนตำแหน่ง
            </Button>
          </div>

          {!isPendingEmpty && (
            <Space direction="vertical" style={{ width: "100%" }} size={30}>
              <Detector
                render={({ online }) => {
                  return (
                    <Button
                      size="large"
                      onClick={handlePushData}
                      disabled={!online}
                      block
                    >
                      {online ? `ส่งข้อมูล (${pendingData.length})` : "ออฟไลน์"}
                    </Button>
                  );
                }}
              />
              <Button
                size="large"
                onClick={handleClearData}
                type="danger"
                block
              >
                ล้างข้อมูล
              </Button>
            </Space>
          )}
        </Space>
      </Layout.Content>
    </Layout>
  );
}

export default Info;
