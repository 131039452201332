import React, { Fragment } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import moment from 'moment'
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Typography,
  makeStyles,
  withStyles,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'
import { Spin } from 'antd'
import _size from 'lodash/size'
import _get from 'lodash/get'
import _groupBy from 'lodash/groupBy'
import _minBy from 'lodash/minBy'
import _orderBy from 'lodash/orderBy'
import getUser from '../../graphql/queries/getUserByBib'
import getParkrunner from '../../graphql/queries/getParkrunner'
import getVolunteerStat from '../../graphql/queries/getVolunteerStat'
import AppFooter from '../../components/Footer'

const useStyles = makeStyles((theme) => ({
  root: {
    width:'100%',
    backgroundColor: 'inherit'
  },
  table1: {
    width: '100%',
    marginTop: theme.spacing(2),
    overflowX: 'auto',
  },
  table2: {
    width: '80%',
    marginTop: theme.spacing(2),
    overflowX: 'auto',
    '@media (max-width: 868px)': {
        width:'100%'
    }
  },
  marginTable: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  avatar: {
    margin: '20px 0 10px',
    height: 150,
    width: 150,
  },
  sidenav: {
    alignItems: 'center',
    marginTop: '100px',
    display: 'flex',
    flexDirection: 'column',
    width:'100%',
    '@media (max-width: 414px)': {
        marginTop: '50px',
      }
  },
  table: {
    minWidth: 717,
  },
  title:{
    '@media (max-width: 483px)': {
        fontSize: 30,
      },
      '@media (max-width: 435px)': {
        fontSize: 28,
        margin: '30px 0 10px'
      }
  }
}))

const StyledTableCell = withStyles(()  => ({
    head: {
      backgroundColor: '#CAE4E5',
      color: '#000000',
      fontWeight:600,
      '@media (max-width: 540px)': {
        fontSize: 12,
      }
    },
    body: {
      fontSize: 14,
      '@media (max-width: 540px)': {
        fontSize: 12,
      }
    },
}))(TableCell)
  
  const StyledTableRow = withStyles(()  => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: '#ecf4f3',
      },
    },
  }))(TableRow)


const Parkrunner = ({ className, ...rest }) => {
  const classes = useStyles()
  const { bib } = useParams()

  const { data = { userOne: {} }, loading } = useQuery(getUser, {
    variables: {
      bib: Number(bib)
    },
    fetchPolicy: 'cache-and-network',
  })
  const recents = useQuery(getParkrunner, {
    variables: {
      bib: Number(bib)
    },
    fetchPolicy: 'cache-and-network',
  })
  const volunteers = useQuery(getVolunteerStat, {
    variables: {
      bib: Number(bib)
    },
    fetchPolicy: 'cache-and-network',
	})
  
  // if(loading){
  //   return <CircularProgress />
  // }

  const volunteerData = _get(volunteers, 'data.volunteerStat')
  const parkrunner = _get(recents, 'data.parkrunner')

  const calTime = (time) => {
    return moment.utc(moment(time,"YYYY/MM/DD HH:mm:ss").diff(moment(time,"YYYY/MM/DD 7:00:00"))).format("HH:mm:ss")
  }

  const raceGroup = _groupBy(parkrunner, 'races[0]._id')
  let CheckpointData = []
  /* eslint-disable array-callback-return */
  Object.keys(raceGroup).map(function(key, index) {
    CheckpointData.push(raceGroup[key][raceGroup[key].length - 1])
  })
  const raceNameGroup = _groupBy(CheckpointData, 'events[0].name')
  let events = Object.keys(raceNameGroup).map(function(key, index) {
    return {
      name:(raceNameGroup[key])[0].events[0].name,
      racesum: _size(raceNameGroup[key]),
      // minrank: _minBy(raceNameGroup[key], function(o) { return o.rank; }),
      // minrankall: _minBy(raceNameGroup[key], function(o) { return o.rankall; }),
      mintime: _minBy(raceNameGroup[key], function(o) { return o.time; })
    }
  });

  events = _orderBy(events, ['racesum'], ['desc'])

  

  return (
    <Fragment>
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          {loading? <Spin tip="Loading...data" />:
          <Grid item lg={12} xs={12} className={classes.sidenav}>
            <Card className={clsx(classes.root, className)} {...rest} elevation={0}>
              <CardContent>
                <Box alignItems="center" display="flex" flexDirection="column">
                    <Typography variant="h4" align="center" className={classes.title}>
                      {`${data.userOne.name}  ( ${_size(CheckpointData)} พาร์ครัน )`}
                    </Typography>
                    <Avatar className={classes.avatar} src={_get(data.userOne, 'image')} />
                    <Typography variant="subtitle1" display="flex" alignItems="flex-end" >
                    เพศ:&nbsp;{_get(data.userOne, 'gender') && _get(data.userOne, 'gender') === 'male' ? 'ชาย' : 'หญิง'} &nbsp;&nbsp;อายุ:&nbsp;{moment(new Date()).diff(moment(data.userOne.birthDate), 'years')} ปี
                    </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          }
        </Grid>

        {/* ---------------------Most Recent Runs----------------------------------- */}
        <Grid container spacing={2}>
          <Grid item lg={12} xs={12}>
            {recents.loading ?
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spin tip="Loading...data" />
            </div>:<>
                <Typography variant="h5" align="center">
                    รายการวิ่งล่าสุด
                </Typography>
            <Card className={clsx(classes.table1, className)} {...rest} elevation={0}>
                <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>ชื่อสนาม</StyledTableCell>
                            <StyledTableCell align="center">วันที่</StyledTableCell>
                            {/* <StyledTableCell align="center" style={{width:320}}>{`อันดับในจำนวนนักวิ่งเพศ${_get(data.userOne, 'gender') && _get(data.userOne, 'gender') === 'male' ? 'ชาย' : 'หญิง'}ทั้งหมด`}</StyledTableCell>
                            <StyledTableCell align="center" style={{width:300}}>อันดับในจำนวนนักวิ่งทั้งหมด</StyledTableCell> */}
                            <StyledTableCell align="center" >เวลา</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {CheckpointData.map(row => (
                        <StyledTableRow key={row._id}>
                        <StyledTableCell component="th" scope="row">
                            {row.events[0].name}
                        </StyledTableCell>
                        <StyledTableCell align="center">{moment(row.time).format('DD/MM/YYYY')}</StyledTableCell>
                        {/* <StyledTableCell align="center">{row.rank}</StyledTableCell>
                        <StyledTableCell align="center">{row.rankall}</StyledTableCell> */}
                        <StyledTableCell align="center">{calTime(row.time)}</StyledTableCell>
                        </StyledTableRow>
                    ))}
                    </TableBody>
                </Table>
            </Card>
            </>
            }
          </Grid>

        {/* ---------------------Event Summaries----------------------------------- */}      
          <Grid item lg={12} xs={12} className={classes.marginTable}>
                <Typography variant="h5" align="center">
                    สรุปผลการวิ่ง
                </Typography>
            <Card className={clsx(classes.table2, className)} {...rest} elevation={0}>
                <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>ชื่อสนาม</StyledTableCell>
                            <StyledTableCell align="center" >วิ่ง&nbsp;(ครั้ง)</StyledTableCell>
                            {/* <StyledTableCell align="center" style={{width:200}}>{`อันดับที่ดีที่สุดในจำนวนนักวิ่งเพศ${_get(data.userOne, 'gender') && _get(data.userOne, 'gender') === 'male' ? 'ชาย' : 'หญิง'}ทั้งหมด`}</StyledTableCell>
                            <StyledTableCell align="center" style={{width:200}}>อันดับที่ดีที่สุดในจำนวนนักวิ่งทั้งหมด</StyledTableCell> */}
                            <StyledTableCell align="center" style={{width:200}}>เวลาที่ดีที่สุด</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {events.map(row => (
                        <StyledTableRow key={row.name}>
                        <StyledTableCell component="th" scope="row">
                            {row.name}
                        </StyledTableCell>
                        <StyledTableCell align="center">{row.racesum}</StyledTableCell>
                        {/* <StyledTableCell align="center">{row.minrank.rank}</StyledTableCell>
                        <StyledTableCell align="center">{row.minrankall.rankall}</StyledTableCell> */}
                        <StyledTableCell align="center">{calTime(row.mintime.time)}</StyledTableCell>
                        </StyledTableRow>
                    ))}
                    </TableBody>
                </Table>
            </Card>
          </Grid>

          {/* ---------------------Volunteer Summary----------------------------------- */} 
          <Grid item lg={12} xs={12} className={classes.marginTable}>
            {volunteers.loading ? <Spin tip="Loading...data" />:<>
                <Typography variant="h5" align="center">
                    สรุปอาสาสมัคร
                </Typography>
            <Card className={clsx(classes.table2, className)} {...rest} elevation={0}>
                <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>ปี</StyledTableCell>
                            <StyledTableCell align="center" style={{width:200}}>บทบาท</StyledTableCell>
                            <StyledTableCell align="center">จำนวนครั้ง</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {volunteerData && volunteerData.map(row => (
                        <StyledTableRow key={row._id}>
                        <StyledTableCell component="th" scope="row">
                            {row._id}
                        </StyledTableCell>
                        <StyledTableCell align="center">QR Code Scanner</StyledTableCell>
                        <StyledTableCell align="center">{row.count}</StyledTableCell>
                        </StyledTableRow>
                    ))}
                    </TableBody>
                </Table>
            </Card>
            </>
            }
          </Grid>
        </Grid>
      </Container>
      <div style={{ marginTop: '10px' }}>
        <AppFooter description="795 ถนน บรรทัดทอง แขวง วังใหม่ เขตปทุมวัน กรุงเทพมหานคร 10330" />
      </div>
    </Fragment>
  )
}

Parkrunner.propTypes = {
  className: PropTypes.string,
}

export default Parkrunner