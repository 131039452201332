import React, { Fragment, useContext } from "react";
import { useQuery } from "@apollo/react-hooks";
import PropTypes from "prop-types";
import clsx from "clsx";
// import { v4 as uuid } from 'uuid';
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Avatar,
  Box,
  colors,
  Button,
  Card,
  CardContent,
  Typography,
  makeStyles,
  Container,
  Grid,
  CircularProgress,
  CardHeader,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@material-ui/core";
import MoneyIcon from "@material-ui/icons/Money";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import AddIcCallIcon from "@material-ui/icons/AddIcCall";
import FavoriteIcon from "@material-ui/icons/Favorite";
import CakeIcon from "@material-ui/icons/Cake";
import WcIcon from "@material-ui/icons/Wc";
import QRCode from "qrcode.react";

// import getEvent from '../../graphql/queries/getAllEvent'
import _get from "lodash/get";
import _groupBy from "lodash/groupBy";

import getUser from "../../graphql/queries/getUserOne";
import getCheckpoint from "../../graphql/queries/getCheckpointByuserId";
import AuthContext from "../../context/AuthContext";
import AppFooter from "../../components/Footer";
import raceRun from "../../images/racerun.svg";
import vrRun from "../../images/anywhere.jpg";
import RewardCoinsContainer from "../Reward/RewardCoinsContainer";

const useStyles = makeStyles((theme) => ({
  root: {},
  root2: {
    minHeight: "100%",
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  avatar: {
    height: 150,
    width: 150,
  },
  sidenav: {
    alignItems: "center",
    marginTop: "100px",
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  header_bg: {
    height: "auto",
    backgroundColor: "#41AEA9",
  },
  avatar2: {
    backgroundColor: colors.red[600],
    height: 56,
    width: 56,
  },
  differenceIcon: {
    color: colors.red[900],
  },
  differenceValue: {
    color: colors.red[900],
    marginRight: theme.spacing(1),
  },
  table1: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    minWidth: 600,
  },
  paper: {
    height: "auto",
    padding: theme.spacing(1),
    marginTop: "100px",
    "@media (max-width: 959px)": {
      marginTop: "10px",
    },
  },
  phase: {
    display: "block",
    magin: "auto",
    "@media (max-width: 768px)": {
      marginLeft: "32%",
    },
    "@media (max-width: 425px)": {
      marginLeft: "15%",
    },
    "@media (max-width: 375px)": {
      marginLeft: "10%",
    },
    "@media (max-width: 320px)": {
      marginLeft: "4%",
    },
  },
  actions: {
    justifyContent: "flex-end",
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  MuiCardHeader: {
    fontSize: "25px",
    paddingTop: "10px"
  },
  textboby: {
    color: "#fff",
    display: "flex",
    paddingBottom: "5px",
  },
  textName: {
    margin: theme.spacing(1),
    color: "#fff",
    fontSize: "25px",
  },
  carouimg: {
    height: "300px",
    position: "relative",
    flexBasis: "auto",
    display: "flex",
    justifyContent: "center",
  },
}));

const bibNumber = (number) => {
  const pad = "0000000";
  return pad.substr(0, pad.length - `${number}`.length) + number;
};

const ProfilePage = ({ className, ...rest }) => {
  const { userInfo } = useContext(AuthContext);
  // console.log(userInfo)
  const { data = { userOne: {} }, loading } = useQuery(getUser, {
    variables: {
      _id: `${userInfo._id}`,
    },
    fetchPolicy: "cache-and-network",
  });
  // console.log(data)

  const {
    data: data3 = { checkpointMany: [], regisMany: [] },
    loading: loading2,
  } = useQuery(getCheckpoint, {
    variables: {
      userId: `${userInfo._id}`,
    },
    fetchPolicy: "cache-and-network",
  });
  const classes = useStyles();

  if (loading && loading2) {
    return <p>loading</p>;
  }
  const isNotNullNorUndefined = function (o) {
    return typeof o !== "undefined" && o !== null;
  };
  const varr = data3.regisMany.filter(
    (regis) => regis.regisType === "VALUNTEER"
  );
  const valunteercount = varr.filter((type) => type.status === "apporve");
  const raceCP = data3.checkpointMany.filter((cp) => cp.isVirtual === false);
  const vrCP = data3.checkpointMany.filter((cp) => cp.isVirtual === true);
  const totalResult = vrCP.map((data) => data)
  console.log(totalResult);
  const groupCP = _groupBy(raceCP, "raceId");
  //console.log(groupCP);
  let CheckpointData = [];
  /* eslint-disable array-callback-return */
  Object.keys(groupCP).map(function (key, index) {
    CheckpointData.push(groupCP[key][groupCP[key].length - 1]);
  });
  //console.log(CheckpointData);

  const bestTimeData = CheckpointData.filter(isNotNullNorUndefined)[0];
  // const finalCp = CheckpointData.concat(vrCP)
  // console.log(finalCp);

  function calcTime(before, current) {
    const curTime = moment(current);
    const beforeTime = moment(before);
    const diff = curTime.diff(beforeTime);
    const duration = moment.duration(diff);
    return moment.utc(duration.asMilliseconds()).format("HH:mm:ss");
  }

  const downloadQR = (bib) => {
    const canvas = document.getElementById("123456");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    const downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `qrcode${bib}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <Fragment>
      <Container maxWidth="lg">
        <Grid
          container
          spacing={2}
          className={clsx(classes.header_bg, className)}
          {...rest}
        >
          <Grid item lg={4} md={4} xs={12} className={classes.sidenav}>
            {loading ? (
              <CircularProgress animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </CircularProgress>
            ) : (
              <>
                <Avatar
                  className={classes.avatar}
                  src={_get(data.userOne, "image")}
                />
                <Typography
                  gutterBottom
                  variant="h5"
                  align="center"
                  className={classes.textName}
                >
                  {data.userOne.name}
                </Typography>
                <div style={{ alignItems: "end" }}>
                  <Typography className={classes.textboby} variant="subtitle2">
                    <EmailIcon fontSize="small" className={classes.icon} />{" "}
                    {data.userOne.email}
                  </Typography>
                  <Typography className={classes.textboby} variant="subtitle2">
                    <PhoneIcon fontSize="small" className={classes.icon} />{" "}
                    {data.userOne.phone}
                  </Typography>
                  <Typography className={classes.textboby} variant="subtitle2">
                    <AddIcCallIcon fontSize="small" className={classes.icon} />
                    ฉุกเฉิน: {data.userOne.emergenPhone}
                  </Typography>
                  <Typography className={classes.textboby} variant="subtitle2">
                    <FavoriteIcon fontSize="small" className={classes.icon} />
                    แพ้ยา: {data.userOne.drug}
                  </Typography>
                  <Typography className={classes.textboby} variant="subtitle2">
                    <WcIcon fontSize="small" className={classes.icon} />
                    เพศ:{" "}
                    {_get(data.userOne, "gender") &&
                    _get(data.userOne, "gender") === "male"
                      ? "ชาย"
                      : "หญิง"}
                  </Typography>
                  <Typography className={classes.textboby} variant="subtitle2">
                    <CakeIcon fontSize="small" className={classes.icon} />
                    อายุ:{" "}
                    {moment(new Date()).diff(
                      moment(data.userOne.birthDate),
                      "years"
                    )}{" "}
                    ปี
                  </Typography>
                </div>
                <Button
                  align="center"
                  variant="contained"
                  href="/editprofile"
                  style={{
                    marginTop: "10px",
                    width: "50%",
                    borderRadius: "15px",
                    backgroundColor: "#fff",
                    color: "#41AEA9",
                  }}
                >
                  แก้ไขโปรไฟล์
                </Button>
              </>
            )}
          </Grid>

          <Grid item lg={8} md={8} xs={12}>
            <Card className={classes.paper} elevation={0}>
              <Grid container justify="space-between" spacing={1}>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  display="flex"
                  justifyContent="center"
                >
                  <div className={classes.carouimg}>
                    <QRCode
                      id="123456"
                      value={`${data.userOne._id}`}
                      style={{ width: "100%", height: "auto" }}
                    />
                  </div>
                  <Typography align="center" variant="h3">
                    {bibNumber(data.userOne.bib)}
                  </Typography>
                  <Button
                    align="center"
                    variant="contained"
                    style={{
                      marginTop: "10px",
                      width: "100%",
                      borderRadius: "15px",
                      backgroundColor: "#41AEA9",
                      color: "#fff",
                    }}
                    onClick={() => downloadQR(data.userOne.bib)}
                  >
                    ดาวน์โหลดคิวอาร์โค้ดของคุณ
                  </Button>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Grid container justify="space-between" spacing={2}>
                    <Grid item xs={12}>
                      <Card className={clsx(classes.root, className)} {...rest}>
                        <CardContent>
                          <Grid container justify="space-between" spacing={2}>
                            <Grid item>
                              <Typography
                                color="textSecondary"
                                gutterBottom
                                variant="h6"
                              >
                                วิ่งแล้ว
                              </Typography>
                              <Typography color="textPrimary" variant="h5">
                                {CheckpointData.length} สนาม
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Avatar className={classes.avatar2}>
                                <MoneyIcon />
                              </Avatar>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12}>
                      <Card className={clsx(classes.root, className)} {...rest}>
                        <CardContent>
                          <Grid container justify="space-between" spacing={3}>
                            <Grid item>
                              <Typography
                                color="textSecondary"
                                gutterBottom
                                variant="h6"
                              >
                                อาสาสมัคร
                              </Typography>
                              <Typography color="textPrimary" variant="h5">
                                {valunteercount.length} สนาม
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Avatar className={classes.avatar2}>
                                <MoneyIcon />
                              </Avatar>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                    {typeof bestTimeData !== "undefined" && (
                      <Grid item xs={12}>
                        <Card
                          className={clsx(classes.root, className)}
                          {...rest}
                        >
                          <CardContent>
                            <Grid container justify="space-between" spacing={3}>
                              <Grid item>
                                <Typography
                                  color="textSecondary"
                                  gutterBottom
                                  variant="h6"
                                >
                                  เวลาที่ดีที่สุด
                                </Typography>
                                <Typography color="textPrimary" variant="h5">
                                  {calcTime(
                                    bestTimeData.race.startTime,
                                    bestTimeData.time
                                  ) + ` ชั่วโมง`}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Avatar className={classes.avatar2}>
                                  <MoneyIcon />
                                </Avatar>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>

        <RewardCoinsContainer classname={classes.root2} classProb={classes} />

        {/* -------------------------------runner----------------------------------- */}
        <Grid container spacing={2} className={classes.root2}>
          <Grid item lg={12} xs={12}>
            <Card
              className={clsx(classes.root, className)}
              {...rest}
              elevation={0}
            >
              <CardHeader
                title="งานที่ร่วมวิ่ง"
                style={{ backgroundColor: "#CAE4E5" }}
              />
              <Divider />
              <PerfectScrollbar className={classes.table1}>
                <Box className={classes.table}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>ชื่องานวิ่ง</TableCell>
                        <TableCell sortDirection="desc">
                          <Tooltip enterDelay={300} title="Sort">
                            <TableSortLabel active direction="desc">
                              วันที่
                            </TableSortLabel>
                          </Tooltip>
                        </TableCell>
                        <TableCell>ระยะเวลาที่ได้</TableCell>
                        <TableCell>ประเภทงาน</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {CheckpointData.map((dataUser) => (
                        <TableRow hover key={dataUser.id}>
                          <TableCell>
                            {dataUser.isVirtual
                              ? "ParkRun @nywhere"
                              : dataUser.race.name}
                          </TableCell>
                          <TableCell>
                            {dataUser.isVirtual
                              ? moment(dataUser.startTime).format("DD/MM/YYYY")
                              : moment(dataUser.race.startTime).format(
                                  "DD/MM/YYYY"
                                )}
                          </TableCell>
                          <TableCell>
                            {dataUser.isVirtual
                              ? calcTime(dataUser.startTime, dataUser.endTime) +
                                ` ชั่วโมง`
                              : calcTime(
                                  dataUser.race.startTime,
                                  dataUser.time
                                ) + ` ชั่วโมง`}
                          </TableCell>
                          <TableCell style={{ padding: 0 }}>
                            {dataUser.isVirtual ? (
                              <img src={vrRun} alt="vr" />
                            ) : (
                              <img
                                src={raceRun}
                                alt="race"
                                style={{ paddingLeft: "30px" }}
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </PerfectScrollbar>
              <Box display="flex" justifyContent="flex-end" p={1}>
                <Button
                  color="primary"
                  endIcon={<ArrowRightIcon />}
                  size="small"
                  variant="text"
                  style={{ color: "#41AEA9" }}
                >
                  ถัดไป
                </Button>
              </Box>
            </Card>
          </Grid>
          {/* ---------------------anywhere----------------------------------- */}
          <Grid item lg={12} xs={12}>
            <Card
              className={clsx(classes.root, className)}
              {...rest}
              elevation={0}
            >
              <CardHeader
                title="Parkrun @nywhere"
                action={`สะสมทั้งหมด : ${totalResult.length} ครั้ง`}
                style={{ backgroundColor: "#CAE4E5" }}
                classes={{action : classes.MuiCardHeader}}
              />
              <Divider />
              <PerfectScrollbar className={classes.table1}>
                <Box className={classes.table}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>ชื่องานวิ่ง</TableCell>
                        <TableCell sortDirection="desc">
                          <Tooltip enterDelay={300} title="Sort">
                            <TableSortLabel active direction="desc">
                              วันที่
                            </TableSortLabel>
                          </Tooltip>
                        </TableCell>
                        <TableCell>ระยะเวลาที่ได้</TableCell>
                        <TableCell>ประเภทงาน</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {vrCP.map((dataUser) => (
                        <TableRow hover key={dataUser.id}>
                          <TableCell>
                            {dataUser.isVirtual
                              ? "ParkRun @nywhere"
                              : dataUser.race.name}
                          </TableCell>
                          <TableCell>
                            {dataUser.isVirtual
                              ? moment(dataUser.startTime).format("DD/MM/YYYY")
                              : moment(dataUser.race.startTime).format(
                                  "DD/MM/YYYY"
                                )}
                          </TableCell>
                          <TableCell>
                            {dataUser.isVirtual
                              ? calcTime(dataUser.startTime, dataUser.endTime) +
                                ` ชั่วโมง`
                              : calcTime(
                                  dataUser.race.startTime,
                                  dataUser.time
                                ) + ` ชั่วโมง`}
                          </TableCell>
                          <TableCell style={{ padding: 0 }}>
                            {dataUser.isVirtual ? (
                              <img src={vrRun} alt="vr" />
                            ) : (
                              <img
                                src={raceRun}
                                alt="race"
                                style={{ paddingLeft: "30px" }}
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </PerfectScrollbar>
              <Box display="flex" justifyContent="flex-end" p={1}>
                <Button
                  color="primary"
                  endIcon={<ArrowRightIcon />}
                  size="small"
                  variant="text"
                  style={{ color: "#41AEA9" }}
                >
                  ถัดไป
                </Button>
              </Box>
            </Card>
          </Grid>

          {/* ---------------------volunteer----------------------------------- */}
          <Grid item lg={12} xs={12}>
            <Card
              className={clsx(classes.root, className)}
              {...rest}
              elevation={0}
            >
              <CardHeader
                title="งานที่เป็นอาสาสมัคร"
                style={{ backgroundColor: "#f3eac2" }}
              />
              <Divider />
              <PerfectScrollbar className={classes.table1}>
                <Box className={classes.table}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>ชื่องานวิ่ง</TableCell>
                        <TableCell sortDirection="desc">
                          <Tooltip enterDelay={300} title="Sort">
                            <TableSortLabel active direction="desc">
                              วันที่
                            </TableSortLabel>
                          </Tooltip>
                        </TableCell>
                        <TableCell>สถานะ</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {varr.map((dataUser2) => (
                        <TableRow hover key={dataUser2.id}>
                          <TableCell>{dataUser2.name}</TableCell>
                          <TableCell>
                            {_get(dataUser2, "race.startTime")
                              ? moment(
                                  _get(dataUser2, "race.startTime")
                                ).format("DD/MM/YYYY")
                              : ""}
                          </TableCell>
                          <TableCell>
                            <Chip label={dataUser2.status} size="small" />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </PerfectScrollbar>
              <Box display="flex" justifyContent="flex-end" p={1}>
                <Button
                  color="primary"
                  endIcon={<ArrowRightIcon />}
                  size="small"
                  variant="text"
                  style={{ color: "#f5b461" }}
                >
                  ถัดไป
                </Button>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <AppFooter description="795 ถนน บรรทัดทอง แขวง วังใหม่ เขตปทุมวัน กรุงเทพมหานคร 10330" />
    </Fragment>
  );
};

ProfilePage.propTypes = {
  className: PropTypes.string,
};

export default ProfilePage;
