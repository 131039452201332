import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import "antd/dist/antd.css";
import {
  makeStyles,
  withStyles,
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  Button,
  Chip,
  Table,
  TableBody,
  TableCell,
  // TableHead,
  TableRow,
  Avatar,
  Link,
} from "@material-ui/core";

import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { Carousel } from "antd";

import "./css/pages.css";
import client from "../index";
import img from "../images/banner_edit1.jpg";
import img2 from "../images/homeBG2.1.png";
import imgparkrun01 from "./pictures/imgparkrun01.jpg";
import imgparkrun02 from "./pictures/imgparkrun02.jpg";
import imgparkrun03 from "./pictures/imgparkrun03.jpg";
import imgparkrun04 from "./pictures/imgparkrun04.jpg";
import AppFooter from "../components/Footer";
import Stats from "../components/AllStats";
import getTop10 from "../graphql/queries/getTop10Runner";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100%",
    paddingBottom: theme.spacing(0),
    paddingTop: theme.spacing(1),
  },
  root2: {
    minHeight: "100%",
  },
  bgSearchImage: {
    height: "500px",
    width: "100%",
    backgroundImage: `url(${img})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    "@media (max-width: 1024px)": {
      height: "370px",
    },
    "@media (max-width: 768px)": {
      height: "280px",
    },
    "@media (max-width: 425px)": {
      backgroundImage: `url(${img2})`,
      height: "500px",
    },
    "@media (max-width: 320px)": {
      height: "440px",
    },
  },
  card: {
    width: "100%",
    backgroundColor: "#e21145",
  },
  table: {
    minWidth: 600,
    "@media (max-width: 425px)": {
      minWidth: 500,
    },
  },
  table1: {
    width: "100%",
    marginTop: theme.spacing(2),
    overflowX: "auto",
  },
  avatar: {
    height: 100,
    width: 100,
    "@media (max-width: 425px)": {
      height: 60,
      width: 60,
    },
  },
  textlinktable: {
    color: "#263238",
    "&:hover": {
      color: "#41aea9",
    },
  },
  nav: {
    marginLeft: "5px",
    color: "#213e3b",
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "#c70039",
      color: "#fff",
    },
  },
  textboby: {
    color: "#41aea9",
    "&:hover": {
      color: "#0d7377",
    },
  },
  chip: {
    display: "flex",
    justifyContent: "center",
    marginTop: "5px",
    "& > *": {
      margin: theme.spacing(0.5),
    },
    "@media (max-width: 280px)": {
      flexWrap: "nowrap",
    },
  },
}));

const Accordion = withStyles({
  root: {
    border: "inherit",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);
const AccordionSummary = withStyles({
  root: {
    backgroundColor: "inherit",
    borderBottom: "inherit",
    marginBottom: -5,
    minHeight: 50,
    "&$expanded": {
      minHeight: 50,
    },
    color: "#777",
    "&:hover": {
      color: "#41AEA9",
    },
  },
  content: {
    "&$expanded": {
      margin: "10px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);
const AccordionDetails = withStyles((theme) => ({
  root: {
    // padding: theme.spacing(1),
  },
}))(MuiAccordionDetails);
const StyledTableCell = withStyles(() => ({
  // head: {
  //   backgroundColor: '#CAE4E5',
  //   color: '#000000',
  //   fontSize: 18,
  //   '@media (max-width: 320px)': {
  //     fontSize: 14,
  //   }
  // },
  body: {
    fontSize: 18,
    "@media (max-width: 425px)": {
      fontSize: 16,
    },
  },
}))(TableCell);

const StyledTableRow = withStyles(() => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#ecf4f3",
    },
  },
}))(TableRow);

const IndexPage = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState("");
  const history = useHistory();
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const { data = { getTop10Runner: [] } } = useQuery(getTop10, {
    client,
    fetchPolicy: "cache-and-network",
  });
  return (
    <Fragment>
      {/* -----------------------ประกาศ COVID-19--------------------------------- */}
      <Grid item xs={12} style={{ marginTop: "80px" }}>
        <Card className={classes.card} square variant="outlined">
          <CardContent className="alertBanner">
            <Typography align="center" gutterBottom className="textpage">
              ขณะนี้กิจกรรม Park Run THAILAND <u>บางสนาม</u>
              ในประเทศไทยถูกระงับเนื่องจาก COVID-19(Coronavirus) โปรดดู{" "}
              <Button
                color="primary"
                className={classes.nav}
                onClick={() =>
                  window.open("https://www.skho.moph.go.th/eoc/?p=1141")
                }
              >
                คำแนะนำ
              </Button>{" "}
              เพิ่มเติม
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      {/* -------------------------------------------------------- */}
      <div className={classes.root}>
        <Container maxWidth="lg" style={{ backgroundColor: "#fff" }}>
          <div>
            <img src={img} style={{ width: "100%" }} alt="banner" />
          </div>
        </Container>
      </div>

      {/* ------------------------Park Run THAILAND คืออะไร-------------------------------- */}
      <div className={classes.root}>
        <Container maxWidth="lg" style={{ backgroundColor: "#fff" }}>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} xs={12}>
              <Typography className="textbody01" variant="h5">
                Park Run THAILAND คืออะไร
              </Typography>
              <Typography className="textbody02">
                Park Run THAILAND
                เป็นเว็บไซต์ลงทะเบียนงานวิ่งฟรีที่จัดขึ้นทุกสัปดาห์ทั่วประเทศมีระยะวิ่งไม่เกิน
                5
                กิโลเมตร&ensp;จัดขึ้นในสวนสาธารณะหรือพื้นที่เปิดโล่ง&ensp;พาร์ครันเป็นกิจกรรมที่การสร้างเสริมสุขภาพกายใจที่ดีสร้างความเข้มแข็งให้ชุมชนที่อยู่ร่วมกันและขับเคลื่อนสุขภาวะในหลายมิติ&ensp;พาร์ครันเป็นประสบการณ์ที่ดีสามารถเข้าร่วมได้ทุกคน
                ทุกเพศ และทุกวัย
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} xs={12}>
              <div className="vi">
                <iframe
                  title="#"
                  className="video"
                  src="https://www.youtube.com/embed/dXZQ__4dRAU"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            </Grid>
          </Grid>
          {/* -----------------------สนามวิ่งยอดนิยม--------------------------------- */}
          {/* <Grid item lg={12} xs={12} style={{paddingBottom:'5px'}}>
              <Typography align="Left" variant="h5" className="titleHome">สนามวิ่งยอดนิยม เดือนธันวาคม</Typography>
              <div className={classes.chip}>
                <Divider variant="middle" style={{backgroundColor:'#63BAB6',height:'3px',width:'20%'}}/>
              </div>
                <Grid container spacing={1} style={{marginTop:'5px'}}>
                  <Grid item lg={3} md={6} sm={6} xs={12}>
                    <Paper className="paper" elevation={0} />
                  </Grid>
                  <Grid item lg={3} md={6} sm={6} xs={12}>
                    <Paper className="paper" elevation={0} />
                  </Grid>
                  <Grid item lg={3} md={6} sm={6} xs={12}>
                    <Paper className="paper" elevation={0} />
                  </Grid>
                  <Grid item lg={3} md={6} sm={6} xs={12}>
                    <Paper className="paper" elevation={0} />
                  </Grid>
              </Grid>
            </Grid> */}
        </Container>
      </div>
      {/* --------------------------ภาพบรรยากาศ กิจกรรม Park Run THAILAND------------------------------ */}
      <div className={classes.root2}>
        <Container maxWidth="lg" style={{ backgroundColor: "#63BAB6" }}>
          <Grid item xs={12}>
            <Typography align="center" variant="h5" className="title01">
              ภาพบรรยากาศกิจกรรม Park Run THAILAND
            </Typography>
            <Carousel autoplay>
              <div>
                <div className="carouimg">
                  <img className="img" alt="complex" src={imgparkrun01} />
                </div>
              </div>
              <div>
                <div className="carouimg">
                  <img className="img" alt="complex" src={imgparkrun02} />
                </div>
              </div>
              <div>
                <div className="carouimg">
                  <img className="img" alt="complex" src={imgparkrun03} />
                </div>
              </div>
              <div>
                <div className="carouimg">
                  <img className="img" alt="complex" src={imgparkrun04} />
                </div>
              </div>
            </Carousel>
            <CardContent>
              <Typography
                align="center"
                variant="subtitle1"
                className="textbody03"
              >
                นักวิ่งทุกคนเป็นเจ้าขอพาร์ครันไทยแลนด์&ensp;เมื่อทุกคนเป็นเจ้าของก็จะช่วยกันพัฒนาให้ระบบดีขึ้นเป็นการพัฒนาการวิ่งแบบใหม่&ensp;เมื่อมีผู้เข้าร่วมกิจกรรมเพิ่มขึ้นก็หมายถึงสุขภาพของคนไทยจะดีขึ้น
              </Typography>
              <div className={classes.chip}>
                <Chip size="small" label="พอเพียง" />
                <Chip size="small" label="วินัย" />
                <Chip size="small" label="สุจริต" />
                <Chip size="small" label="จิตอาสา" />
              </div>
            </CardContent>
          </Grid>
        </Container>
      </div>
      {/* ----------------------ตารางจัดอันดับ Top 10 นักวิ่ง---------------------------------- */}
      <div className={classes.root2}>
        <Container maxWidth="lg" style={{ backgroundColor: "#fff" }}>
          <Grid item lg={12} xs={12}>
            <Typography className="textbody01" align="center" variant="h5">
              10&nbsp;อันดับ&nbsp;นักวิ่งที่มีจำนวนวิ่งเยอะที่สุด
            </Typography>
            <Typography
              className="textbody04"
              align="center"
              variant="subtitle1"
            >
              ตารางแสดง&nbsp;10&nbsp;อันดับ&nbsp;นักวิ่งที่มีจำนวนการเข้าร่วมวิ่งในสนามพาร์ครันไทยแลนด์เยอะที่สุด
            </Typography>
            <Card className={classes.table1} elevation={0}>
              <Table className={classes.table} aria-label="customized table">
                {/* <TableHead>
                  <TableRow>
                    <StyledTableCell align="center" style={{width:'25%'}}>รูปโปรไฟล์</StyledTableCell>
                    <StyledTableCell align="center" style={{width:'25%'}}>เลขประจำตัว(บิบ)</StyledTableCell>
                    <StyledTableCell align="center" style={{width:'25%'}}>ชื่อ - สกุล</StyledTableCell>
                    <StyledTableCell align="center" style={{width:'30%'}}>จำนวนครั้งที่วิ่ง</StyledTableCell>
                  </TableRow>
              </TableHead> */}
                <TableBody>
                  {data.getTop10Runner.map((row, index) => (
                    <StyledTableRow key={row.name}>
                      <StyledTableCell align="left" component="th" scope="row">
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Avatar
                          className={classes.avatar}
                          alt=""
                          src={row.image}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        BIB&nbsp;{row.bib}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Link
                          onClick={() => history.push(`/parkrunner/${row.bib}`)}
                          className={classes.textlinktable}
                        >
                          {row.name}
                        </Link>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.Count}&nbsp;สนาม
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </Card>
          </Grid>
        </Container>
      </div>
      {/* --------------------------คำถามทั่วไป------------------------------ */}
      <div className={classes.root2}>
        <Container maxWidth="lg" style={{ backgroundColor: "#fff" }}>
          <Typography className="textbody01" align="center" variant="h5">
            คำถามทั่วไป
          </Typography>
          <Grid container justify="space-between" spacing={2}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Accordion
                square
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography className="titleQA">
                    Park Run THAILAND คืออะไร ?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="textQA">
                    <p>
                      {" "}
                      ● Park Run THAILAND คืองานวิ่ง 5 กม.
                      จัดขึ้นทุกเช้าวันเสาร์พร้อมกันทุก สนามทั่วประเทศ
                      เริ่มต้นวันเสาร์แรก 5 ธันวาคม 2563
                    </p>
                    <p>
                      {" "}
                      ●{" "}
                      <a href="/actions/register" className={classes.textboby}>
                        ลงทะเบียนกับ Park Run THAILAND
                      </a>{" "}
                      เพียงครั้งเดียว ก็ได้ QR Code เป็นเหมือน Bib ประจําตัว
                      ใช้วิ่งได้ทุกสนาม{" "}
                    </p>
                    <p>
                      {" "}
                      ● เป็นงานวิ่งทีจัดโดยอาสาสมัคร เพื่อนักวิ่ง เพื่อชุมชน
                      จัดการอย่างเรียบง่ายที่สุด ถึงมีผู้จัดเพียงคนเดียวก็ทําได้
                      มีระบบรองรับ
                    </p>
                    <p>
                      {" "}
                      ● ทุกคนเป็นได้ทั้ง นักวิ่ง อาสาสมัคร หรือผู้จัดงานก็ได้
                    </p>
                    <p> ● ไม่มีธุรกิจการค้ามาเกียวข้อง</p>
                    <p>
                      {" "}
                      ● เส้นทางวิ่งปลอดภัย วิ่งในสวน สนามกีฬา หรือชุมชน ฯลฯ{" "}
                    </p>
                    <p>
                      {" "}
                      ● เก็บสถิติการวิ่งทุกครั้งจะถูกบันทึกเข้าระบบส่วนกลาง{" "}
                    </p>
                    <p>
                      <b>ฟรี!! ไม่มีค่าใช้จ่ายตลอดไป</b>
                    </p>
                    <p>
                      บริหารจัดการโดยมูลนิธิสมาพันธ์ชมรมเดินวิ่งเพื่อสุขภาพไทย,
                      หน่วยงานราชการ, เอกชน ที่ร่วมสนับสนุน
                    </p>
                    <p>ดูแลและพัฒนาระบบโดยบริษัท ไทยดอทรัน จำกัด</p>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                square
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  aria-controls="panel2d-content"
                  id="panel2d-header"
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography className="titleQA">
                    ฉันวิ่งได้เมื่อไร ?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="textQA">
                    <p>
                      <b>การจัด Park Run จะจัดได้ 3 แบบตามความถี่ต่อเดือน</b>
                    </p>
                    <p>1. สนามที่จัดขึ้นทุกสัปดาห์ (ทุกวันเสาร์)</p>
                    <p>
                      2. สนามทีจัดขึ้นเดือนละ 2 ครั้ง (วันเสาร์สัปดาห์ที่ 1 และ
                      3 ของเดือน)
                    </p>
                    <p>
                      3. สนามที่จัดขึ้นเดือนละ 1 ครัง (เสาร์สัปดาห์แรกของเดือน)
                    </p>
                    <p>ทุกคนสามารถดูตารางสนามที่เปิดวิ่งได้ที่หน้าเพจของสนาม</p>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                square
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
              >
                <AccordionSummary
                  aria-controls="panel3d-content"
                  id="panel3d-header"
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography className="titleQA">
                    ฉันสามารถเข้าร่วมวิ่งได้หรือไม่ ?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="textQA">
                    <p>
                      <b>
                        ทุกคนสามารถเข้าร่วมวิ่งใน Park Run
                        เพียงทําตามขั้นตอนดังนี้
                      </b>
                    </p>
                    <p>
                      <b>
                        1.{" "}
                        <a
                          href="/actions/register"
                          className={classes.textboby}
                        >
                          ลงทะเบียน
                        </a>
                      </b>{" "}
                      : คลิกลงทะเบียนจากเมนูด้านขวาบน
                      ลงทะเบียนครั้งแรกครั้งเดียวเท่านั้น
                    </p>
                    <p>
                      <b>2. QR CODE</b> : หลังลงทะเบียนแล้วจะมีข้อความ SMS
                      ยืนยันทีมี QR Code และเลขบิบประจําตัว
                      ไปยังเบอร์มือถือของคุณ หรือไปยัง
                      <a href="/profile" className={classes.textboby}>
                        โปร์ไฟล์ของคุณ
                      </a>{" "}
                      คุณสามารถพิมพ์หรือเซฟภาพใส่มือถือ{" "}
                      <b>อย่าลืมนําติดตัวไปด้วยเมื่อคุณเรียกใช้</b>
                    </p>
                    <p>
                      <b>3. ค้นหาสนามและสมัคร</b> : คลิก
                      <a href="/eventMap" className={classes.textboby}>
                        สนามวิ่ง
                      </a>
                      จากเมนูด้านขวาบน จะแสดงสนาม Park Run
                      ทั้งหมดที่จัดขึ้นในสัปดาห์ต่าง ๆ คลิกเลือกสนาม
                      ระบบจะพาไปยังหน้าเพจของสนามจะแสดงข้อมูลต่างๆ
                      เกี่ยวกับสนามและมีตารางแสดงวันจัดงานวิ่งที่กําลังจะเกิดข้ึน
                      คุณสามารถคลิกสมัครในฐานะนักวิ่งได้
                      ในช่องตารางนักวิ่งของสนามนั้นและไปร่วมวิ่ง
                    </p>
                    <p className={classes.p}>
                      <b>4. เข้าร่วมวิ่ง</b>
                      <br />
                      • อย่าลืม QR Code ในมือถือหรือพิมพ์มาด้วยทุกครั้ง <br />
                      • ไปยังสนามจุดนัดพบก่อน เวลา 06:30 น.
                      <br />
                      • วอร์มอัพร่างกายก่อนวิ่ง นำโดยอาสาสมัคร เวลา 06:50 น.
                      <br />
                      • เตรียมเครื่องดื่มระหว่างวิ่งไปเอง
                      <br />
                      • ปล่อยตัวพร้อมกัน เวลา 07:00 น.
                      <br />• นํา QR Code
                      ให้อาสาสมัครสแกนเพื่อเก็บเวลาเมื่อวิ่งจบ
                    </p>
                    <p>
                      <b>5. ผลการวิ่ง</b> : หลังจากกิจกรรมเสร็จสิ้น จะมีข้อความ
                      SMS ที่มีผลการวิ่งของคุณ
                      หรือคุณสามารถเข้าไปดูรายละเอียดผลการวิ่งได้ที่หน้าโปรไฟล์
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                square
                expanded={expanded === "panel4"}
                onChange={handleChange("panel4")}
              >
                <AccordionSummary
                  aria-controls="panel4d-content"
                  id="panel4d-header"
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography className="titleQA">
                    ฉันสามารถวิ่งโดยไม่มีคิวอาร์โค้ดได้หรือไม่ ?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="textQA">
                    <p>
                      <b>ได้ แต่คุณจะไม่มีเวลา</b>
                    </p>
                    <p>
                      เราดำเนินงานโดยยึดนโยบาย{" "}
                      <b>'คิวอาร์โค้ดไม่มี = ไม่มีผลวิ่ง'</b>{" "}
                      หากคุณได้รับการรู้ข่าวสารที่แตกต่างออกไป
                      ฉันเกรงว่าคุณจะเข้าใจผิด!!
                      เราเข้มงวดกับนโยบายนี้เนื่องจากเราประมวลผลผลวิ่งมากกว่า
                      1,000 รายการของทุกวันเสาร์
                      และหากเราต้องผ่อนนโยบายนี้ก็จะเพิ่มปริมาณงานให้อาสาสมัครของเราเป็นสามเท่า{" "}
                    </p>
                    <p>
                      โชคดี ที่การพิมพ์สำเนาคิวอาร์โค้ดของคุณเป็นเรื่องง่าย -
                      ไปที่หน้าโปร์ไฟล์ของคุณ
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Accordion
                square
                expanded={expanded === "panel5"}
                onChange={handleChange("panel5")}
              >
                <AccordionSummary
                  aria-controls="panel5d-content"
                  id="panel5d-header"
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography className="titleQA">
                    ฉันจะเป็นอาสาสมัครได้อย่างไร ?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="textQA">
                    <p>
                      <b>
                        ทุกคนสามารถเข้าร่วมเป็นอาสาสมัครใน Park Run
                        เพียงทําตามขั้นตอนดังนี้
                      </b>
                    </p>
                    <p>
                      <b>
                        1.{" "}
                        <a
                          href="/actions/register"
                          className={classes.textboby}
                        >
                          ลงทะเบียน
                        </a>
                      </b>{" "}
                      : หากลงทะเบียนแล้ว ข้ามไปข้อ 2
                    </p>
                    <p>
                      <b>2. ค้นหาสนามและสมัคร</b> : คลิก
                      <a href="/eventMap" className={classes.textboby}>
                        สนามวิ่ง
                      </a>
                      จากเมนูด้านขวาบน จะแสดงสนาม Park Run
                      ทั้งหมดที่จัดขึ้นในสัปดาห์ต่าง ๆ คลิกเลือกสนาม
                      ระบบจะพาไปยังหน้าเพจของสนามจะแสดงข้อมูลต่างๆ
                      เกี่ยวกับสนามและมีตารางแสดงวันจัดงานวิ่งที่กําลังจะเกิดข้ึน
                      คุณสามารถคลิกเลือกตำแหน่งที่ต้องการสมัครในฐานะอาสาสมัครได้
                      ในช่องตารางอาสาสมัครของสนามนั้น
                    </p>
                    <p>
                      <b>3. รอตอบรับ</b> : รอการตอบรับจาก RD (Run Director)
                      สามารถเช็คสถานะได้ที่หน้าโปรไฟล์
                      เมื่อได้รับการตอบรับแล้วให้คลิกปุ่ม "สำหรับอาสาสมัคร"
                      จะรายการงานวิ่งของสนามที่ได้เป็นอาสาสมัครเพื่อไว้สำหรับใช้ระบบสแกนคิวอาร์โค้ด
                    </p>
                    <p className={classes.p}>
                      <b>4. เข้าร่วมงาน</b>
                      <br />
                      • ไปยังสนามจุดนัดพบก่อน เวลา 06:00 น. หรือตามที่ RD
                      นัดหมาย
                      <br />
                      •
                      ประชุมเกี่ยวกับการดําเนินงานเพื่อเตรียมความพร้อมในตําแหน่งของคุณ
                      จําไว้ว่าในฐานะอาสาสมัครคุณคือคนสําคัญสําหรับเรา <br />•
                      ช่วยเหลืองานตามมอบหมาย
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                square
                expanded={expanded === "panel6"}
                onChange={handleChange("panel6")}
              >
                <AccordionSummary
                  aria-controls="panel6d-content"
                  id="panel6d-header"
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography className="titleQA">
                    อาสาสมัครมีตำแหน่งอะไรบ้าง ?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="textQA">
                    <p>
                      <b>สาสมัคร (Volunteer)</b> : สมาชิกของระบบพาร์ครันไทยแลนด์
                      หากต้องการพักกิจกรรมวิ่ง มารับอาสาช่วยจัดกิจกรรม
                      สามารถเลือกสนามที่จัดกิจกรรมอยู่และอาสาตนเองเข้ามาในระบบได้
                      อาสาสมัครต้องมาถึงสนามจัดกิจกรรมก่อนเวลาเพื่อรับหน้าที่จาก
                      RD ของสนามโดยอาสาสมัครอาจจะมีหน้าที่ต่าง ๆ ได้ดังนี้{" "}
                    </p>
                    <p>
                      •<b> Marshals</b> : เป็นผู้ควบคุมเส้นทาง
                      ให้คำแนะนำถึงจุดอันตรายบนทาง
                      ชี้นำเส้นทางและให้กำลังใจกับผู้ร่วมกิจกรรม
                      คอยเป็นหูเป็นตาให้กับผู้จัดกิจกรรมถึงสภาพโดยรวมของผู้เข้าร่วมกิจกรรม
                      ให้ผู้จัดการกิจกรรมทราบถึงตำแหน่งของผู้เข้าร่วมกิจกรรมคนสุดท้าย{" "}
                    </p>
                    <p>
                      •<b> M Guide (Visual Impairment)</b> :
                      เป็นอาสาสมัครที่คอยดูแลผู้เข้าร่วมกิจกรรมที่มีความบกพร่องทางสายตา
                      ควรเตรียมผ้าสำหรับใช้จูงมือกับผู้มีความบกพร่องทางสายตา
                      มีความรู้ในการเดิน-วิ่งกับผู้มีความบกพร่องทางสายตา
                      เช่นต้องส่งเสียงบอกก่อนการเปลี่ยนทิศส่วงหน้า
                      บอกถึงจุดอันตรายสิ่งกีดขวางล่วงหน้า{" "}
                    </p>
                    <p>
                      •<b> Tail Walker or Sweeper</b> :
                      เป็นอาสาสมัครที่เดินปิดท้ายหลังผู้เข้าร่วมกิจกรรมคนสุดท้ายอาสาสมัครตำแหน่งนี้อาจจะต้องมีความรู้ด้านการปฐมพยาบาลเบื้องต้น
                      เพื่อช่วยเหลือผู้เข้าร่วมกิจกรรมซึ่งอาจจะเกิดอุบัตติเหตุต่างๆ
                      หรือควรมีโทรศัพท์มือถือเพื่อใช้แจ้งเหตุฉุกเฉินไปยังผู้อำนวยการกิจกรรม
                      โดยปรกติอาสาสมัครตำแหน่งนี้จะได้รับคะแนนในฐานะทั้งผู้เข้าร่วมกิจกรรมและอาสาสมัคร{" "}
                    </p>
                    <p>
                      •<b> QR Code Scanner</b> :
                      คืออาสาสมัครที่คอยแสกนคิวอาร์โค้ดของผู้เข้าร่วมกิจกรรม
                      ที่จุดเช็คพอยท์ต่างๆ และที่เส้นชัย
                      โดยระบบพาร์ครันไทยแลนด์สามารถกำหนดจุดเช็คพอยท์ได้ทุกๆ 1
                      กิโลเมตร
                      ผู้ที่เป็นอาสาสมัครในตำแหน่งนี้จะต้องเตรียมเว็บแอพพลิเคชั่นระบบสแกนคิวอาร์โค้ดมาไว้บนโทรศัพท์มือถือ
                      ซึ่งรองรับทั้ง IOS และ Android{" "}
                    </p>
                    <p>
                      •<b> Warm Up Leader</b> :
                      อาสาสมัครที่มีความรู้ทางด้านการนำการยืดเหยียดและอบอุ่นร่างกาย
                    </p>
                    <p>
                      •<b> Pacer</b> :
                      อาสาสมัครที่จะวิ่งควบคุมความเร็วโดยอาจจะใช้ลูกโป่งหรือป้ายเวลาปิดไว้ที่หลัง
                      เพื่อให้ผู้เข้าร่วมกิจกรรมที่ประสงค์จะพัฒนาฝีเท้าในการวิ่งตามเพชเซอร์ได้ความเร็วที่ต้องการ{" "}
                    </p>
                    <p>
                      •<b> Photographers</b> : ช่วยเก็บภาพผู้เข้าร่วมกิจกรรม
                      เพื่อสร้างแรงบันดาลใจให้ผู้อื่นสนใจมาเข้าร่วมกิจกรรมและให้ผู้เข้าร่วมกิจกรรมนำไปใช้เป็นภาพที่ระลึก
                    </p>
                    <p>
                      •<b> อื่น ๆ</b> :
                      ผู้จัดกิจกรรมสามารถจัดสรรหน้าที่อาสาสมัครต่างๆ
                      ตามต้องการขึ้นอยู่กับขนาดของผู้เข้าร่วมกิจกรมในแต่ละครั้ง{" "}
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                square
                expanded={expanded === "panel7"}
                onChange={handleChange("panel7")}
              >
                <AccordionSummary
                  aria-controls="panel7d-content"
                  id="panel7d-header"
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography className="titleQA">
                    ฉันจะมีสนามของฉันได้อย่างไร ?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="textQA">
                    <p>
                      <b>ทุกคนสามารถเป็นผู้สร้างสนามวิ่งได้</b>
                    </p>
                    <p>
                      <b>คุณสมบัติของสนาม</b> :
                      ต้องเป็นเส้นทางที่ผู้จัดกิจกรรมใช้เดิน-วิ่งออกกำลังกายเป็นประจำ
                      มีความปลอดภัย
                      มีขนาดกว้างขวางพอสำหรับต้อนรับจำนวนผู้เข้าร่วมกิจกรรม
                      เพื่อให้ไม่เป็นการรบกวนชุมชนหรือผู้ร่วมใช้สวนสาธารณะคนอื่น
                      ๆ{" "}
                    </p>
                    <p className={classes.p}>
                      <b>จัดอย่างสม่ำเสมอ</b> :
                      สามารถจัดได้อย่างสม่ำเสมอตลอดเลือกได้ <br />
                      1. ทุกสัปดาห์ <br />
                      2. เดือนละ 2 ครั้ง <br />
                      3. เดือนละครั้ง{" "}
                    </p>
                    <p>
                      <b>คุณสามารถสร้างสนามวิ่งของคุณได้ง่ายๆด้วยวิธีดังนี้</b>
                    </p>
                    <p>
                      เมื่อคุณต้องการสร้างสนามวิ่ง ให้คุณเข้ามาที่
                      www.parkrunthailand.com หลังจากนั้นให้คุณ Log in
                      เข้าสู่ระบบและคลิกที่ คลิก
                      <a href="/eventMap" className={classes.textboby}>
                        สนามวิ่ง
                      </a>
                      จากเมนูด้านขวาบนของเว็บไซต์ หน้าแสดงผลจะมีปุ่ม
                      "ขอเปิดสนามวิ่ง" และคลิก
                    </p>
                    <p>
                      ระบบจะพาคุณเข้ามาที่หน้าแบบฟร์อมขอเปิดสนามวิ่ง
                      คุณจะต้องกรอกรายละเอียดสนามของคุณให้ครบถ้วน
                      หลังจากที่คุณกรอกรายละเอียดของสนามเสร็จแล้ว ให้คุณคลิกปุ่ม
                      "ขออนุมัติเปิดสนามวิ่ง"{" "}
                    </p>
                    <p className={classes.p}>
                      หลังจากคุณส่งคำขออนุมัติสร้างสนามวิ่งแล้ว
                      คุณสามารถดูสถานะสนามวิ่งของคุณได้ที่หน้าโปรไฟล์ของคุณ
                      ซึ่งจะมี 3 สถานะ ดังนี้ <br />
                      - ส่งคำขออนุมัติแล้ว <br />
                      - กำลังดำเนินการตรวจสอบ
                      <br />
                      - อนุมัติแล้ว <br />
                      เมื่อผ่านการอนุมัติแล้ว จะมีปุ่ม "สำหรับผู้จัด"
                      อยู่ที่แถบเมนูของเว็บไซต์
                    </p>
                    <p>
                      ให้คุณคลิก "สำหรับผู้จัด"
                      ระบบจะพาคุณไปยังหน้าการจัดการงานวิ่งของคุณ
                    </p>
                    <p className={classes.p}>
                      <b>
                        ในส่วนหน้าการจัดการงานวิ่ง
                        เราขอแนะนำเมนูต่างๆในระบบสำหรับการจัดการงานวิ่งให้ครบถ้วน
                      </b>
                      <br />
                      <b>1. สถิติงานวิ่ง</b> :
                      เมนูนี้จะเป็นเมนูหลักเมื่อคุณเข้ามาในการจัดการงานวิ่ง
                      ซึ่งจะทำหน้าที่แสดงผลภาพรวมงานต่างๆ เช่น
                      จำนวนนักวิ่งและอาสาสมัครแต่ละสัปดาห์ เดือน และปี
                      ข้อมูลอายุและเพศของผู้เข้าร่วม ลำดับนักวิ่ง เป็นต้น <br />
                      <b>2. แก้ไขข้อมูลสนาม</b> :
                      เป็นเมนูเพื่อแก้ไขข้อมูลหลักของสนาม เช่น ชื่อสนาม
                      สถานที่จัดงาน ช่องทางการติดต่อผู้ประสานงาน
                      (คุณสามารถเพิ่มผู้ดูแลได้
                      โดยใช้อีเมลหรือเลขบิบของผู้ใช้ที่อยู่ในระบบได้ )
                      และรวมถึงข้อกำหนดต่างๆของงานวิ่ง เป็นต้น <br />
                      <b>3. อัพเดทงานวิ่ง</b> : เพื่อสร้าง Content ของงานวิ่ง
                      ซึ่งสามารถใส่ เนื้อหาคำอธิบายงานวิ่ง
                      รูปภาพที่เกี่ยวข้องกับงานวิ่งของคุณ
                      เพื่อให้งานวิ่งของคุณน่าสนใจมากยิ่งขึ้น
                      <br />
                      <b>4. ตารางวันวิ่ง</b> : เป็นเมนูสำหรับการจัดการวันวิ่ง
                      เพื่อรับสมัครผู้เข้าร่วมงานวิ่ง
                      ระบบจะสร้างวันวิ่งให้อัตโนมัติ
                      โดยคุณสามารถแก้ไขวันเวลาหรือลบรายการวิ่งได้ <br />
                      <b>5. ผู้เข้าร่วม</b> :
                      หลังจากการเปิดงานวิ่งและมีคนเข้าร่วมทั้งนักวิ่งและอาสาสมัคร
                      เมนูนี้จะช่วยให้คุณ Filter ข้อมูลที่ต้องการ
                      เช็คจำนวนและรายละเอียดของผู้เข้าร่วม
                      (สามารถดาวน์โหลดข้อมูลเหล่านี้ได้ตลอดเวลา)
                      ในส่วนของอาสาสมัครจะเป็นตารางที่คุณสามารถอนุมัติ
                      หรือเพิ่มอาสาสมัครโดยใช้เลขบิบของผู้ใช้ที่อยู่ในระบบได้
                      <br />
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                square
                expanded={expanded === "panel8"}
                onChange={handleChange("panel8")}
              >
                <AccordionSummary
                  aria-controls="panel8d-content"
                  id="panel8d-header"
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography className="titleQA">
                    ผู้จัดกิจกรรมหรือผู้อำนวยการวิ่งทำอะไร ?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="textQA">
                    <p>
                      <b>
                        ผู้จัดกิจกรรม(Event Director) หรือ ผู้อำนวยการวิ่ง(Run
                        Director)
                      </b>{" "}
                      : คือสมาชิกของระบบพาร์ครันไทยแลนด์ ซึ่งมีเส้นทางเดิน-วิ่ง
                      โดยที่ใช้อยู่เป็นกิจวัตร
                      เป็นเส้นทางที่ปลอดภัยควรหลีกเลี่ยงการตัดผ่านกับถนนที่มีรถยนต์สัญจร
                      มีขนาดพื้นที่กว้างขวางพอสำหรับการรองรับผู้เข้าร่วมกิจกรรมได้จำนวนหนึ่ง
                      ผู้จัดกิจกรรมต้องมีความพร้อมที่จะเดิน-วิ่งนำผู้มาเข้าร่วมกิจกรรมได้
                      รวมถึงมีความรู้เบื้องต้นในการอบอุ่นร่างกายก่อนการทำกิจกรรมและหลังการกิจกรรม
                      และที่สำคัญที่สุดคือต้องมีวินัยในการจัดกิจกรรมทุกเช้าวันเสาร์
                      7 นาฬิกาทุกสัปดาห์
                      ซึ่งเป็นไปได้ที่ผู้จัดกิจกรรมอาจจะเป็นทีมหลายคนผลัดกันมาช่วยจัดกิจกรรมทุกเช้าวันเสาร์เพื่อไม่ให้ขาดตอน
                      โดยปรกติแล้วผู้จัดกิจกรรม
                      จะรับหน้าที่ดูแลการจัดกิจกรรมโดยรวมตัดสินใจการเลื่อนหรือยกเลิกกิจกรรมตามสภาพภูมิอากาศ
                      (ฝนตกหนัก พายุหนัก ฯลฯ ) ดูแลเส้นทางวิ่ง
                      เปลี่ยนแปลงเส้นทางวิ่ง
                      หรืออาจจะวิ่งนำนักวิ่งผู้ร่วมกิจกรรม
                      คือเป็นผู้อำนวยการวิ่ง (Run Director) ด้วยอีกตำแหน่งหนึ่ง
                      โดยก่อนการวิ่งผู้อำนวยการวิ่งจะต้องสรุปกิจกรรมให้ผู้เข้าร่วมกิจกรรมใหม่ทราบก่อนถึงเวลาปล่อยตัว{" "}
                    </p>
                    <p className={classes.p}>
                      <b>หน้าที่หลัก</b>
                      <br />
                      <b>1. วางแผนงาน</b> : ทุกสนามจะมี RD ที่ได้รับมอบหมาย
                      จะคอยวางแผนเส้นทาง
                      ติดต่อผู้ที่เกี่ยวข้องเพื่อจัดการงานวิ่งได้ <br />
                      <b>2. จัดการสนาม</b> :
                      ผู้จัดกิจกรรมคอยจัดการข้อมูลของสนามตัวเองในระบบ
                      <br />
                      <b>3. ตอบรับอาสาสมัคร</b> :
                      ผู้จัดกิจกรรมสามารถตอบรับคนที่สมัครเป็นอาสาสมัครตามทีี่ต้องการและทําการนัดหมาย
                      <br />
                      <b>4. ประชุมอาสาสมัคร</b> : ผู้จัดกิจกรรมไปยังสนามจุดนัดพบ
                      จัดประชุมคณะทํางานและอาสาสมัคร เวลา 06:00 น.
                      หรือตามเวลาที่กําหนด
                      <br />
                      <b>5. ประสานงาน</b> : ดูแลงานให้ลุล่วง
                      ประสานส่วนกลางทั้งปัญหาและข้อเสนอแนะ
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Container>
      </div>
      {/* ----------------------สรุปสถิติ---------------------------------- */}
      <Stats />
      {/* ----------------------footer---------------------------------- */}
      <AppFooter description="795 ถนน บรรทัดทอง แขวง วังใหม่ เขตปทุมวัน กรุงเทพมหานคร 10330" />
    </Fragment>
  );
};

export default IndexPage;
