import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Row, Col } from 'antd'
import QRCode from 'react-qr-code'
import getUser from '../../graphql/queries/getUserAll'
import imgf from './img/banner_parkrunTest.png'


const Generator = () =>{

    const allUser = useQuery(getUser)

    if(allUser.data.userMany.loading){
        return <p>loading ...</p>
    }

    const users = allUser.data.userMany


    return(
        <div>
            <Row>
            {
            users.map((item, index)=> (
                item.bib && <Col span={6} style={{marginBottom: '10px'}}>
                <img src={imgf} style={{width: '180px'}} alt="img" />
                
                <div style={{ position: 'absolute' ,marginTop: '-143px', marginLeft: '13px'}} >
                    <div style={{display: 'flex',marginTop: '46px' }}>
                        <QRCode key={index} value={`${item._id}`} size="90" />
                        <div style={{marginTop: '38px', marginLeft: '6px'}}>{item.name}</div>
                    </div>
                
                </div>
                
            </Col>
                
                
            ))
        }
            </Row>
            
        </div>
        
    )
}


export default Generator