import React, { useContext, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { NavLink, Link as RouterLink, useLocation } from "react-router-dom";
import jwt from "jsonwebtoken";
import _get from "lodash/get";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  // Button,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles,
} from "@material-ui/core";
import HomeTwoToneIcon from "@material-ui/icons/HomeTwoTone";
import EventTwoToneIcon from "@material-ui/icons/EventTwoTone";
import DescriptionIcon from "@material-ui/icons/Description";
import AssessmentTwoToneIcon from "@material-ui/icons/AssessmentTwoTone";
import AccessibilityNewTwoToneIcon from "@material-ui/icons/AccessibilityNewTwoTone";
import ExitToAppTwoToneIcon from "@material-ui/icons/ExitToAppTwoTone";
import PersonAddTwoToneIcon from "@material-ui/icons/PersonAddTwoTone";
import MeetingRoomTwoToneIcon from "@material-ui/icons/MeetingRoomTwoTone";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";

import AuthContext from "../../../context/AuthContext";

import getUser from "../../../graphql/queries/getUserOne";

import NavItem from "./NavItem";
// import getEvent from '../../../graphql/queries/getAllEvent'

let items;
const items1 = [
  {
    href: "/home",
    icon: HomeTwoToneIcon,
    title: "หน้าหลัก",
  },
  {
    href: "/eventMap",
    icon: EventTwoToneIcon,
    title: "สนามวิ่ง",
  },
  {
    href: "/result",
    icon: AssessmentTwoToneIcon,
    title: "สถิติโดยรวม",
  },
  {
    href: "/event-vr",
    icon: EventTwoToneIcon,
    title: "Park Run @nywhere",
  },
  {
    href: "/actions/login",
    icon: ExitToAppTwoToneIcon,
    title: "เข้าสู่ระบบ",
  },
  {
    href: "/actions/register",
    icon: PersonAddTwoToneIcon,
    title: "ลงทะเบียน",
  },
];
const items2 = [
  {
    href: "/home",
    icon: HomeTwoToneIcon,
    title: "หน้าหลัก",
  },
  {
    href: "/eventMap",
    icon: EventTwoToneIcon,
    title: "สนามวิ่ง",
  },
  {
    href: "/result",
    icon: AssessmentTwoToneIcon,
    title: "สถิติโดยรวม",
  },
  {
    href: "/event-vr",
    icon: EventTwoToneIcon,
    title: "Park Run @nywhere",
  },
  {
    href: "",
    icon: DescriptionIcon,
    title: "สำหรับผู้จัด",
  },
  {
    href: "/eventlist",
    icon: AccessibilityNewTwoToneIcon,
    title: "สำหรับอาสาสมัคร",
  },
  {
    href: "/rewardpoint",
    icon: CardGiftcardIcon,
    title: "รางวัลทั้งหมด",
  },
  {
    href: "/",
    icon: MeetingRoomTwoToneIcon,
    title: "ออกจากระบบ",
  },
];
const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  testB: {
    display: "flex",
  },
  list: {
    // width: '100%',
    maxWidth: 300,
  },
}));

const NavBar = ({ openMobile, onMobileClose, userId }) => {
  const { signOut } = useContext(AuthContext);
  const classes = useStyles();
  const location = useLocation();
  const { userInfo } = useContext(AuthContext);

  const { data = { userOne: {} } } = useQuery(getUser, {
    variables: {
      _id: `${userInfo._id}`,
    },
    fetchPolicy: "cache-and-network",
  });

  const handleLink = () => {
    if (
      data.userOne.role.includes("ORGANIZER") ||
      data.userOne.role.includes("ADMIN")
    ) {
      const token = jwt.sign(
        {
          id: data.userOne._id,
          name: data.userOne.name,
          role: data.userOne.role,
        },
        "secret",
        {
          expiresIn: "1h",
        }
      );
      //localStorage.setItem('token', token)
      window.location.replace(
        `https://admin.parkrunthailand.com/token?token=${token}`
      );
    }
  };
  // const { data2 = { eventMany: {} } } = useQuery(getEvent, {
  //   varables: {
  //     _id: `${userInfo._id}`
  //   },
  // })
  if (userId) {
    // console.log('a');
    // items = items.filter((item)=> item.title !== 'เข้าสู่ระบบ')
    // items = items.filter((item)=> item.title !== 'ลงทะเบียน')
    items = items2;
  } else {
    items = items1;
  }
  // if(!userId){
  //   items = items.filter((item)=> item.title !== 'ออกจากระบบ')
  // }

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      {_get(data.userOne, "name") && (
        <React.Fragment>
          <Box alignItems="center" display="flex" flexDirection="column" p={2}>
            <Avatar
              className={classes.avatar}
              component={RouterLink}
              src={_get(data.userOne, "image")}
              to="/profile"
            />
            <Typography
              color="textPrimary"
              variant="h6"
              className={classes.list}
            >
              {_get(data.userOne, "name")}
            </Typography>
            <Typography
              color="textSecondary"
              variant="body2"
              className={classes.testB}
            >
              <EmailIcon fontSize="small" className={classes.icon} />{" "}
              {_get(data.userOne, "email")}
            </Typography>
            <Typography
              color="textSecondary"
              variant="body2"
              className={classes.testB}
            >
              <PhoneIcon fontSize="small" className={classes.icon} />{" "}
              {_get(data.userOne, "phone")}
            </Typography>
            <Typography
              variant="subtitle2"
              style={{ fontSize: "12px", marginTop: "5px" }}
            >
              <NavLink to="/profile" style={{ color: "#4db6ac" }}>
                แตะเพื่อเข้าสู่หน้าโปรไฟล์ของคุณ
              </NavLink>
            </Typography>
          </Box>
          <Divider />
        </React.Fragment>
      )}
      <Box p={2}>
        <List>
          {items.map((item) => {
            if (item.title === "ออกจากระบบ") {
              return (
                <NavItem
                  onClick={() => signOut()}
                  href={item.href}
                  key={item.title}
                  title={item.title}
                  icon={item.icon}
                />
              );
            }
            if (item.title === "สำหรับผู้จัด") {
              return (
                <NavItem
                  onClick={() => handleLink()}
                  href={item.href}
                  key={item.title}
                  title={item.title}
                  icon={item.icon}
                />
              );
            }
            return (
              <NavItem
                href={item.href}
                key={item.title}
                title={item.title}
                icon={item.icon}
              />
            );
          })}
        </List>
      </Box>
      {/* <Box flexGrow={1} />
      <Box
        p={2}
        m={2}
        bgcolor="background.dark"
      >
        <Typography
          align="center"
          gutterBottom
          variant="h4"
        >
          Need more?
        </Typography>
        <Typography
          align="center"
          variant="body2"
        >
          Upgrade to PRO version and access 20 more screens
        </Typography>
        <Box
          display="flex"
          justifyContent="center"
          mt={2}
        >
          <Button
            color="primary"
            component="a"
            href="https://react-material-kit.devias.io"
            variant="contained"
          >
            See PRO version
          </Button>
        </Box>
      </Box> */}
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      {/* <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden> */}
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default NavBar;
